// Customizable Area Start
Object.defineProperty(exports, '__esModule', {
  value: true
});


exports.compnaypagetitle = 'COMPANY PAGE';
exports.baseURL = require('../../../framework/src/config');
exports.getcompanyApiContentType = 'application/json';
exports.getCompanyEndPoint = 'bx_block_joblisting/company_pages';
exports.getcompanyApiMethod = 'GET';
exports.ApiContentType = "application/json";
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.searchWorkApiEndPoint = "bx_block_landingpage2/work_opportunities/find_work_opportunities?page=";
exports.workOpportunitiesEndPoint="bx_block_landingpage2/work_opportunities/my_work_post_opportunities";
exports.locationList = "/bx_block_landingpage2/work_opportunities/location_list"
exports.searchCategoryEndPoint = "bx_block_projectportfolio/get_categories"
exports.searchWorkApiQueryMidPoint = "&per_page="
exports.searchPeopleApiEndPoint = "bx_block_search/search_people";
exports.searchCompanyApiEndPoint = "bx_block_search/search_companies";
exports.putApiMethodType = "PUT"
exports.dropDownApiEndPoint = "bx_block_landingpage2/work_opportunities/my_work_post_opportunities_filter_dropdown"

exports.validationApiContentType = 'application/json';
exports.validationApiMethodType = 'GET';
exports.exampleAPiEndPoint = 'EXAMPLE_URL';
exports.exampleAPiMethod = 'POST';
exports.exampleApiContentType = 'application/json';
exports.textInputPlaceHolder = 'Enter Text';
exports.labelTitleText = 'JobListing';
exports.labelBodyText = 'JobListing Body';
exports.emptyList = 'No Data Found';
exports.btnExampleTitle = 'CLICK ME';
exports.pendingDataEndPoint="bx_block_joblisting/proposals/proposal_listing"
exports.locationDataEndPoint="bx_block_joblisting/proposals/proposal_locations"
exports.updateProposalStatusEndPoint = "bx_block_joblisting/proposals/update_proposal_status"

// draft opportunity
exports.getSkillEndPoint = "bx_block_projectportfolio/get_skills";
exports.discoverList="bx_block_landingpage2/projects";
exports.searchSkillEndPoint = "bx_block_projectportfolio/search_skill";
exports.getBoardsEndPoint="bx_block_dashboard/inspirations"
exports.createWorkOpportunity = "bx_block_landingpage2/work_opportunities/";
exports.getSpecificData = "bx_block_landingpage2/work_opportunities/"
exports.updateStatus = "bx_block_landingpage2/work_opportunities/update_work_opportunity_status?id="
exports.delete = "DELETE"

exports.workTypeArray = [
  {
    name:'Concept creation',
    value: 'Concept creation',
    details:'The designer will do only concept creation and rest of the design work will be handled by the client’s own team.'
  },
  {
    name:'Adaptation',
    value: 'Adaptation',
    details:'Develop and design the whole project based on a starting design concept given by client.'
  },
  {
    name:'Improvisation/ Rework',
    value: 'Improvisation',
    details:'Implement some design changes and improvisations in an existing design project of thee client.'
  },
]

exports.experienceArray = [
  {
    name:'Entry level',
    value: 'Entry level'
  },
  {
    name:'Intermediate level',
    value: 'Intermediate level'
  },
  {
    name:'Expert level',
    value: 'Expert level'
  },
]

// Manage people
exports.pageMemberLabel = 'Company page admins';
exports.pageMemberNote = 'View, Edit or Add member to you company page';

// add people
exports.addPeople = 'ADD PEOPLE';

// buttons
exports.continue_btn = 'Continue';
exports.continuetopage = 'Go to company page';
exports.addMember = 'Add New Admin';

exports.dropDownDataStatic=[
  {
    "id": 1,
		"name": "Open opportunity",
    "color": "#59BDD3",
    "value":"open_opportunity"
  },
  {
    "id": 2,
		"name": "Offered contract",
    "color":"yellow",
    "value":"offered_contract"
  },
  {
    "id": 3,
		"name": "Activated",
    "color":"green",
    "value":"activated"
  },
  {
    "id": 4,
		"name": "Delete post",
    "color":"red",
    "value":"deleted_post"
  },
  {
    "id": 5,
		"name": "Drafts",
    "color":"grey",
    "value":"draft"
  }
]

exports.sorting=[
  {
    "id": 1,
		"name": "Oldest to latest",
    "value":"Oldest to latest"
  },
  {
    "id": 2,
		"name": "Latest to Oldest",
    "value":"Latest to Oldest"
  },
  {
    "id": 3,
		"name": "Service Rate: Low to high",
    "value":"Service Rate: Low to high"
  },
  {
    "id": 4,
		"name": "Service Rate: High to low",
    "value":"Service Rate: High to low"
  },
  {
    "id": 5,
		"name": "Ratings",
    "value":"Ratings"
  }
]


exports.tabData=[
  {
    "id": 1,
		"name": "Pending",
  },
  {
    "id": 2,
		"name": "Shortlisted",
  },
  {
    "id": 3,
		"name": "Archived",
  },
 
]

exports.ratings=[
  
  {
    "id":2,
    "name":"1.0-2.0",
    
  },
  {
    "id":3,
    "name":"2.0-3.0",
  },
  {
    "id":4,
    "name":"3.0-4.0",
  },
  {
    "id":5,
    "name":"4.0-4.5",
  },
  
]

exports.categories = [
		{
			"id": 1,
			"name": "Graphic Design",
			"created_at": "2024-07-26T09:14:50.349Z",
			"updated_at": "2024-07-26T09:14:50.349Z"
		},
		{
			"id": 2,
			"name": "Digital and Web Design",
			"created_at": "2024-07-26T09:14:50.647Z",
			"updated_at": "2024-07-26T09:14:50.647Z"
		},
		{
			"id": 3,
			"name": "Illustration",
			"created_at": "2024-07-26T09:14:50.770Z",
			"updated_at": "2024-07-26T09:14:50.770Z"
		},
		{
			"id": 4,
			"name": "3D and Motion Design",
			"created_at": "2024-07-26T09:14:50.882Z",
			"updated_at": "2024-07-26T09:14:50.882Z"
		},
		{
			"id": 5,
			"name": "Photography and Videography",
			"created_at": "2024-07-26T09:14:51.001Z",
			"updated_at": "2024-07-26T09:14:51.001Z"
		},
		{
			"id": 6,
			"name": "Fashion and Textile Design",
			"created_at": "2024-07-26T09:14:51.106Z",
			"updated_at": "2024-07-26T09:14:51.106Z"
		},
		{
			"id": 7,
			"name": "Interior and Architecture Design",
			"created_at": "2024-07-26T09:14:51.186Z",
			"updated_at": "2024-07-26T09:14:51.186Z"
		},
		{
			"id": 8,
			"name": "Art and Crafts",
			"created_at": "2024-07-26T09:14:51.280Z",
			"updated_at": "2024-07-26T09:14:51.280Z"
		},
		{
			"id": 9,
			"name": "Print Design",
			"created_at": "2024-07-26T09:14:51.359Z",
			"updated_at": "2024-07-26T09:14:51.359Z"
		},
		{
			"id": 10,
			"name": "Marketing and Advertising Design",
			"created_at": "2024-07-26T09:14:51.463Z",
			"updated_at": "2024-07-26T09:14:51.463Z"
		},
		{
			"id": 11,
			"name": "Other Specialized Design Services",
			"created_at": "2024-07-26T09:14:51.560Z",
			"updated_at": "2024-07-26T09:14:51.560Z"
		}
	]

exports.OpprtunityArray = [
      {
          "id": "1",
          "type": "find_work_opportunity",
          "attributes": {
              "id": 1,
              "title": "Project new title",
              "description": "test description",
              "rate_type": "hourly_rate",
              "rate_amount": 2001,
              "project_timeline": 29,
              "project_timeline_type": "week",
              "location": "indore",
              "created_at": "2024-07-05T05:44:33.611Z",
              "updated_at": "2024-07-05T05:44:33.611Z",
              "photo": "/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBZ0VEIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--108e9abb78c3e8b5546f108448c7d7b9cc390380/tiger.jpg",
              "user_name": "huskey",
              "full_name": "karan yedlawar",
              "background_color_code": "#E9B0B2",
              "posted_time_ago": "posted 23 days ago"
          }
      },
      {
          "id": "2",
          "type": "find_work_opportunity",
          "attributes": {
              "id": 2,
              "title": "Project new title",
              "description": "test description",
              "rate_type": "Hourly rate",
              "rate_amount": 1200,
              "project_timeline": 4,
              "project_timeline_type": "Months",
              "location": "Remote",
              "created_at": "2024-07-08T08:33:35.718Z",
              "updated_at": "2024-07-08T08:33:35.718Z",
              "photo": null,
              "user_name": "testuser",
              "full_name": "dfdgghghgfhf ",
              "background_color_code": "#E9B0B2",
              "posted_time_ago": "posted 20 days ago"
          }
      },
      {
          "id": "3",
          "type": "find_work_opportunity",
          "attributes": {
              "id": 3,
              "title": "titke to test",
              "description": "titke to test",
              "rate_type": "hourly",
              "rate_amount": 24,
              "project_timeline": 2,
              "project_timeline_type": "Weeks",
              "location": "Remote",
              "created_at": "2024-07-08T08:39:24.714Z",
              "updated_at": "2024-07-08T08:39:24.714Z",
              "photo": null,
              "user_name": "varun.designer",
              "full_name": "Varun ",
              "background_color_code": "#E9B0B2",
              "posted_time_ago": "posted 20 days ago"
          }
      },
      {
          "id": "4",
          "type": "find_work_opportunity",
          "attributes": {
              "id": 4,
              "title": "Paragraphs are the building blocks of papers. Many",
              "description": "Paragraphs are the building blocks of papers. Many students define paragraphs in terms of length: a paragraph is a group of at least five sentences, a paragraph is half a page long, etc. In reality, though, the unity and coherence of ideas among sentences is what constitutes a paragraph.",
              "rate_type": "hourly",
              "rate_amount": 500,
              "project_timeline": 5,
              "project_timeline_type": "Weeks",
              "location": "Remote",
              "created_at": "2024-07-08T14:27:53.521Z",
              "updated_at": "2024-07-08T14:27:53.521Z",
              "photo": null,
              "user_name": "varun.designer",
              "full_name": "Varun ",
              "background_color_code": "#E9B0B2",
              "posted_time_ago": "posted 20 days ago"
          }
      },
      {
          "id": "5",
          "type": "find_work_opportunity",
          "attributes": {
              "id": 5,
              "title": "Paragraphs are the building blocks of papers. Many",
              "description": "h",
              "rate_type": "hourly",
              "rate_amount": 67,
              "project_timeline": 788,
              "project_timeline_type": "Weeks",
              "location": "Remote",
              "created_at": "2024-07-08T14:30:17.483Z",
              "updated_at": "2024-07-08T14:30:17.483Z",
              "photo": null,
              "user_name": "varun.designer",
              "full_name": "Varun ",
              "background_color_code": "#E9B0B2",
              "posted_time_ago": "posted 20 days ago"
          }
      },
      {
          "id": "6",
          "type": "find_work_opportunity",
          "attributes": {
              "id": 6,
              "title": "test",
              "description": "test@project",
              "rate_type": "hourly",
              "rate_amount": 5,
              "project_timeline": 5,
              "project_timeline_type": "Weeks",
              "location": "Remote",
              "created_at": "2024-07-09T06:46:01.837Z",
              "updated_at": "2024-07-09T06:46:01.837Z",
              "photo": null,
              "user_name": "Client",
              "full_name": "Client ",
              "background_color_code": "#E9B0B2",
              "posted_time_ago": "posted 19 days ago"
          }
      },
      {
          "id": "7",
          "type": "find_work_opportunity",
          "attributes": {
              "id": 7,
              "title": "test pro",
              "description": "test dff",
              "rate_type": "hourly",
              "rate_amount": 2,
              "project_timeline": 4,
              "project_timeline_type": "Weeks",
              "location": "Remote",
              "created_at": "2024-07-09T07:01:30.766Z",
              "updated_at": "2024-07-09T07:01:30.766Z",
              "photo": null,
              "user_name": "Client",
              "full_name": "Client ",
              "background_color_code": "#E9B0B2",
              "posted_time_ago": "posted 19 days ago"
          }
      },
      {
          "id": "8",
          "type": "find_work_opportunity",
          "attributes": {
              "id": 8,
              "title": "test pro",
              "description": "testpro",
              "rate_type": "hourly",
              "rate_amount": 3,
              "project_timeline": 4,
              "project_timeline_type": "Weeks",
              "location": "Remote",
              "created_at": "2024-07-09T07:16:45.611Z",
              "updated_at": "2024-07-09T07:16:45.611Z",
              "photo": null,
              "user_name": "Client",
              "full_name": "Client ",
              "background_color_code": "#E9B0B2",
              "posted_time_ago": "posted 19 days ago"
          }
      },
      {
          "id": "9",
          "type": "find_work_opportunity",
          "attributes": {
              "id": 9,
              "title": "test ",
              "description": "pro test",
              "rate_type": "hourly",
              "rate_amount": 3,
              "project_timeline": 3,
              "project_timeline_type": "Weeks",
              "location": "Remote",
              "created_at": "2024-07-09T07:24:45.468Z",
              "updated_at": "2024-07-09T07:24:45.468Z",
              "photo": null,
              "user_name": "Client",
              "full_name": "Client ",
              "background_color_code": "#E9B0B2",
              "posted_time_ago": "posted 19 days ago"
          }
      },
      {
          "id": "10",
          "type": "find_work_opportunity",
          "attributes": {
              "id": 10,
              "title": "test project",
              "description": "test test test",
              "rate_type": "hourly",
              "rate_amount": 2,
              "project_timeline": 4,
              "project_timeline_type": "Weeks",
              "location": "Remote",
              "created_at": "2024-07-09T09:28:14.634Z",
              "updated_at": "2024-07-09T09:28:14.634Z",
              "photo": null,
              "user_name": "Client",
              "full_name": "Client ",
              "background_color_code": "#E9B0B2",
              "posted_time_ago": "posted 19 days ago"
          }
      },
      {
          "id": "11",
          "type": "find_work_opportunity",
          "attributes": {
              "id": 11,
              "title": "test project",
              "description": "Dsgdhgfj",
              "rate_type": "hourly",
              "rate_amount": 3,
              "project_timeline": 32,
              "project_timeline_type": "Weeks",
              "location": "Remote",
              "created_at": "2024-07-09T09:30:33.346Z",
              "updated_at": "2024-07-09T09:30:33.346Z",
              "photo": null,
              "user_name": "Client",
              "full_name": "Client ",
              "background_color_code": "#E9B0B2",
              "posted_time_ago": "posted 19 days ago"
          }
      },
      {
          "id": "12",
          "type": "find_work_opportunity",
          "attributes": {
              "id": 12,
              "title": "Project new title",
              "description": "test description",
              "rate_type": "hourly_rate",
              "rate_amount": 2001,
              "project_timeline": 29,
              "project_timeline_type": "week",
              "location": "indore",
              "created_at": "2024-07-09T09:41:13.715Z",
              "updated_at": "2024-07-09T09:41:13.715Z",
              "photo": null,
              "user_name": "Client",
              "full_name": "Client ",
              "background_color_code": "#E9B0B2",
              "posted_time_ago": "posted 19 days ago"
          }
      },
      {
          "id": "13",
          "type": "find_work_opportunity",
          "attributes": {
              "id": 13,
              "title": "Project new title",
              "description": "test description",
              "rate_type": "hourly_rate",
              "rate_amount": 2001,
              "project_timeline": 29,
              "project_timeline_type": "week",
              "location": "indore",
              "created_at": "2024-07-09T09:42:10.975Z",
              "updated_at": "2024-07-09T09:42:10.975Z",
              "photo": null,
              "user_name": "Client",
              "full_name": "Client ",
              "background_color_code": "#E9B0B2",
              "posted_time_ago": "posted 19 days ago"
          }
      },
      {
          "id": "14",
          "type": "find_work_opportunity",
          "attributes": {
              "id": 14,
              "title": "test opp",
              "description": "test opptest oppdes",
              "rate_type": "hourly",
              "rate_amount": 22,
              "project_timeline": 22,
              "project_timeline_type": "Weeks",
              "location": "Remote",
              "created_at": "2024-07-09T09:47:36.859Z",
              "updated_at": "2024-07-09T09:47:36.859Z",
              "photo": null,
              "user_name": "Client",
              "full_name": "Client ",
              "background_color_code": "#E9B0B2",
              "posted_time_ago": "posted 19 days ago"
          }
      },
      {
          "id": "15",
          "type": "find_work_opportunity",
          "attributes": {
              "id": 15,
              "title": "twstt",
              "description": "tregh",
              "rate_type": "hourly",
              "rate_amount": 24,
              "project_timeline": 24,
              "project_timeline_type": "Weeks",
              "location": "Remote",
              "created_at": "2024-07-09T09:58:55.113Z",
              "updated_at": "2024-07-09T09:58:55.113Z",
              "photo": null,
              "user_name": "Client",
              "full_name": "Client ",
              "background_color_code": "#E9B0B2",
              "posted_time_ago": "posted 19 days ago"
          }
      },
      {
          "id": "16",
          "type": "find_work_opportunity",
          "attributes": {
              "id": 16,
              "title": "Your Work Opportunity Title",
              "description": "Description of your work opportunity",
              "rate_type": "hourly",
              "rate_amount": 50,
              "project_timeline": 6,
              "project_timeline_type": "Month",
              "location": "remote",
              "created_at": "2024-07-09T10:18:58.816Z",
              "updated_at": "2024-07-09T11:32:53.793Z",
              "photo": null,
              "user_name": "testuser",
              "full_name": "dfdgghghgfhf ",
              "background_color_code": "#E9B0B2",
              "posted_time_ago": "posted 19 days ago"
          }
      },
      {
          "id": "17",
          "type": "find_work_opportunity",
          "attributes": {
              "id": 17,
              "title": "teest",
              "description": "tesret",
              "rate_type": "hourly",
              "rate_amount": 24,
              "project_timeline": 75,
              "project_timeline_type": "Weeks",
              "location": "Remote",
              "created_at": "2024-07-09T12:15:08.056Z",
              "updated_at": "2024-07-09T12:15:08.056Z",
              "photo": null,
              "user_name": "Client",
              "full_name": "Client ",
              "background_color_code": "#E9B0B2",
              "posted_time_ago": "posted 19 days ago"
          }
      },
      {
          "id": "18",
          "type": "find_work_opportunity",
          "attributes": {
              "id": 18,
              "title": "hsklj",
              "description": "klasklc",
              "rate_type": "hourly",
              "rate_amount": 45,
              "project_timeline": 22,
              "project_timeline_type": "Weeks",
              "location": "Remote",
              "created_at": "2024-07-09T14:33:25.794Z",
              "updated_at": "2024-07-09T14:33:25.794Z",
              "photo": null,
              "user_name": "varun.designer",
              "full_name": "Varun ",
              "background_color_code": null,
              "posted_time_ago": "posted 19 days ago"
          }
      }
  ]

  exports.cityArray = [
		{
			"id": 1,
			"name": "Mumbai",
      "value":'mumbai'
		},{
			"id": 2,
			"name": "Delhi",
      "value":'delhi'
		},{
			"id": 1,
			"name": "Jabalpur",
      "value":'jabalpur'
		},
	]

  exports.workDurationData = {
    title: "Work Duration",
    data:[
    {
      id: 0,
      name: "Less than a month",
      value: "less_than_a_month"
    },
    {
      id: 1,
      name: "1 - 3 months",
      value: '1-3_months'
    },
    {
      id: 2,
      name: "3 - 6 months",
      value:'3-6_months'
    },
    {
      id: 3,
      name: "More than 6 months",
      value:'more_than_6_months'
    },
  ]}

  exports.workTypeData = {
    title:"Work type",
    data:[
    {
      id: 0,
      name: "Concept creation",
      value:"concept_creation",
      tooltip:"The designer will do only concept creation and the rest of the design work will be handled by the client's own team."
    },
    {
      id: 1,
      name: "Adaptation",
      value:"adaptation",
      tooltip:"Develop and design the whole project based on a starting design concept given by client."
    },
    {
      id: 2,
      name: "Improvisation / rework",
      value: "improvisation",
      tooltip:"Implement some design changes and improvisations in an existing design project of the client."
    }
  ]}

  exports.experienceLevelData = {
    title:"Experience level",
    data:[
    {
      id: 0,
      name: "Entry level",
      value: 'entry_level'
    },
    {
      id: 1,
      name: "Intermediate",
      value: 'intermediate'
    },
    {
      id: 2,
      name: "Expert",
      value:'expert'
    }
  ]}

  exports.hoursPerWeekData = {
    title: "Hours per week",
    data:[
    {
      id: 0,
      name: "Less than 15 Hours per week",
      value: 'less_than_15_hours_per_week'
    },
    {
      id: 1,
      name: "15 - 25 Hours per week",
      value: '15-25_hours_per_week'
    },
    {
      id: 2,
      name: "25 - 40 Hours per week",
      value: '25-40_hours_per_week'
    },
    {
      id: 3,
      name: "More than 40 Hours per week",
      value: 'more_than_40_hours_per_week'
    },
  ]}

  exports.locationTitle = "Location"


  //view work details
  exports.viewDeatilApiEndPoint = "bx_block_landingpage2/work_opportunities/"

  exports.workOpportunityContactApiEndPoint = "bx_block_landingpage2/work_opportunities/work_opportunity_contract?id="

// Customizable Area End

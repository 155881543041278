Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfdesignersidecontractmanagement";
exports.labelBodyText = "cfdesignersidecontractmanagement Body";

exports.offerContractApiEnd = "bx_block_joblisting/proposals/offer_contract?data[attributes][proposal_id]="
exports.createContractApiEndPoint = "bx_block_dashboard/contracts"
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End
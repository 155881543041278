// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import JsFileDownloader from "js-file-downloader";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isVisibleModal: boolean;
  downloadData: { [keyData: string]: string }[];
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class DownloadOptionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getApicallData: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isVisibleModal: false,
      downloadData: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.getUserDocumentsData();
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived in");

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getApicallData) {
        if (!responseJson.errors) {
          this.handleSetData(responseJson)
        }        
      }
    }

    // Customizable Area End
  }

  // web events
  // Customizable Area Start

  handleSetData = (responseJson:{data:{attributes: {[keyVal:string]:string}}}) =>{
    let respData : {[keyData:string]:string}[] = [];
    responseJson && responseJson.data && Object.keys(responseJson.data.attributes).forEach(
      (element: string) => {
      const prepData = this.handlePrepData(element,responseJson.data.attributes);
        respData.push(prepData)
      })
    this.setState({
      downloadData: respData,
    });
  }

  handlePrepData = (imageText:string,parentObject:{[keyData:string]:string}) =>{
    const prepData = {
      title: imageText.split("_").join(" "),
      value:`https://rooappinc-163379-ruby.b163379.dev.eastus.az.svc.builder.cafe${parentObject[imageText]}`,              
      altVal: "Image not available",
    };
    return prepData;
  }


  getUserDocumentsData() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token:
        "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MTY3NiwiZXhwIjoxNjc5NDc2OTkyLCJ0b2tlbl90eXBlIjoibG9naW4ifQ.eM0-YNGmV6-XiKFJqUaZrAu1vnKStmyJVRwiyqXmMNkHaX2rBtxchmXgRa4Il2eiRlI9QM75zRUUkfUF-ZZUEQ",
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getApicallData = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.downloadOptionUrl
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  async handleDownload(imageUrl: string) {
    try {
      new JsFileDownloader({
        url: imageUrl,
      });
    } catch (error) {}
  }

  // Customizable Area End
}

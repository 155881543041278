// index.js - WEB
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import "../../blocks/fonts/fontStyles.css";
import "../../blocks/fonts/globalStyle.css";
import registerServiceWorker from '../../components/src/registerServiceWorker';
import { GoogleOAuthProvider } from '@react-oauth/google';

ReactDOM.render(
  <Router>
  <GoogleOAuthProvider   clientId="314020539281-i3eqnbe7qc9cevc1f7dk64m2ih8vhu91.apps.googleusercontent.com">  
    <App />
  </GoogleOAuthProvider>
  </Router>,
  document.getElementById('root')
);
registerServiceWorker();

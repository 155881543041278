Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleAPiDeleteMethod = "DELETE";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";
exports.sendViewedHighlightApiEndPoint = "bx_block_landingpage2/highlights/view_highlights"
exports.httpPostMethod = "POST";
exports.perPageParams = "?per_page="
exports.pageParams = "&page="
exports.saveWorkApiEndPoint = "bx_block_dashboard/saved_work_opportunities"

exports.btnExampleTitle = "CLICK ME";
exports.createHiglightsEndPoint="bx_block_landingpage2/highlights"
exports.getAllUsersHighlights="bx_block_landingpage2/highlights/users_with_highlights"
exports.baseURL = require('../../../framework/src/config');
exports.discoverList="bx_block_landingpage2/projects";
exports.getCategoryEndPoint = "bx_block_projectportfolio/get_categories";
exports.getSkillEndPoint = "bx_block_projectportfolio/get_skills";
exports.workOpportunityEndPoint  = "bx_block_landingpage2/work_opportunities/display_work_opportunities";
exports.commentsList="bx_block_comments/comments/project_comments";
exports.commentsEndPoint="bx_block_comments/comments";
exports.inspirationEndPoint="bx_block_dashboard/inspirations";
exports.saveInspirationEndPoint="bx_block_dashboard/inspiration_projects";
exports.followsEndPoint="bx_block_followers/follows";
exports.likeProjectEndPoint="bx_block_like/likes";
exports.likesDetailsEndPoint="bx_block_like/likes/project_likes_details";
exports.viewsDetailsEndPoint="bx_block_dashboard/views/project_views_details";
exports.projectDetailsEndPoint="bx_block_landingpage2/projects/project_details";
exports.workGetOpportunityWithIdEndPoint  = "bx_block_landingpage2/work_opportunities/";

// Customizable Area End
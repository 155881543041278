
import { Box, Divider, Typography, styled } from '@material-ui/core';
import { BlockComponent } from 'framework/src/BlockComponent'
import React from 'react'

export interface Props {
  opportunity: workOpportunity;
  handleOpenOpportunity:(id:string | number) => void;
  handlenavigate:any;
}

export interface workOpportunity {
  'id':string;
  "type":string;
  'attributes':{
    "id":number;
    "title":string;
    "description":string;
    "rate_type":string;
    "rate_amount":number;
    "project_timeline":number;
    "project_timeline_type":string;
    "location":string;
    "photo":string | null,
    "user_name":string;
    "full_name":string;
    "posted_time_ago":string;
    "background_color_code":string | null;
    "created_at":Date;
    "updated_at":Date;
  }

}

interface S {

}

interface SS {
}

const profilePic = require('../../blocks/joblisting/assets/ic_userlogo.png')
const config = require("../../framework/src/config.js")

export default class OpportunityCard extends BlockComponent<Props, S, SS> {


  constructor(props: Props) {
    super(props)

    this.state = {}
  }

  MainWrapper = styled(Box)(({ theme }) => ({
    "& .opportunity_card_main_container": {
      borderRadius: 12,
      padding: 20,
      gap: 16,
      border: "1px solid #3E3E3E",
      cursor: "pointer",
    },
    "& .opportunity_card_title_box": {
      borderColor: '#3E3E3E',
      display: 'flex',
      flexDirection: 'column',
      fontFamily: "Inter , sans-serif",
      alignItems: "stretch",
      gap: 14,
      justifyContent: "space-between",
    },
    "& .opportunity_card_divider_color": {
      borderColor: '#3E3E3E'
    },
    "& .opportunity_card_hour": {
      color: "#66625C",
      fontSize: "11px",
      fontWeight: 400,
      fontFamily: "Inter,sans-serif",
    },
    "& .opportunity_card_title": {
      fontSize: "20px",
      fontFamily: "Inter,sans-serif",
      color: "#FFF2E3",
      fontWeight: 600,
      height:58
    },
    "& .opportunity_card_person_name": {
      fontSize: "14px",
      fontFamily: "Inter,sans-serif",
      color: "#FFF2E3",
      fontWeight: 600
    }, "& .opportunity_card_person_detain_box": {
      display: "flex",
      justifyContent: 'space-between',
      alignItems: "baseline",
      marginTop:10,
    },
    "& .opportunity_card_person_city": {
      fontSize: "14px",
      fontFamily: "Inter,sans-serif",
      color: "#FFF2E3",
      fontWeight: 400,
      textTransform:"capitalize"
    },
    "& .opportunity_card_price_duration": {
      fontSize: "14px",
      padding: "6px 16px 6px 16px",
      borderRadius: "24px",
      backgroundColor: "#1D1D1D",
      fontFamily: "Inter,sans-serif",
    },
    "& .opportunity_card_price_color": {
      color: "#1CBAE0"
    }, "& .opportunity_card_duration_color": {
      color: "#FFB9C7",
      marginLeft: 15
    },
    "& .opportunity_card__description": {
      fontSize: "12px",
      height:50,
      fontFamily: "Inter,sans-serif",
      fontWeight: '400',
      color: "#FFF2E3",
      flexGrow: 1,
    },
    "& .opportunity_card_price_duration_box": {
      display: 'flex'
    }, "& .only_2_line":{
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 2,
      overflow: "hidden",
      textOverflow: "ellipsis",
      lineHeight: 1.5,
      maxHeight: "3em",
  }
  }));

  renderProfilePic = () =>{
    const { opportunity } = this.props

    if(opportunity.attributes.photo && opportunity.attributes.photo?.length > 0){
      return <img src={config.baseURL + opportunity.attributes.photo} alt={opportunity.attributes.full_name} height={'44px'} width={'44px'} style={{ borderRadius: 50 }} />
    } else if(opportunity.attributes.background_color_code && opportunity.attributes.background_color_code?.length > 0) {
      return <Box style={{height:44, width:44, borderRadius:50, background:`${opportunity.attributes.background_color_code}`}}/>
    } else {
      return <img src={profilePic} alt="blank_image" height={'44px'} width={'44px'} style={{ borderRadius: 50 }} />
    }
  }

  capitalizeFirstLetter(value:string) {
    return value?.replace(/\b[a-z]/g, (match) => match.toUpperCase());
  }
  
  render() {
    const { opportunity } = this.props
    return (
      <this.MainWrapper>
        <Box className='opportunity_card_main_container' data-test-id="openOpportunity" onClick={()=>this.props.handleOpenOpportunity(opportunity.id)}>
          <Box className='opportunity_card_title_box'>
            <Typography className='opportunity_card_title only_2_line'>{opportunity.attributes.title} </Typography>
            <Box className='opportunity_card_price_duration_box'>
              <Typography className='opportunity_card_price_duration opportunity_card_price_color'>Rs. {opportunity.attributes.rate_amount.toLocaleString('en-IN')} {opportunity.attributes.rate_type !== "overall" && "pr/hr"}</Typography>
              <Typography className='opportunity_card_price_duration opportunity_card_duration_color'>{opportunity.attributes.project_timeline} {this.capitalizeFirstLetter(opportunity.attributes.project_timeline_type)}</Typography>
            </Box>
            <Typography className='opportunity_card__description only_2_line'>{opportunity.attributes.description}</Typography>
          </Box>
          <Box>
<Divider style={{ backgroundColor: "rgba(84, 84, 84, 1)", marginBlock: '16px'}} />
          <Box className='opportunity_card_person_detain_box' onClick={(event)=>this.props.handlenavigate(event,opportunity.attributes)}>
            <Box sx={{
              display: 'flex'
            }}>
              <Box sx={{
                height: '44px',
                width: "44px"
              }}>
                { this.renderProfilePic() }
              </Box>
              <Box sx={{ marginLeft: 5 }}>
                <Typography className='opportunity_card_person_name'>{opportunity.attributes.full_name}</Typography>
                <Typography className='opportunity_card_person_city'>{opportunity.attributes.location}</Typography>
              </Box>
            </Box>
            <Box >
              <Typography className='opportunity_card_hour'>{opportunity.attributes.posted_time_ago}</Typography>
            </Box>
          </Box>
          </Box>
        </Box>
      </this.MainWrapper>
    )
  }
}

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import storage from "../../../framework/src/StorageProvider.web";
import { getCroppedImg, convertImageUrlToBase64, uploadImage,shouldShowHighlights,getToken,getUserProfile} from "../../../components/src/Base64Conversion";
import { getStorageData } from "framework/src/Utilities";

export interface HighlightItem {

  data: {

    id: number,

    attributes: {

      is_viewed: boolean;

      is_liked: boolean;
      created_at:string;

      views: number;

      image_url: string;

      likes: number;

      location: string;

    };

  };

}
export interface MessageApi {

  getData: () => ({

    likes_count: 10,

    is_liked: true,

  }),

}
export interface HighlightListProps {

  allHighlightdata: HighlightData[];

  userInfo: {

    user_name: string;

  };

  handleViewHighlight: (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>, index: number) => void;

  uploadHighLightHandler: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;

  itemsContainerRef: React.RefObject<HTMLDivElement>;

  scrollPosition: number;

  userType: string | null | UserType;

userPhoto:string | null;

}
export interface HighlightData {

  seen_status: boolean;

  user_name: string;

  highlights: HighlightItem[];

  id: number;

  account_id: number;
  full_name:string;
  index: number;

  profile_image: string,
  background_color:string

}
interface CroppedAreaPixels {

  x: number;

  y: number;

  width: number;

  height: number;

}
interface UserInfo {

  user_name: string;
  profile_image:string|null;
  background_color:string|null;

}
interface UserType {

  role_name: string;

}
interface UploadResponse {

  errMessage: string;

  openToast: boolean;

  action: string;

}
interface ErrorMessage {

  message: string;

}
interface ResponseType {
  data: HighlightData[];
}

interface Highlight {
  id: number;
  highlight_image: string;
  data: {
    attributes: {
      image_url: string;
      location: string;
    };
  };
}

export interface Account {
  id: number;
  user_name: string;
  highlights: Highlight[];
}

interface ApiResponse {
  data: {
    likes_count: number;
    is_liked: boolean;
  };
}

export const config = require("../../../framework/src/config");
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeTab: string;
  showPopup: boolean;
  totalAccounts:number;
  newHighlightPresent:boolean;
  logindUserUnViewedIds:number[];
  logindUserIds:number[];
  viewedHighlightIds:number[],
  image: File | null;
  crop: { x: number; y: number };
  zoom: number;
  croppedAreaPixels: CroppedAreaPixels | null;
  croppedImage: Blob | null ;
  scrollPosition:number;
  containerWidth: number;
  maxScroll: number;
      allHighlightdata:HighlightData[];
      openToast:boolean;
      action:string,
      count:number,
      clickedHighlightIndex:number|null,
      highlightState:number,
  userType: UserType | string | null,
  vistedhiglightArray: number[],
      allHighlightedIds:number[];
      errMessage:string,
      userInfo:UserInfo,
      viewCount:number,
      is_viewed:boolean,
      tokenData:string,
      userPhoto:string | null,
      isLoading:boolean;
      userRole:string,      
      alreadyViewedHighlights:any[],
      perPage:number;
      page:number;
      domainUrl:string | undefined;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  itemsContainerRef: React.RefObject<HTMLDivElement>;
  loaderRef: React.RefObject<any>
  sendViewedHighlightsApiCallId:string = "";
  getAllUsersHighlightsApiCallId:string = "";
  apiDeleteCallId: string = "";
  apiPostCallId: string = "";
  apiPostViewId: string = "";
  apiGetCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionRequestMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      activeTab: "",
      domainUrl:config.baseURL,
      alreadyViewedHighlights:[],
      isLoading:true,
      newHighlightPresent:false,
      logindUserUnViewedIds:[],
      logindUserIds:[],
      allHighlightedIds:[],
      totalAccounts:0,
      showPopup: false,
      viewedHighlightIds:[],
      image: null,
      crop: { x: 0, y: 0 },
      zoom: 1,
      croppedAreaPixels: null,
      croppedImage: null,
      scrollPosition:0,
      containerWidth: 0,
      maxScroll: 0,
      allHighlightdata:[],
      openToast:false,
      action: "",
      count:0,
      clickedHighlightIndex:null,
      highlightState:0,
      userType:null,
      vistedhiglightArray:[],
      errMessage:"",
      userInfo: { user_name: "", background_color:null,profile_image:null },
      viewCount:0,
      is_viewed:false,
      tokenData:"",
      userPhoto:null,
      perPage:16,
      page:1,
      userRole: ''
    };
    this.itemsContainerRef = React.createRef();
    this.loaderRef = React.createRef<HTMLDivElement>();
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleResponseData(apiRequestCallId, responseJson, message);
    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleResponseData = (apiRequestCallId: string, responseJson: any, message: Message) => {

    if (apiRequestCallId && responseJson) {
      if(apiRequestCallId === this.getAllUsersHighlightsApiCallId ) {
        this.getResponseOfHighlights(responseJson)
      } else if (apiRequestCallId === this.apiDeleteCallId || apiRequestCallId ===  this.apiPostCallId) {
        this.handlelikeValue(message);
      } else if (apiRequestCallId === this.apiGetCallId) {
        this.handleGetData(responseJson);
      }
    }
  }

  sendViewedHighlights = async () => {
    let token = this.state.tokenData;
    const actualArray = this.state.viewedHighlightIds.filter(element => !this.state.allHighlightedIds.includes(element));
    const header = {
      'Content-Type': configJSON.exampleApiContentType,
      token: token
    };

    const data = {
      attributes: {
        highlight_ids: actualArray
      }
    }

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendViewedHighlightsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendViewedHighlightApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getResponseOfHighlights = (response:any) =>{
    this.setState({totalAccounts:response.accounts_count})
      let data = [...response.accounts];
      this.createCheckListArrayForHighlights(data);
    
      storage.get("userInfo").then((res) => {
        let storeData = JSON.parse(res);
        if (storeData && storeData.data) {
          this.setState({
            userType: storeData.data.attributes.role_name,
            userInfo: storeData.data.attributes,
          });
        }
        return storeData.data.attributes;
      }).then((user) => {
        let key = "user_name";
        let value = user.user_name;
    
        let index = data.findIndex((obj) => obj[key] === value);
    
        if (index !== -1) {
          let removedItem = data.splice(index, 1)[0];
          data.unshift(removedItem);
        }
        this.convertBase64(data);
        this.setState({isLoading:false})
      });
  }

  handleScroll = () => {
    const loader = this.loaderRef.current;

    if (loader) {
      const { scrollLeft, scrollWidth, clientWidth } = loader;
      if (scrollLeft + clientWidth >= scrollWidth - 200 && !this.state.isLoading) {
        this.setState(
          (prevState) => ({ page: prevState.page + 1 }),
          () => {
            if(this.state.allHighlightdata.length < this.state.totalAccounts){
              this.getHighlightOfAllUsersRunEngine()
            }
          }
        );
      }
    }
  };

  getHighlightOfAllUsersRunEngine = async () => {
    this.setState({isLoading:true})
    let token = this.state.tokenData;

    const header = {
      'Content-Type': configJSON.exampleApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllUsersHighlightsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllUsersHighlights + configJSON.perPageParams + this.state.perPage + configJSON.pageParams + this.state.page
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetData = (responseJson: {accounts: Account[]}) => {
    const data = [...responseJson.accounts];
    storage.get("userInfo").then(res => {
      let storeData = JSON.parse(res);
      if (storeData && storeData.data) {
        this.setState({ userType: storeData.data.attributes.role_name, userInfo: storeData.data.attributes });
      }
      return storeData.data.attributes;
    }).then((user) => {
      let key = "user_name";
      let value = user.user_name;

      let index = data.findIndex((obj:Account) => obj['user_name'] === value);

      if (index !== -1) {
        let removedItem = data.splice(index, 1)[0];
        data.unshift(removedItem);
      }
      this.convertBase64(data);
    }).catch(error => error.message);
  }
  async componentDidMount() {
    let newToken = await getStorageData("userInfo")
    if (typeof newToken === 'string') {
      newToken = JSON.parse(newToken);
    }
    const { data: { attributes: { role_name } } } = newToken;
    this.setState({
      userRole:role_name
    })
    getToken().then((data)=>{ this.setState({tokenData:data})}).then(()=>{
      this.getHighlightOfAllUsersRunEngine()
    })

    const userProfile=await getUserProfile()
    this.setState({userPhoto:userProfile?.data?.data?.attributes?.photo?userProfile.data.data.attributes.photo:null})
    if (this.loaderRef.current) {
      this.loaderRef.current.addEventListener('scroll', this.handleScroll);
    }
  }

  async componentWillUnmount() {
    if (this.loaderRef.current) {
      this.loaderRef.current.removeEventListener('scroll', this.handleScroll);
    }
  }

  handleActiveTab(tab: string, path: string) {
    this.setState({ activeTab: tab });
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), path);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  uploadHighLightHandler(e:React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    this.setState({ showPopup: true });

  }

  closePopup() {
    this.setState({ showPopup: false, image: null, zoom: 1 });
  }

  handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  handleDrop = (event:React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      this.setState({ image: file });
    }
  };

  handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    const maxSizeInBytes = 10 * 1024 * 1024; // 10MB
    const minSizeInBytes = 10 * 1024; // 10KB

    // Validate file type
    if (!file || !file.type.startsWith('image/')) {
      this.setState({errMessage:'Please select an image file.',openToast:true,action:"danger"})
      return;
    }
    // Validate file size

    else if (file.size > maxSizeInBytes || file.size < minSizeInBytes) {
    this.setState({errMessage:'File size exceeds 10mb limit or less than 10kb',openToast:true,action:"danger"})
      return;
    }
    else if (file) this.setState({ image: file });


  };

  formatDate = (dateString: string) => {
    const dateObj = new Date(dateString);
    if (isNaN(dateObj.getTime())) {
      return null;
    }

    const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'long', year: 'numeric' };
    const formattedDate = dateObj.toLocaleDateString('en-GB', options);

    return formattedDate;
  };


  handleDiscardHighlight = () => {
    this.setState({ image: null, zoom: 1 });
  };

  handleCroppedImage = async () => {

    if (this.state.image) {
      const resData = await getCroppedImg(URL.createObjectURL(this.state.image), this.state.croppedAreaPixels, 1) as Blob;
      this.uploadImageData(resData)
      this.setState({ showPopup: false, image: null, zoom: 1, croppedImage:  resData});
    }

    this.handleSeenStatus()
  };

  handleSeenStatus=()=>{
    if(this.state.allHighlightdata[0]&&this.state.allHighlightdata[0].seen_status&& this.state.allHighlightdata[0].user_name==this.state.userInfo.user_name)
    {
      let temp=this.state.allHighlightdata
        temp[0].seen_status =false;
      this.setState({
        allHighlightdata: [...temp]
      });
    }
  }

  handleCropChange = (crop: { x: number; y: number }) => {
    this.setState({ crop });
  };

  onCropComplete = (_:CroppedAreaPixels , croppedAreaPixel: CroppedAreaPixels) => {
    this.setState({ croppedAreaPixels: croppedAreaPixel });
  };

  handleZoomChange = (zoom: number) => {
    this.setState({ zoom });
  };
  handleZoomChangeFromSlider = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ zoom: parseFloat(e.target.value) });
  };


 uploadImageData= async(croppedImage: Blob) =>{
    const resData: UploadResponse = await uploadImage(
      croppedImage,
      this.state.image,
      `${configJSON.baseURL.baseURL}/${configJSON.createHiglightsEndPoint}`,
      this.state.allHighlightdata,
      this.state.userInfo,
      this.state.tokenData
    ) || { errMessage: "", openToast: false, action: "" };

    
    this.setState({ ...resData, newHighlightPresent:true })

    let allIds:number[] = [];
    let allUnViewedIds:number[] = [];

    if (this.state.allHighlightdata[0].user_name === this.state.userInfo.user_name) {
  this.state.allHighlightdata[0].highlights.forEach((ele: HighlightItem) => {
    allIds.push(ele.data.id);
    if (!ele.data.attributes.is_viewed) {
      allUnViewedIds.push(ele.data.id);
    }
  });
}

    this.setState({logindUserUnViewedIds:allUnViewedIds, logindUserIds:allIds})
  }

  highlightScrollLeft = () => {
    const newPosition = this.state.scrollPosition - 200;
    this.setState({ scrollPosition: Math.max(newPosition, 0) });
  };

  highlightScrollRight = () => {
    const newPosition = this.state.scrollPosition + 200;
    this.setState({ scrollPosition: Math.min(newPosition, this.state.maxScroll) });
  };

  getHighlightOfAllUsers = () => {
    const token = this.state.tokenData;
    const headers = {
      token: token ? token : "",
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'If-Modified-Since': '0',
    };

    const url = `${configJSON.baseURL.baseURL}/${configJSON.getAllUsersHighlights}`;

    const requestMessage = new Message('RestAPIRequestMessage');

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );
    this.apiGetCallId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  convertBase64 = async (dataVal: Account[]) => {
    try {
      let modifiedData = await Promise.allSettled(dataVal.map(async (account: Account, index: number) => {
        const highlightArr = await convertImageUrlToBase64(account.highlights);
        return {
          ...account,
          highlights: highlightArr
        } as HighlightData;

      }));

      const filteredData = modifiedData
        .filter(result => result.status === 'fulfilled')
        .map(result => (result as PromiseFulfilledResult<HighlightData>).value);
    this.setState({ allHighlightdata: [...this.state.allHighlightdata,...filteredData] });
    } catch (error) {
    }
  }

handleCloseToast=()=>{
  this.setState({openToast: false})
  }

  handleViewHighlight = (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>, index: number) => {
    const target = e.target as HTMLButtonElement | HTMLDivElement;
    const id = target.id;

    if (id === "plus-icon-id" || id === "upload-btn") {
      this.setState({ showPopup: true });
    }
  else if (id!="plus-icon-id" && id!="upload-btn"){
      let tempArr = [...this.state.vistedhiglightArray];
      if (!tempArr.includes(index)) {
        tempArr.push(index);
      }

      this.setState({
        clickedHighlightIndex: index,
        vistedhiglightArray: tempArr,
      },()=>this.updateHighlightData(0,0));
      this.handleAddVisitedIds(this.state.allHighlightdata[
        index
      ]?.highlights[this.state.highlightState]?.data?.id)

    }

  }

  updateHighlightData = (clickedIndexOffset = 0, highlightStateOffset = 0) => {
    const { clickedHighlightIndex, allHighlightdata, highlightState } = this.state;
  
    const currentIndex = (clickedHighlightIndex ?? 0) + clickedIndexOffset;
    const nextHighlightIndex = highlightState + highlightStateOffset;
  
    const nextValue = allHighlightdata[currentIndex]?.highlights[nextHighlightIndex];
    if (!nextValue || nextValue.data.attributes.is_viewed) return;
  
    this.setState((prevState) => ({
      allHighlightdata: prevState.allHighlightdata.map((highlightData, index) => {
        if (index !== currentIndex) return highlightData;
  
        return {
          ...highlightData,
          highlights: highlightData.highlights.map((highlight, highlightIndex) => {
            if (highlightIndex !== nextHighlightIndex) return highlight;
  
            return {
              ...highlight,
              data: {
                ...highlight.data,
                attributes: {
                  ...highlight.data.attributes,
                  views: highlight.data.attributes.views + 1,
                  is_viewed: true,
                },
              },
            };
          }),
        };
      }),
    }));
  };

handleCloseHighlightOnNavbarClick = () =>{
    this.sendViewedHighlights()
  this.setState({clickedHighlightIndex:null})
  }

handleViewValue=()=>{
  let temp2=this.state.allHighlightdata
  if (temp2 && this.state.viewCount&&this.state.clickedHighlightIndex!=null&& temp2[this.state.clickedHighlightIndex] && temp2[this.state.clickedHighlightIndex].highlights && temp2[this.state.clickedHighlightIndex].highlights[this.state.highlightState]) {
    let prevViewCount=temp2[this.state.clickedHighlightIndex].highlights[this.state.highlightState].data.attributes.views?temp2[this.state.clickedHighlightIndex].highlights[this.state.highlightState].data.attributes.views:0
    temp2[this.state.clickedHighlightIndex].highlights[this.state.highlightState].data.attributes.views = prevViewCount+this.state.viewCount;
      this.setState({
        allHighlightdata: [...temp2]
      });
    }
  }

handleAddVisitedIds = (id:number) =>{
    let alreadyPresent = this.state.viewedHighlightIds.includes(id)
  if(!alreadyPresent){
    this.setState({viewedHighlightIds: [...this.state.viewedHighlightIds, id]})
    }
  }

  getPreviousHighlightId() {
    if (this.state.clickedHighlightIndex !== null && this.state.clickedHighlightIndex > 0) {
      const prevHighlightIndex = this.state.highlightState - 1;
      return this.state.allHighlightdata[
        this.state.clickedHighlightIndex
      ].highlights[prevHighlightIndex]?.data.id;
    }
    return null;
  }

  getNextHighlightId() {
    if (this.state.clickedHighlightIndex !== null) {
      const nextHighlightIndex = this.state.highlightState + 1;
      return this.state.allHighlightdata[
        this.state.clickedHighlightIndex
      ].highlights[nextHighlightIndex]?.data.id;
    }
    return null;
  }

showNextHiglight=()=>{

  const nextValue = this.state.allHighlightdata[this.state.clickedHighlightIndex != null? this.state.clickedHighlightIndex: 0]?.highlights[this.state.highlightState+1]

  if(!nextValue) return

  this.updateHighlightData(0, 1)

    this.handleAddVisitedIds(nextValue?.data?.id)
  let temp=this.state.highlightState+1
  if( this.state.clickedHighlightIndex!=null&&temp>=this.state.allHighlightdata[this.state.clickedHighlightIndex]?.highlights.length)this.setState({highlightState:0})
    else
  this.setState({highlightState:temp})
    this.handleViewValue()
  }

showPrevHiglight=()=>{

  const prevValue = this.state.allHighlightdata[this.state.clickedHighlightIndex != null? this.state.clickedHighlightIndex: 0]?.highlights[this.state.highlightState-1]

  if(!prevValue) return

    this.handleAddVisitedIds(prevValue?.data?.id)

  let temp=this.state.highlightState-1
  if( this.state.clickedHighlightIndex!=null&&temp<0 )this.setState({highlightState:this.state.allHighlightdata[this.state.clickedHighlightIndex]?.highlights.length-1})
    else
  this.setState({highlightState:temp})
    this.handleViewValue()
  }

handleCloseViewHighlight=()=>{
    this.sendViewedHighlights()
  this.setState({clickedHighlightIndex:null, highlightState: 0})
  }

viewHighlightScrollLeft=()=>{
  this.handleAddVisitedIds(this.state.allHighlightdata[this.state.clickedHighlightIndex != null? this.state.clickedHighlightIndex - 1: 0]?.highlights[0]?.data?.id)
  
  this.handleViewValue()
  this.setState({highlightState:0})

  let temp=this.state.clickedHighlightIndex
  if(temp!=null){if(temp<=0)
        return
else
 { 
  this.setState({clickedHighlightIndex:temp-1},()=>{
    this.updateHighlightData(0,0)})
  let tempArr=this.state.vistedhiglightArray
  if(!tempArr.includes(temp-1))
    tempArr.push(temp-1)
}}
  }

viewHighlightScrollRight=()=>{

  this.handleAddVisitedIds(this.state.allHighlightdata[this.state.clickedHighlightIndex != null? this.state.clickedHighlightIndex + 1: 0]?.highlights[0]?.data?.id)

    this.handleViewValue()
  this.setState({highlightState:0})
  let temp=this.state.clickedHighlightIndex
  if(temp!=null){if(temp>=this.state.allHighlightdata.length-1)return
else
 { this.setState({clickedHighlightIndex:temp+1},()=>{
  this.updateHighlightData(0,0)
 })
 let tempArr=this.state.vistedhiglightArray
 if(!tempArr.includes(temp+1))
   tempArr.push(temp+1)
}}
  }

handleReturnSpliceValue = (name:string) =>{
    let newValue = name.length > 11 ? name.slice(0, 10) + "..." : name
    return newValue
  }

handlelikeValue=(message:Message)=>{
    const responseJson = message?.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
  let temp=this.state.allHighlightdata
  if (temp && this.state.clickedHighlightIndex!=null&& temp[this.state.clickedHighlightIndex] && temp[this.state.clickedHighlightIndex].highlights && temp[this.state.clickedHighlightIndex].highlights[this.state.highlightState]) {
      temp[this.state.clickedHighlightIndex].highlights[this.state.highlightState].data.attributes.likes = responseJson?.likes_count;
      temp[this.state.clickedHighlightIndex].highlights[this.state.highlightState].data.attributes.is_liked = responseJson?.is_liked;
    this.setState({allHighlightdata: [...temp] });
    }

  }


  handleLikeBtnClick = (id: number, currentStatus: boolean) => {
    const token = this.state.tokenData;
  const headers={
    token:token,
      'Content-Type': 'application/json',
      'highlight_id': id.toString()
    };

    const url = `${configJSON.createHiglightsEndPoint}/${id}/${currentStatus ? 'unlike' : 'like'}`;

    const requestMessage = new Message('RestAPIRequestMessage');
    requestMessage.addData('RestAPIRequestHeaderMessage', headers);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    if (currentStatus) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'DELETE'
      );

      this.apiDeleteCallId = requestMessage.messageId;
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'POST'
      );
      this.apiPostCallId = requestMessage.messageId;

    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

createCheckListArrayForHighlights = (data: any[]) =>{
  let allHighlightedIds : number[] = []

  let newArray = data.map((element)=>{
      let newData = {
      accountId:null, highlightsIDs:[] , viewedHighlightsId:[]
      }

      newData.accountId = element.account_id

      newData.viewedHighlightsId = element.highlights
        ?.filter((highlight: any) => highlight.data.attributes.is_viewed)
        .map((highlight: any) => highlight.data.id) || [];

    allHighlightedIds = [...allHighlightedIds, ...newData.viewedHighlightsId ]

    newData.highlightsIDs = element.highlights?.map((highlight:any)=>{
        return highlight.data.id
      })

    this.setState({allHighlightedIds:[...this.state.allHighlightedIds,...allHighlightedIds]})

      return newData


    })

  this.setState({alreadyViewedHighlights:[...this.state.alreadyViewedHighlights,...newArray]})
  }

checkIsViewed = (id:number) : boolean =>{
  let getNonMatchingIds:number[] = []
  const {alreadyViewedHighlights, viewedHighlightIds} = this.state
  let accountData = alreadyViewedHighlights.filter((element:any)=> element.accountId === id)[0]
 if(accountData?.viewedHighlightsId?.length !== accountData?.highlightsIDs?.length){
  getNonMatchingIds = this.getNonMatchingIds(accountData.viewedHighlightsId,accountData.highlightsIDs)
      return getNonMatchingIds.some(element => !viewedHighlightIds.includes(element))
 }else {
      return false
    }
  }

getNonMatchingIds = (array1:number[], array2:number[]) => {
    const set1 = new Set(array1);
    const set2 = new Set(array2);

    const uniqueToSet1 = Array.from(set1).filter(id => !set2.has(id));
    const uniqueToSet2 = Array.from(set2).filter(id => !set1.has(id));

    return [...uniqueToSet1, ...uniqueToSet2];
  };

  determineHighlightsVisibility() {
    const { allHighlightdata, userType, userInfo } = this.state;
    return shouldShowHighlights(allHighlightdata, userType, userInfo);
  }

  checkNextHighLightIsThere() {
    return (
      this.state.clickedHighlightIndex !== null &&
      (this.state.clickedHighlightIndex + 1 > this.state.allHighlightdata.length - 1 || this.state.allHighlightdata.length <= 1)
    ) 
      ? "opacityZero" 
      : "";
  }

checkPrevHighLightIsThere(){
  if(this.state.clickedHighlightIndex!=null )
      return (this.state.clickedHighlightIndex - 1 >= 0 ? "" : "opacityZero")
  }
  // Customizable Area End
}

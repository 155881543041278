import React from "react";
// Customizable Area Start
import OfferContractController, {
  ContractDetailContractDataAttributes,
  ContractDetailProposalDataAttributes,
  Props,
} from "./OfferContractController.web";
import { Box, Grid, Step,
  StepLabel,
  StepContent, Divider, Typography, styled } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import StarIcon from '@material-ui/icons/Star';
import { StepperStyledComponentWithNumber } from '../../cfproposalmanagement/src/UnderReviewProposal.web'
import { CalenderIcon } from "../../../components/src/ProposalComponent";
import { Proposals } from "./Proposals.web";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import {avatar} from "../../catalogue/src/assets"
const config = require("../../../framework/src/config.js")
// Customizable Area End

export class OfferContract extends OfferContractController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderPhoto = (mainPhoto: string, backgroundColor: string) => {
    let content;
    if (mainPhoto) {
      content = (
        <img style={{ width: "100px", height: "100px", borderRadius: "50%" }}
          src={`${config.baseURL}${mainPhoto}`}
        />
      );
    } else if (backgroundColor) {
      content = (
        <Box style={{ width: "100px", height: "100px", borderRadius: "50%", backgroundColor: backgroundColor }}></Box>
      );
    } else {
      content = (
        <img src={avatar} style={{ width: "100px", height: "100px", borderRadius: "50%" }} alt="avatar" />
      );
    }
    return <>
      {content}
    </>;
  }

  cardDetail = () => {
    const { contractDtailsProposal: { data } } = this.state;
    let final: ContractDetailProposalDataAttributes = {
      id: 0,
      created_at: "",
      updated_at: "",
      service_rate: 0,
      give_your_pitch: "",
      profile_photo: "",
      background_color: "",
      location: "",
      user_name: "",
      likes_count: 0,
      view_count: 0,
      rating: 0,
      received_time_ago: ""
    }
    if (data?.attributes) {
      final = data?.attributes;
    }
    return (
      <CardBox data-test-id="navigate">
        <Box style={{ display: "flex", alignItems: "center", gap: "30px", padding: "2rem 1rem" }}>
          <Box>
            {this.renderPhoto(final.profile_photo, final.background_color)}
          </Box>
          <Box><Typography style={{
            ...webStyle.title,
            textTransform: "capitalize",
            width: "100%",
            wordBreak: "break-all"
          }}>{final.user_name}</Typography>
            <Typography style={{
              fontFamily: "Inter",
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "19.36px",
              color: "#FFF2E3",
              textTransform: "capitalize",
              paddingTop: "0.5rem"
            }}>{final.location}</Typography></Box>
        </Box>
        <Box style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around", padding: "0px 0.5rem" }}>
          <Box>
            <Typography style={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "16.94px",
              textTransform: "capitalize",
              color: "#7D7D7D",
              textAlign: "center"
            }}>Views</Typography>
            <Typography style={{
              fontFamily: "Inter",
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "19.36px",
              color: "#FFF2E3",
              paddingTop: "0.5rem",
              textTransform: "capitalize",
              textAlign: "center"
            }}>{final.view_count}</Typography>
          </Box>
          <Box style={{ borderLeft: "1px solid grey", paddingLeft: "1rem" }}>
            <Typography style={{
              fontFamily: "Inter",
              fontSize: "14px",
              lineHeight: "16.94px",
              fontWeight: 400,
              color: "#7D7D7D",
              textTransform: "capitalize",
              textAlign: "center"
            }}>Likes</Typography>
            <Typography style={{
              fontFamily: "Inter",
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "19.36px",
              textTransform: "capitalize",
              color: "#FFF2E3",
              paddingTop: "0.5rem",
              textAlign: "center"
            }}>{final.likes_count}</Typography>
          </Box>
          <Box style={{ borderRight: "1px solid grey", borderLeft: "1px solid grey", padding: "0px 10px" }}>
            <Typography style={{
              fontFamily: "Inter",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "16.94px",
              color: "#7D7D7D",
              textTransform: "capitalize",
              textAlign: "center"
            }}>Submitted rate</Typography>
            <Typography style={{
              fontFamily: "Inter",
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "19.36px",
              textTransform: "capitalize",
              color: "#FFF2E3",
              paddingTop: "0.5rem",
              textAlign: "center"
            }}>{final.service_rate.toLocaleString('en-IN')} pr/hr</Typography>
          </Box>
          <Box>
            <Typography style={{
              fontFamily: "Inter",
              fontSize: "14px",
              lineHeight: "16.94px",
              fontWeight: 400,
              color: "#7D7D7D",
              textTransform: "capitalize",
              textAlign: "center"
            }}>Ratings</Typography>
            <Box style={{
              display: "flex", alignItems: "center", justifyContent: "center", marginTop: "5px",
              height: "26px", width: "60px", background: "green", borderRadius: "3px", gap: "2px"
            }}>
              <Typography style={{
                fontFamily: "Inter",
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "19.36px",
                textTransform: "capitalize",
                textAlign: "center",
                color: "#FFF2E3",
              }}>{final.rating}
              </Typography>
              <Box><StarIcon style={{ color: "white", width: "14px", height: "13px" }} /></Box>
            </Box>
          </Box>
        </Box>
        <Box style={{ borderTop: "0.97px solid #545454", marginTop: "20px" }}>
          <Box style={{ padding: "1rem" }}>
            <Typography style={{
              fontFamily: "Inter",
              fontSize: "11px",
              lineHeight: "13.31px",
              fontWeight: 400,
              color: "#66625C",
              textTransform: "capitalize",
              textAlign: "left",
            }}>{final.received_time_ago}</Typography>
          </Box>
          <Box style={{
            padding: "1rem"
          }}>
            <Typography style={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: 700,
              color: "#FFF2E3",
              lineHeight: "16.94px",
              textTransform: "capitalize",
              textAlign: "left",
            }}>Pitch</Typography>
          </Box>
          <Box style={{ padding: "1rem", height: "6rem" }}>
            <Typography style={{
              fontFamily: "Inter",
              fontSize: "14px",
              lineHeight: "16.94px",
              fontWeight: 400,
              color: "#FFF2E3",
              textTransform: "capitalize",
              textAlign: "left",
              display: "-webkit-box",
              WebkitLineClamp: 5,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              wordWrap: "break-word"

            }}>{final.give_your_pitch}</Typography>
          </Box>
        </Box>

      </CardBox>
    )
  }

  setpperFirst = () => {
    const { contractDtailsContract: { data } } = this.state;
    let final: ContractDetailContractDataAttributes = {
      id: 0,
      project_timeline: 0,
      proposal_id: 0,
      terms_and_condition_accept: false,
      decline_contract: false,
      account_id: 0,
      overall_budget: 0,
      created_at: "",
      updated_at: "",
      milestones: {
        data: []
      },
      contract_sent_on_date: "",
      edit_request_n_date: "",
      awaing_action_till: "",
      take_action_until: "",
    }
    if (data?.attributes) { final = data?.attributes; }
    let editPlus = 0;
    const editCount = final.milestones.data.filter((milestone) => milestone.attributes.edits != null).length;
    return (
      final.milestones.data.map((label, index) => {
        const isExpanded = this.state.mileStoneExpandData.expandedTerms[index]
        const checkExapanded = isExpanded ? true : false
        if (label.attributes.edits != null) {
          editPlus = editPlus + 1;
        }
        return (
          index < 4 &&
          <Step
            className={index !== final.milestones.data.length - 1 ? 'step-with-line' : ''}
            expanded={checkExapanded} key={index} >
            <StepLabel >
              <Box style={{ background: "transparent", color: "white", fontFamily: "inter", width: '100%' }} >
                <Box
                  data-test-id="handleAccordionToggle"
                  style={OfferReceivedWebStyle.accordianSummaryStyle}
                >
                  <Box style={{ display: "flex", alignItems: "center", width: '100%', justifyContent: 'space-between', marginBottom: '10px' }}>
                    <Box
                      style={{
                        display: "flex",
                        gap: '6px',
                        alignItems: 'center'
                      }}>
                      <Typography style={webStyle.font16White}>{label.attributes.name}</Typography>
                      <Box style={{ display: 'flex', flexWrap: 'nowrap', gap: '15px' }}>
                        {checkExapanded
                          ?
                          <Box style={OfferReceivedWebStyle.downloadIconStyle} >
                            <ExpandLessIcon
                              onClick={() => this.toggleTerm(index)}
                              data-test-id="expandIconLess"
                              style={{ color: "#fff2e3", fontSize: "25px", cursor: 'pointer' }} />
                          </Box>
                          :
                          <Box style={OfferReceivedWebStyle.downloadIconStyle} >
                            <ExpandMoreIcon 
                              data-test-id="expandIcon" onClick={() => this.toggleTerm(index)} style={{ color: "#fff2e3", fontSize: "25px", cursor: 'pointer' }} />
                          </Box>
                        }
                      </Box>
                    </Box>
                    <Box 
                      style={{ display: 'flex', gap: '40px' }}>
                      <Box style={{ display: 'flex', gap: '10px' }}>
                        {CalenderIcon()}
                        <Typography style={webStyle.font16White}>
                          {this.formatDateShort(label.attributes.due_date)}
                        </Typography>
                      </Box>
                      <Typography style={webStyle.font16White}>&#8377; {label.attributes.amount}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box style={{ marginTop: '10px' }}>
                  <Typography style={webStyle.font14White400}>
                    {label.attributes.description}3
                  </Typography>
                </Box>
                {label.attributes.edits &&
                  <Box 
                    style={webStyle.editGrayDiv}>
                    <Box
                      data-test-id="handleAccordionToggle"
                      style={OfferReceivedWebStyle.accordianSummaryStyle}
                    >
                      <Box>
                        <Typography style={webStyle.font14White400}>Requested edit ({editPlus}/{editCount})</Typography>
                      </Box>
                      <Box style={{ display: "flex", alignItems: "center", width: '100%', justifyContent: 'space-between', marginBottom: '10px', marginTop: '20px' }}>
                        <Box
                          style={{
                            display: "flex",
                            gap: '6px',
                            alignItems: 'center'
                          }}>
                          <Typography style={webStyle.font16White}>{label.attributes.edits?.data.attributes.name}</Typography>
                        </Box>
                        <Box 
                          style={{ display: 'flex', gap: '40px' }}>
                          <Box 
                            style={{ display: 'flex', gap: '10px' }}>
                            {
                              CalenderIcon()
                            }
                            <Typography style={webStyle.font16White}>
                              {
                                this.formatDateShort(label.attributes.edits?.data.attributes.due_date)}</Typography>
                          </Box>
                          <Typography style={webStyle.font16White}>
                            &#8377; {label.attributes.edits?.data.attributes.amount}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box style={{ 
                        marginTop: '10px' 
                      }}
                      >
                      <Typography style={webStyle.font14White400}>{label.attributes.edits?.data.attributes.description}</Typography>
                    </Box>
                    <Box style={{ paddingInline: 0, paddingBottom: 0, flexDirection: 'column', marginTop: '10px' }}>
                      <Box style={{ display: 'flex', width: '100%', flexDirection: 'column', gap: '10px' }}>
                        <Typography className="deliveerable-style">Deliverables:</Typography>
                        {label.attributes.edits.data.attributes.edit_deliverables.data.map((item, index) => {
                          return (
                            <Typography style={webStyle.font14White400}>
                              {index + 1}.{" "}{item.attributes.deliverable}
                            </Typography>
                          )
                        })
                        }
                      </Box>
                    </Box>
                  </Box>
                }
              </Box>
            </StepLabel>
            <StepContent>
              {checkExapanded &&
                <Box style={{ paddingInline: 0, paddingBottom: 0, flexDirection: 'column', marginTop: '10px' }}>
                  <Box style={{ display: 'flex', width: '100%', flexDirection: 'column', gap: '10px' }}>
                    <Typography className="deliveerable-style">Deliverables:</Typography>
                    {label.attributes.deliverables.data.map((item, index) => {
                      return (<Typography style={webStyle.font14White400}>
                          {index + 1}.{" "}{item.attributes.deliverable}
                        </Typography>
                      )
                    })
                    }
                  </Box>
                </Box>}
            </StepContent>
          </Step>
        )
      }
      )
    )
  }

  stepperLast = () => {
    const { contractDtailsContract: {
      data
    } } = this.state;
    let final: ContractDetailContractDataAttributes = {
      id: 0,
      proposal_id: 0,
      account_id: 0,
      terms_and_condition_accept: false,
      decline_contract: false,
      project_timeline: 0,
      overall_budget: 0,
      created_at: "",
      updated_at: "",
      milestones: {
        data: []
      },
      contract_sent_on_date: "",
      take_action_until: "",
      edit_request_n_date: "",
      awaing_action_till: ""
    }
    if (data?.attributes) {
      final = data?.attributes;
    }
    let editPlus = 0;
    const editCount = final.milestones.data.filter((milestone) => milestone.attributes.edits != null).length;
    return (
      this.state.moreMilestone && final.milestones.data.map((label, index) => {
        const isExpanded = this.state.mileStoneExpandData.expandedTerms[index]
        const checkExapanded = isExpanded ? true : false
        if (label.attributes.edits != null) { 
          editPlus = editPlus + 1; 
        }
        return (
          index > 3 &&
          <Step
            className={index !== final.milestones.data.length - 1 ? 'step-with-line' : ''}
            key={index} 
            expanded={checkExapanded}>
            <StepLabel >
              <Box 
                style={{ background: "transparent", color: "white", fontFamily: "inter", width: '100%' }} >
                <Box style={OfferReceivedWebStyle.accordianSummaryStyle}
                  data-test-id="handleAccordionToggle"
                >
                  <Box style={{ display: "flex", alignItems: "center", width: '100%', justifyContent: 'space-between', marginBottom: '10px' }}>
                    <Box
                      style={{
                        gap: '6px',
                        alignItems: 'center',
                        display: "flex",
                      }}>
                      <Typography 
                        style={webStyle.font16White}>{label.attributes.name}</Typography>
                      <Box style={{ display: 'flex', flexWrap: 'nowrap', gap: '15px' }}>
                        {checkExapanded
                          ?
                          <Box style={OfferReceivedWebStyle.downloadIconStyle} >
                            <ExpandLessIcon onClick={() => this.toggleTerm(index)}
                              style={{ color: "#fff2e3", fontSize: "25px", cursor: 'pointer' }} />
                          </Box>
                          :
                          <Box style={OfferReceivedWebStyle.downloadIconStyle} >
                            <ExpandMoreIcon onClick={() => this.toggleTerm(index)} style={{ color: "#fff2e3", fontSize: "25px", cursor: 'pointer' }} />
                          </Box>
                        }
                      </Box>
                    </Box>
                    <Box style={{ display: 'flex', gap: '40px' }}>
                      <Box 
                        style={{ 
                          display: 'flex', 
                          gap: '10px' 
                        }}>
                        {CalenderIcon()}
                        <Typography style={webStyle.font16White}>{this.formatDateShort(label.attributes.due_date)}</Typography>
                      </Box>
                      <Typography style={webStyle.font16White}>
                        &#8377; {label.attributes.amount}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box style={{ 
                    marginTop: '10px' 
                  }}
                  >
                  <Typography style={webStyle.font14White400}>
                    {label.attributes.description}</Typography>
                </Box>
                {label.attributes.edits &&
                  <Box style={webStyle.editGrayDiv}>
                    <Box
                      style={OfferReceivedWebStyle.accordianSummaryStyle}
                      data-test-id="handleAccordionToggle"
                    >
                      <Box>
                        <Typography style={webStyle.font14White400}>Requested edit ({editPlus}/{editCount})</Typography>
                      </Box>
                      <Box style={{ display: "flex", alignItems: "center", width: '100%', justifyContent: 'space-between', marginBottom: '10px', marginTop: '20px' }}>
                        <Box
                          style={{
                            alignItems: 'center',
                            gap: '6px',
                            display: "flex",
                          }}>
                          <Typography style={webStyle.font16White}>{label.attributes.edits?.data.attributes.name}</Typography>
                        </Box>
                        <Box style={{ display: 'flex', gap: '40px' }}>
                          <Box 
                            style={{ 
                              display: 'flex', 
                              gap: '10px' 
                            }}>
                            {CalenderIcon()}
                            <Typography style={webStyle.font16White}>
                              {this.formatDateShort(label.attributes.edits?.data.attributes.due_date)}
                            </Typography>
                          </Box>
                          <Typography style={webStyle.font16White}>
                            &#8377; {label.attributes.edits?.data.attributes.amount}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box 
                      style={{ marginTop: '10px' }}>
                      <Typography 
                        style={webStyle.font14White400}
                        >
                          {label.attributes.edits?.data.attributes.description}
                      </Typography>
                    </Box>
                    <Box 
                      style={{ paddingInline: 0, paddingBottom: 0, flexDirection: 'column', marginTop: '10px' }}>
                      <Box 
                        style={{ display: 'flex', width: '100%', flexDirection: 'column', gap: '10px' }}>
                        <Typography className="deliveerable-style">Deliverables:</Typography>
                        {label.attributes.edits.data.attributes.edit_deliverables.data.map((item, index) => {
                          return (
                            <Typography style={webStyle.font14White400}>{index + 1}.{" "}{item.attributes.deliverable}
                          </Typography>
                          )
                        })
                        }
                      </Box>
                    </Box>
                  </Box>
                }
              </Box>
            </StepLabel>
            <StepContent>
              {checkExapanded &&
                <Box 
                  style={{ paddingInline: 0, paddingBottom: 0, flexDirection: 'column', marginTop: '10px' }}>
                  <Box style={{ display: 'flex', width: '100%', flexDirection: 'column', gap: '10px' }}>
                    <Typography 
                      className="deliveerable-style">
                        Deliverables:
                    </Typography>
                    {label.attributes.deliverables.data.map((item, index) => {
                      return (<Typography style={webStyle.font14White400}>{index + 1}.{" "}{item.attributes.deliverable}
                      </Typography>
                      )
                    })
                    }
                  </Box>
                </Box>}
            </StepContent>
          </Step>
        )
      }
      )
    )
  }

  stepperDetail = () => {
    const { contractDtailsContract: { data } } = this.state;
    let final: ContractDetailContractDataAttributes = {
      overall_budget: 0,
      id: 0,
      proposal_id: 0,
      decline_contract: false,
      account_id: 0,
      terms_and_condition_accept: false,
      created_at: "",
      updated_at: "",
      project_timeline: 0,
      milestones: {
        data: []
      },
      edit_request_n_date: "",
      contract_sent_on_date: "",
      take_action_until: "",
      awaing_action_till: ""
    }
    if (data?.attributes) {
      final = data?.attributes;
    }
    const length = final.milestones.data.length;
    const editCount = final.milestones.data.filter((milestone) => milestone.attributes.edits != null).length;
    return (
      <StepperStyledComponentWithNumber style={{ padding: '40px 40px 0px 40px' }} activeStep={0} orientation="vertical" className="about-under-review-second-box-stepper-transparent">
        {this.setpperFirst()}
        {!this.state.moreMilestone && length - 4 > 0 &&
          <Step>
            <StepLabel
              StepIconComponent={() => (
                <CustomIconContainer>
                  <Typography 
                    variant="caption" component="div" style={{ fontSize: '14px', fontWeight: 'normal' }}>
                      {`+${length - 4}`}
                  </Typography>
                </CustomIconContainer>
              )}
            >
              <Typography data-test-id="checkMoreMilestone" onClick={this.checkMoreMilestone} style={{ marginTop: '3px', textDecoration: 'underline', cursor: 'pointer' }}>{length - 4}  more milestones</Typography>
            </StepLabel>
          </Step>
        }
        {this.stepperLast()}
      </StepperStyledComponentWithNumber>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <div>
        <Box>
          <Box style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
            <Typography
              data-testid="allProposals"
              onClick={this.navigateViewWork}
              style={{
                fontFamily: "Inter",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "19.26px",
                textAlign: "left",
                color: "#FFF2E3",
                textDecoration: "underline",
                cursor: "pointer",
                width: 'fit-content'
              }}>View work details</Typography>

            {this.state.contractDtailsContract.data?.attributes.contract_sent_on_date &&
              <Typography style={webStyle.gray12}>Contract sent on {this.formatDateShort(this.state.contractDtailsContract.data?.attributes.contract_sent_on_date)}</Typography>
            }
          </Box>

          <Box
            style={{
              marginTop: "45px"
            }}>
            <Typography
              style={{
                fontFamily: "Inter",
                fontSize: "36px",
                fontWeight: 700,
                lineHeight: "43.57px",
                textAlign: "left",
                color: "#FFF2E3",
              }}>{this.state.contractAllDetails.status}
            </Typography>
          </Box>

          <Box
            style={{
              margin: "10px 0 20px"
            }}>
            <Typography
              style={{
                fontFamily: "Inter",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "19.36px",
                color: "#FFF2E3",
              }}>Deadline: {this.state.contractDtailsContract.data?.attributes.awaing_action_till}
            </Typography>
          </Box>

          <Divider style={webStyle.dividerStyle} />
        </Box>
        {this.state.contentType === "awaiting" &&
          <Box sx={webStyle.paper}>
            <Box>
              <Grid container>
                <Grid item xl={4} lg={4} sm={6} xs={12}
                  style={{ borderRight: "1px solid rgba(84, 84, 84, 1)" }}>
                  <Box
                    style={{
                      marginTop: "45px"
                    }}>
                    <Typography
                      style={webStyle.title}>Selected Proposal
                    </Typography>
                  </Box>
                  {this.cardDetail()}
                  <Box>
                    <Typography style={webStyle.font16WhiteUnderline} data-test-id="displayAllProposal" onClick={this.displayAllProposal}>
                      View all  Proposals
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xl={8}
                  lg={8}
                  md={7}
                  sm={6}
                  xs={12}
                >
                  <Box style={{ padding: '20px' }}>
                    <Box style={webStyle.opportunity_card_price_duration_box}>
                      <Box>
                        <Box>
                          <Typography
                            style={webStyle.title}>Contract Milestones</Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Box>
                          <span style={webStyle.font14White}>Start date:</span>{"   "}<span style={webStyle.font16White}>11/11/23</span>
                        </Box>
                      </Box>
                      <Box style={webStyle.flexGap50}>
                        <Box style={webStyle.titleCardWrap}>
                          <Typography style={{ ...webStyle.gray12, marginBottom: '10px' }}>Project timeline</Typography>
                          <Typography style={webStyle.opportunity_card_duration_color}>{this.state.contractDtailsContract.data?.attributes.project_timeline} months</Typography>
                        </Box>
                        <Box style={webStyle.titleCardWrap}>
                          <Typography style={{ ...webStyle.gray12, marginBottom: '10px' }}>Overall budget:</Typography>
                          <Typography style={webStyle.opportunity_card_price_duration}>&#8377; {this.state.contractDtailsContract.data?.attributes.project_timeline} pr/hr</Typography>
                        </Box>
                      </Box>
                    </Box>

                    <Box style={{ marginTop: '40px' }}>
                      {this.stepperDetail()}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        }

        {this.state.contentType === "proposalList" &&
          <Proposals data-test-id="proposal" cardId={this.props.cardId} onOfferAction={this.displayAwaiting} dropDownId={this.props.dropDownId} navigation={this.props.navigation} displayTitle={false} />
        }
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const CardBox = styled(Box)({
  maxWidth: "398px",
  width: '100%',
  margin: "18px 0",
  border: "1.15px solid #6D6D6D",
  borderRadius: "11.54px",
  overflow: 'hidden',
  "@media (max-width:960px)": {
      height: "533px",
  },
  "@media (min-width:720px)": {

  },
});

const OfferReceivedWebStyle = {
  accordianSummaryStyle: {
    fontSize: "20px",
    fontWeight: 700,
    margin:0, 
    width: '100%' as '100%',
    borderBottom: "1px solid rgba(84, 84, 84, 1)",
    lineHeight: "24.2px",
    textAlign: "left" as 'left',
    color: "#fff2e3",
    paddingLeft: "0px" as '0px',
  },
  downloadIconStyle: {
    borderRadius: '50%',
    background: "rgba(84, 84, 84, 1)",
    width: '30px', height: '30px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex'
  },
}

const CustomIconContainer = styled('div')(({ theme }) => ({
  width: '30px',
  height: '30px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: "#FFF2E3",
  color: 'rgba(17, 17, 17, 1)',
}));

const webStyle: Record<string, CSSProperties> = {
  dividerStyle: {
    backgroundColor: "rgba(84, 84, 84, 1)",
  },
  paper: {
    scrollbarWidth: "none",
    "-ms-overflow-style": "none",
  },
  title: {
    fontFamily: "Inter, sans-serif",
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '24.2px',
    color: '#FFF2E3'
  },
  opportunity_card_price_duration_box: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between'
  },
  opportunity_card_price_duration: {
    padding: "6px 16px 6px 16px",
    fontSize: "14px",
    borderRadius: "24px",
    backgroundColor: "#1D1D1D",
    fontFamily: "Inter,sans-serif",
    color: "#1CBAE0",
    fontWeight: 600,
  },
  opportunity_card_duration_color: {
    padding: "6px 16px 6px 16px",
    fontSize: "14px",
    borderRadius: "24px",
    backgroundColor: "#1D1D1D",
    fontFamily: "Inter,sans-serif",
    color: "#FFB9C7",
    fontWeight: 600,
  },
  titleCardWrap: {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column'
  },
  gray12: {
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '14.52px',
    color: '#7D7D7D'
  },
  font14White: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '16.94px',
    color: '#FFF2E3'
  },
  font16White: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '19.36px',
    color: '#FFF2E3'
  },
  font16WhiteUnderline: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '19.36px',
    color: '#FFF2E3',
    textDecorationLine: 'underline',
    cursor: 'pointer'
  },
  flexGap50: {
    display: 'flex',
    gap: '50px',
    alignItems: 'center',
    justifyContent: 'center'
  },
  editGrayDiv: {
    backgroundColor: '#323232',
    padding: '15px',
    borderRadius: '11px',
    marginTop: '15px'
  }
}
// Customizable Area End
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import React, { ChangeEvent, RefObject } from "react";
import { compressedImageData } from "../../../components/src/Base64Conversion";
import dayjs, { Dayjs } from "dayjs";
import { getStorageData } from "framework/src/Utilities";
const config = require("../../../framework/src/config.js")

export interface Response {
  account_id: string | number,
  cover_image_url: string,
  likes: string | number,
  views: string | number,
  project_name: string,
  username: string,
  saved_count: string | number
}
interface AddedProject {
  project_title: string;
  description: string;
  project_cover_image:string,
  user_name:string,
  like_count: number;
  view_count: number;
  saved_count: number;
}
interface AttachedFile {
  file_name: string;
  url: string;
}

interface CollectionList {id:null | number,name:string,count:number}
interface Skill {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  category_id: number;
}
interface WorkOpportunityAttributes {
  id: number;
  title: string;
  description: string;
  work_type: string;
  deliverables: string;
  experience_level: string;
  rate_type: string;
  rate_amount: number;
  required_hours_per_week: number;
  project_timeline: number;
  files_or_links: string;
  question: [];
  start_date: string | null;
  project_timeline_type: string;
  location: string;
  agree_to_terms_and_conditions: boolean;
  created_at: string;
  updated_at: string;
  added_projects: AddedProject[]; 
  attached_files: AttachedFile[]; 
  skills: Skill[]; 
}
interface WorkOpportunity {
  id: string;
  type: string;
  attributes: WorkOpportunityAttributes;
}

export interface Error {
  serviceRate: number,
  pitch: string,
  startDate: Dayjs ,
  clientQuestion1: string,
  clientQuestion2: string,
}

export interface ParticularCollection
		{
			"id": string,
			"type": string,
			"attributes": {
				"project_name": string,
				"start_date": null | Date,
				"end_date": null | Date,
				"url": null | string,
				"description": string,
				"username": string,
				"account_id": number,
				"profile_photo": string,
				"cover_image_url": string,
				"project_images": string[],
				"likes": number,
				"views": number,
				"saved_count": number,
				"collection_name": string,
				"is_liked": boolean,
				"is_saved": boolean
			}
}
export interface Touched {
  serviceRate: boolean,
  pitch: boolean,
  startDate: boolean,
  clientQuestion1 : boolean,
  clientQuestion2 : boolean
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  selectedOption: string;
  open: boolean,
  message: string,
  action: string,
  files: {
    filename:string
      data: string
  }[],
  files_or_links: string,
  linkList: string[],
  selectedProjectList: ParticularCollection[],
  previewData:any
  showModal: boolean,
  tempSelectedProjectList: string[],
  openCalender: boolean,
  dateData: Dayjs,
  workOppertunityData: WorkOpportunity,
  pitchData: string,
  hourlyRate: number,
  platformFee: number,
  amountAfterFees: number,
  projectEstimate: number,
  overallEstimateAfterFees: number,
  Question1 : string | number,
  Question2 : string | number,
  Question:string[]
  isCheckedTermsAndCondtions : boolean,
  selectAll: boolean,
  selectedProjects: boolean[],
  projects:[],
  collectionList: CollectionList[],
  particularCollectionData:ParticularCollection[],
  radioUpdate : string,
  showCollection:boolean
  currentCollection:number;
  isWordLimitCompleted:boolean;
  isRateLimitCompleted:boolean;
  isAnswersFilled:boolean[],
  userInfo:{
    meta:{
      token:string,
      id:string
    }
  },
  proposalPopUp:boolean;
  profileData:any;
  currentPage:number;
  totalCount:number;
  currentCollectionId:number|null;
  accordian:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfproposalmanagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  workOppertunityGetApiCallId:string = "";
  profileApiId:string=""
  collectionNameGetApiCallId:string = "";
  postPraposalApiCallId:string = ""
  collectionDataID:string="";
  refElm: RefObject<HTMLInputElement> = React.createRef();
  modalRef: RefObject<HTMLDivElement> = React.createRef();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionRequestMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      selectedOption: 'selectProjects',
      previewData:[],
      open: false,
      message: "",
      userInfo:{meta:{token:"",id:""}},
      action: "",
      files: [],
      isAnswersFilled:[],
      files_or_links: "",
      linkList: [],
      currentCollection:0,
      isWordLimitCompleted:false,
      isRateLimitCompleted:false,
      selectedProjectList: [],
      showModal: false,
      tempSelectedProjectList: [],
      openCalender: false,
      dateData: dayjs() || '',
      profileData:[],
      workOppertunityData: {
        id: "0",
        type: "",
        attributes: {
          id: 0,
          title: "",
          description: "",
          work_type: "",
          deliverables: "",
          experience_level: "",
          rate_type: "",
          rate_amount: 0,
          required_hours_per_week: 0,
          project_timeline: 0,
          files_or_links: "",
          question: [],
          start_date: null,
          project_timeline_type: "",
          location: "",
          agree_to_terms_and_conditions: true,
          created_at: "",
          updated_at: "",
          added_projects: [],
          attached_files: [
            {
              file_name: "",
              url: ""
            },
          ],
          skills: []
        }
      },
      pitchData: "",
      Question1: "",
      Question:[],
      Question2: "",
      hourlyRate: 0,
      platformFee: 20, // Platform fee in percentage
      amountAfterFees: 0,
      projectEstimate: 0,
      overallEstimateAfterFees: 0,
      isCheckedTermsAndCondtions: false,
      selectAll: false,
      selectedProjects: [],
      projects: [],
      collectionList: [],
      particularCollectionData:[],
      radioUpdate: "fullProfile",
      showCollection:false,
      proposalPopUp:false,
      currentPage:1,
      currentCollectionId:0,
      accordian:false,
      totalCount:0
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
  
  if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.workOppertunityGetApiCallId) {
      this.setState({
        workOppertunityData: responseJson.data
      },()=>this.handleIsAnswerFilled());
    }
    
    if (apiRequestCallId === this.collectionNameGetApiCallId) {
      if (responseJson?.data?.collections) {
        this.setState({
          collectionList: responseJson.data.collections,
          currentCollectionId: responseJson.data.collections[0].id
        }, () => {
          this.collectionData(responseJson.data.collections[0].id); 
        });
      }
      
    }
    this.handleProposalResponse(responseJson,apiRequestCallId)
    
    
  }

    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  handleIsAnswerFilled = () =>{
    const isAnsweredArray = new Array(this.state.workOppertunityData.attributes.question.length).fill(false);
    const questionArray = new Array(this.state.workOppertunityData.attributes.question.length).fill("")
    this.setState({isAnswersFilled:isAnsweredArray, Question:questionArray})
  }
  handleAbutWorkOpen=()=>{
    this.setState({accordian:!this.state.accordian})
  }
  handleSelectAll = () => {
    const { particularCollectionData, tempSelectedProjectList, selectedProjectList } = this.state;
  
    if (this.areAllProjectsSelected()) {
      const updatedTempList = tempSelectedProjectList.filter(id => !particularCollectionData.some(item => item.id === id));
      const updatedSelectedList = selectedProjectList.filter(project => !particularCollectionData.some(item => item.id === project.id));
  
      const groupedProjects = updatedSelectedList.reduce((acc: any, project) => {
        const collectionName = project.attributes.collection_name;
        if (!acc[collectionName]) {
          acc[collectionName] = {
            collection_name: collectionName,
            data: [],
          };
        }
        acc[collectionName].data.push(project.attributes);
        return acc;
      }, {});
  
      this.setState({
        tempSelectedProjectList: updatedTempList,
        selectedProjectList: updatedSelectedList,
        previewData: Object.values(groupedProjects), // Update previewData
      });
    } else {
      const newIds = particularCollectionData.map(item => item.id);
      const updatedSelectedList = [...new Set([...selectedProjectList, ...particularCollectionData])];
      const updatedTempList = [...new Set([...tempSelectedProjectList, ...newIds])];
  
      const groupedProjectsData = updatedSelectedList.reduce((acc: any, project) => {
        const collectionNameData = project.attributes.collection_name;
        if (!acc[collectionNameData]) {
          acc[collectionNameData] = {
            data: [],
            collection_name: collectionNameData,
          };
        }
        acc[collectionNameData].data.push(project.attributes);
        return acc;
      }, {});
  
      this.setState({
        previewData: Object.values(groupedProjectsData), // Update previewData
        selectedProjectList: updatedSelectedList,
        tempSelectedProjectList: updatedTempList,
      });
    }
  };
  
  getUserProfileImage = async () => {
    const tokens = await getStorageData("userInfo");
    let newToken = JSON.parse(tokens)
    const { meta: { token ,id} } = newToken;   
   const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.profileApiId = getValidationsMsg.messageId
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetUserImageProfile + `${id}`  )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "GET"
    );
    
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }  
  toggleSelectProject = (item: ParticularCollection) => {
    const { tempSelectedProjectList, selectedProjectList } = this.state;
    const isAlreadySelected = tempSelectedProjectList.includes(item.id);
  
    let updatedTempList, updatedSelectedList;
  
    if (isAlreadySelected) {
      updatedTempList = tempSelectedProjectList.filter(projectId => projectId !== item.id);
      updatedSelectedList = selectedProjectList.filter(project => project.id !== item.id);
    } else {
      updatedTempList = [...tempSelectedProjectList, item.id];
      updatedSelectedList = [...selectedProjectList, item];
    }
  
    const groupedProjectsParticular = updatedSelectedList.reduce((acc: any, project) => {
      const collectionNameParticular = project.attributes.collection_name;
      if (!acc[collectionNameParticular]) {
        acc[collectionNameParticular] = {
          data: [],
          collection_name: collectionNameParticular,
        };
      }
      acc[collectionNameParticular].data.push(project.attributes);
      return acc;
    }, {});
  
    this.setState({
      selectedProjectList: updatedSelectedList,
      previewData: Object.values(groupedProjectsParticular), 
      tempSelectedProjectList: updatedTempList,
    });
  };
  
  
  areAllProjectsSelected = () => {
    const { particularCollectionData, tempSelectedProjectList } = this.state;
    return particularCollectionData&& particularCollectionData.length>0 ? 
    particularCollectionData.every(item => tempSelectedProjectList.includes(item.id)):
    false;
  };
  

  handleViewCheck = (item: ParticularCollection) => {
    const matchedData = this.state.tempSelectedProjectList.includes(item.id);
    return matchedData ? true : false
  }

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  handleClose = () => {
    this.setState({ open: false });
  }

  handleProposalResponse(responseJson:any,apiRequestCallId:string){
    if (apiRequestCallId === this.collectionDataID) {
      this.setState({
        particularCollectionData:responseJson?.data,
        totalCount:Math.ceil(responseJson?.meta?.project_count/6)
      });
    }
    if(apiRequestCallId===this.profileApiId){
      this.setState({profileData:responseJson})
    }
    if(apiRequestCallId==this.postPraposalApiCallId){
      if (!responseJson.error) {
        this.setState({proposalPopUp:false})
      this.setState(() => ({
        ...this.state,
        open: true,
  
        postOpportunityLoader: false,
        action: "success",
        message: "Proposal sent successfully",
        title: ""
      }), () => {
        setTimeout(() => {
          this.redirectTo("LandingPage");
        }, 2000);
      });
    }
    else{
      this.setState(() => ({
        ...this.state,
        open: true,
        proposalPopUp: false,
        action: "danger",
        message:responseJson.error,
        title: ""
      }))
    }
    }
   
  }
  openImageInNewTab(base64Data:string) {
    const newTab :any= window.open();
    newTab.document.body.innerHTML = `<img src="${base64Data}" alt="Image" style="max-width:100%; max-height:100%;" />`;
  }
  redirectTo = (url: string) => {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), url);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  createObjectArray = () => {
    let worldataObj: { number: number; type: string; fileName?: string[]; fileUrl?: string; link?: string; title?: string; image?: string; username?: string; views?: number; likes?: number; savedCount?: number; }[] = [];
    let counter = 0;  
    if (Array.isArray(this.state.workOppertunityData.attributes.attached_files) && this.state.workOppertunityData.attributes.attached_files.length) {
      this.state.workOppertunityData.attributes.attached_files.forEach(file => {
        counter++;
        worldataObj.push({
          number: counter,
          type: 'fileName',
          fileName: [file.file_name],
          fileUrl: file.url
        });
      });
    }  
   
    if (this.state.workOppertunityData.attributes.files_or_links) {
      let links=this.state.workOppertunityData.attributes.files_or_links?.split(",")
      links&& links.length>0 &&links.forEach(link=>{
        counter++;
        worldataObj.push({
          number: counter,
          type: 'link',
          link:link
        });
      })
    }  
  
    if (Array.isArray(this.state.workOppertunityData.attributes.added_projects) && this.state.workOppertunityData.attributes.added_projects.length) {
      this.state.workOppertunityData.attributes.added_projects.forEach((projectData, index) => {
        counter++;
        worldataObj.push({
          number: counter,
          type: 'project',
          title: projectData.project_title,
          image: config.baseURL + projectData.project_cover_image,
          username: projectData.user_name,
          views: projectData.view_count,
          likes: projectData.like_count,
          savedCount: projectData.saved_count
        });
      });
    } 
    return worldataObj;
  };



  handleRateChange = (event: ChangeEvent<HTMLInputElement>) => {

    const hourlyRate = parseFloat(event.target.value) || 0;
  
   
  if (hourlyRate>5000) {
        this.setState({  isRateLimitCompleted: true  })
    }
   else{
      this.setState({ hourlyRate,isRateLimitCompleted:false }, this.calculateRates);
    }
  };

  calculateRates = () => {
    const { hourlyRate, platformFee, workOppertunityData } = this.state;
    const { project_timeline_type, project_timeline, required_hours_per_week } = workOppertunityData.attributes;
  
    const amountAfterFees = hourlyRate * (1 - platformFee / 100);
    
    const timelineMultiplier = project_timeline_type && project_timeline_type?.toLowerCase().includes('month') ? 4 : 1;
  
    const adjustedTimeline = project_timeline * timelineMultiplier;
    const projectEstimate = hourlyRate * adjustedTimeline * required_hours_per_week;
    const overallEstimateAfterFees = amountAfterFees * adjustedTimeline * required_hours_per_week;
  
    this.setState({
      amountAfterFees,
      projectEstimate,
      overallEstimateAfterFees,
    });
  };

  handlePitchData = (e: string) => {
    const inputValue = e.split(" ");
                             
                               
                              if (inputValue.length >101) {
                                    this.setState({  isWordLimitCompleted: true  })
                                }
                               else{
                                  this.setState({ isWordLimitCompleted: false,pitchData: e })
                                }
  }
handleBackWindow=()=>{
  window.history.back()
}
  showPitchDataError = () =>{
    return this.state.isWordLimitCompleted ? "Reached Limit of 100 words" : ""
  }

  handleResetError = (index:number) =>{
    let arr = this.state.isAnswersFilled
    arr[index] = false
    this.setState({isAnswersFilled:arr})
  }

  handleQuestionData = (index:number,value:string) =>{
    let arr = this.state.isAnswersFilled
    let newValue = this.state.Question
   
    if(value.length > 500){  
      arr[index] = true

    }else{
      arr[index] = false
      newValue[index] = value
    this.setState({Question:newValue})   

    }
    this.setState({isAnswersFilled:arr})
  }
   weeksToExactMonth=(weeks:number,type:string) =>{
    if(type.toLowerCase()=='month'){
      return weeks;
    }
    else{

      const startDate = new Date(0); 
      const days = weeks * 7;
      const resultDate = new Date(startDate.getTime() + days * 24 * 60 * 60 * 1000); 
      
      return resultDate.getUTCMonth() + 1; 
    }
    }

  handleTermsAndConditions = (e:boolean) => {
    this.setState({isCheckedTermsAndCondtions:e})
  }
  async componentDidMount() {
  let userInfo= await getStorageData("userInfo")
  this.setState({userInfo:JSON.parse(userInfo)})
    this.getWorkOppertunity()
    this.getCollectionAPI()
    this.getUserProfileImage();
  }

  updateLink = (data: string) => {
    this.setState({
      files_or_links: data
    });
  }
  handleDate = (data: Dayjs) => {
    return(this.setState({ dateData: data }))
  }
  handleCurrentDate = (): Dayjs => {
    return dayjs();
  }
  closeCalender = () => {
    this.setState({
      openCalender: false
    });
  }

  handleDisable = () => {
    const { Question, pitchData, dateData, hourlyRate, isCheckedTermsAndCondtions,isWordLimitCompleted } = this.state;
  const answer=this.state.isAnswersFilled.some((item)=>{
    return item==true
  })
    return (
      pitchData.length === 0 ||
      dateData === null || 
      hourlyRate === 0 ||
      !isCheckedTermsAndCondtions ||
      Question.some(question => question.trim() === "") ||
      answer||
      isWordLimitCompleted
    ) ? true : false
  };

handleBackGroundColor=()=>{
  return this.handleDisable()?
                           "#7d7d7d": "rgba(255, 242, 227, 1)"
}
  handleareAllProjectsSelected=()=>{
    return this.areAllProjectsSelected() ? "Deselect all" : "Select all"
  }
  handleSubmit = async () => {
    const {Question , pitchData, dateData, hourlyRate , isCheckedTermsAndCondtions} = this.state

    if(pitchData.length === 0 || dateData === undefined || hourlyRate === 0 || !isCheckedTermsAndCondtions || (Question.length && Question.includes("") )){
      return
    }
    const id = await getStorageData("sendPraposalID") 
     const tokens = this.state.userInfo;
    
    const { meta: { token } } = tokens;   
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: token
      };
let projectIds;
      if (this.state.tempSelectedProjectList?.length) {
         projectIds = this.state.tempSelectedProjectList;

      }
let files;

      if (this.state.files?.length) {
        files = this.state.files;

     }
      const body = {
        proposal: {
          work_opportunity_id: id,
          share_full_profile : this.state.showCollection ?"false":"true" ,
          answer: this.state.Question.length > 0 ? JSON.stringify(this.state.Question ): '',
          service_rate: this.state.hourlyRate,
          give_your_pitch: this.state.pitchData,
          terms_and_conditions: true,
          links: this.state.linkList.join(','),
          start_date: this.state.dateData.format('YYYY-MM-DD'),
          ...(projectIds && { project_ids: projectIds }) ,
          ...(files && { image_files: files }) 
        }
      };
      
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
    this.postPraposalApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_joblisting/proposals"
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);

  };
  getRateTypeLabelProposal = () => {
    const  rate_types  = this.state.workOppertunityData.attributes.rate_type;
    if (rate_types && rate_types.includes("hourly")) {
      return "pr/hr";
    }
    return "";
  }


  handleSelectChange = (value:string) => {
    this.setState({radioUpdate:value , showCollection : value === "fullProfile" ? false : true},()=>{
      if(!this.state.showCollection)
      this.setState({tempSelectedProjectList:[],selectedProjectList:[],
        previewData: [],
        })
      if(  this.state.collectionList?.length>0){

        this.setState({currentCollectionId:this.state.collectionList[0].id,
          })
        this.collectionData(this.state.collectionList[0].id);
      }
        
    
    })
  };

  handleDeleteLink = (id:number) =>{
    let newValue = this.state.linkList.filter((_,index)=>{
      return index !== id
    })

    this.setState({linkList:newValue})
  }

  handleDeleteFile = (id:number) =>{
    let newValue = this.state.files.filter((_,index)=>{
      return index !== id
    })

    this.setState({files:newValue})
  }


  handlePropagation = (event: any) => {
    event.stopPropagation();
  }
  openCalender = (event: any) => {
    this.setState({
      openCalender: true
    });
    this.handlePropagation(event);
  }

  isProjectSelected = () => {
    return this.state.selectedProjectList.length > 0 ? true : false;
  }
  isFileAdded = () => {
    return this.state.files.length > 0 ? true : false;
  }
  handlelinkData = (event: any) => {
    const keyData = event.keyCode ? event.keyCode : event.which
    if (keyData === 13 && this.isUrlValid(this.state.files_or_links)) {
      this.setState({
        linkList: [...this.state.linkList, this.state.files_or_links],
        files_or_links: ""
      })
    }
  }
  isUrlValid = (url: string) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      this.setState({
        open: true,
        message: "Please enter valid link",
        action: "danger"
      })
      return false;
    }
  }
  validFileFormat = (fileData: File) => {
    const validFileTypes = [
      "image/jpeg",
      "image/png",
      "image/jpg",
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ];
    if (!validFileTypes.includes(fileData.type)) {
      this.setState({
        open: true,
        message: "Please select a valid file (jpg, png, pdf, doc, docx).",
        action: "danger"
      });
      return false;
    }
    return true
  }
  handleResourceFiles = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if(this.state.files.length >= 5)
      return
    const maxSizes = {
      image: 25 * 1024 * 1024
    };
    const fileTypes = {
      image: ["image/jpeg", "image/png", "image/jpg"]
    };
    if (!event.target.files) return;
    const tempFile = event.target.files[0];
    const isImage = fileTypes.image.includes(tempFile.type);
    if (!this.validFileFormat(tempFile)) {
      this.refElm.current && (this.refElm.current.value = "");
      return;
    }
    const maxFileSize = maxSizes.image;
    if (tempFile.size > maxFileSize) {
      this.setState({
        open: true,
        action: "danger",
        message: `File size exceeds 25MB. Please upload a smaller file.`
      });
      this.refElm.current && (this.refElm.current.value = "");
      return;
    }
  
    const base64Data = await this.convertToBase64(tempFile);
  
    const fileObject = {
      filename: tempFile.name,
      data: base64Data
    };
  
    this.setState((prevState:any) => ({
      files: [...prevState.files, fileObject]
    }));
  
    this.refElm.current && (this.refElm.current.value = "");
   
    
  }

  convertToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }
  
  getWorkOppertunity = async () => {
    const tokens =  this.state.userInfo;
    const id = await getStorageData("sendPraposalID");
    
    const { meta: { token } } = tokens;   
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.workOppertunityGetApiCallId = getValidationsMsg.messageId
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_landingpage2/work_opportunities/${id}`)
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     'GET'
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }  
  handleChange=(event:any,page:any)=>{
    this.setState({currentPage:page})
 }
 componentDidUpdate(prevProps: any, prevState: any) {
   
  if(
this.state.currentPage!==prevState.currentPage
     ){
this.collectionData(this.state.currentCollectionId)
 }
}
handleCollection=(id:number|null)=>{
  this.setState({currentCollectionId:id,currentPage:1})
  this.collectionData(id)
}

  collectionData= async (collectionId:number|null)=>{
    const tokens = this.state.userInfo;
    const { meta: { token } } = tokens;   
    const { meta: { id } } = tokens;   
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_portfolio_management/portfolios/get_projects_based_collection?collection_id=${collectionId}&account_id=${id}&page=${this.state.currentPage}&per_page=6`)
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     'GET'
    );
    this.collectionDataID = getValidationsMsg.messageId
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getCollectionAPI = async () => {
    const tokens = this.state.userInfo;
    const { meta: { token } } = tokens;   
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/bx_block_projectportfolio/get_user_collections' )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     'GET'
    );
    this.collectionNameGetApiCallId = getValidationsMsg.messageId
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }  
  handlePopUpClose=()=>{
    this.setState({proposalPopUp:false})
      }
  handlePopUpOpen=()=>{
this.setState({proposalPopUp:true})
  }
  // Customizable Area End
}

export const leftIcon = require("../assets/leftIcon.svg");
export const messageIcon = require("../assets/messageIcon.svg");
export const ratingIcon = require("../assets/ratingIcon.svg");
export const shortListIcon = require("../assets/shortListIcon.svg");
export const downloadIcon = require("../assets/downloadIcon.svg");
export const eyeIcon = require("../assets/eyeIcon.svg");
export const loveIcon = require("../assets/loveIcon.svg");
export const saveIcon = require("../assets/saveIcon.svg");
export const unShortListIcon = require("../assets/unShortListIcon.svg");
export const calendar = require("../assets/image_Calendar.png")
export const coverImage = require("../assets/coverImage.png");
export const dummyImage = require("../assets/ic_userlogo.png");

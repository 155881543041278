// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
const config = require("../../../framework/src/config");

import { getToken } from "../../../components/src/Base64Conversion";
import { setStorageData } from "../../../framework/src/Utilities";
export interface Props {
    navigation: any;
    id: string;
    tabNo:number
}








interface S {
    loading: boolean;

    workOppertunityData: any;

    openOppertunityProposal: boolean;
    pendingList: any;
    token: string
    proposalData: any;
    pendingListId: number;
    accordianPop: boolean;
}


interface SS {
    id: string;
}

export const configJSON = require("./config");

export default class UnderReviewController extends BlockComponent<Props, S, SS> {
    pendingProposalAPIid: string = "";
    pendingProposalDetailsAPIid: string = ""

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);


        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionRequestMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            loading: false,
            accordianPop: false,
            workOppertunityData: [],
            openOppertunityProposal: false,
            pendingList: [],
            token: "",
            proposalData: [],
            pendingListId: 0

        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    }

    async receive(from: string, message: Message) {
        runEngine.debugLog('Message Recived', message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId == this.pendingProposalDetailsAPIid) {
                this.setState({ loading: false })

                this.setState({ workOppertunityData: responseJson?.work_opportunity_data?.data })
                this.setState({ proposalData: responseJson?.proposal_data?.data })
            }
            if (apiRequestCallId == this.pendingProposalAPIid) {
                this.setState({ loading: false })
                if(!responseJson.error){

                    this.setState({ pendingList: responseJson?.data })
                    if (responseJson && responseJson?.data[0]?.id) {
                        this.setState({ pendingListId: responseJson.data[0].id })
                        this.getPendingDeailsAPI(responseJson.data[0].id)
                    }
                }
               
            }
        }

    }


    getTokenData = async () => {
        const token = await getToken();
        this.setState({ token: token })
    };

    async componentDidMount() {
        await this.getTokenData();
        this.getPendingListAPI()
    }


    getPendingListAPI = async () => {
        let endPoint=this.props.tabNo==2?
        'bx_block_cfproposalmanagement/contract_offers/archived_proposal_list'
        :'bx_block_cfproposalmanagement/contract_offers/pending_list_proposal'
        this.setState({ loading: true })
        const headers = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.pendingProposalAPIid = getValidationsMsg.messageId
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint)
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'GET'
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }
    getPendingDeailsAPI = async (id: number) => {
        let endPoint=this.props.tabNo==2?
        `/bx_block_cfproposalmanagement/contract_offers/archived_proposal_detail?id=${id}`:
        `bx_block_cfproposalmanagement/contract_offers/pending_proposal_detail?id=${id}`
        const headers = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.pendingProposalDetailsAPIid = getValidationsMsg.messageId
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint)
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'GET'
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }
  

    handleNavigateFind =(data:any) =>{
        let localdata = {
          account_id: data.account_id,
          user_role : "client"
        }
            setStorageData("PublicId",JSON.stringify(localdata));
          
        const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "PublicUserProfile");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
      }
    handleAbutWorkOpen = () => {
        this.setState({ accordianPop: !this.state.accordianPop })

    }
    getRateTypeLabelProposal = (data: any) => {
        const rate_types = data?.attributes?.rate_type;
        if (rate_types && rate_types.includes("hourly")) {
            return "pr/hr";
        }
        return "";
    }


}


// Customizable Area End

// Customizable Area Start
export const customStyle = {
    inputField: {
        '& .MuiInputBase-input': {
          backgroundColor: "transparent",
          color: "#FFF2E3",
          border: "none",
          fontFamily: "Inter, sans-serif",
          fontSize: "16px",
          lineHeight: "19.36px",
          textAlign: "left",
          borderBottom: "none",
          '&::placeholder': {
            color: "#676767 !important",
            opacity: 1 
          }
        },
        '&::before': {
          borderBottom: "none"
        },
        '&::after': {
          borderBottom: "none"
        },
        '&:hover:not(.Mui-disabled)::before': {
          borderBottom: "none !important"
        }
    },
    buttonField: {
        textTransform: "none",
        '&.button-elm': {
            height: "44px",
            borderRadius: "7px",
            alignItems: "center",
            justifyContent: "center",
            verticalAlign: "middle",
            fontFamily: "Inter-Medium,sans-serif",
            lineHeight: "24px",
            letterSpacing: "0.38px",
            fontSize: "16px",
            display: "flex",
            border: "none",
            gap: "10px",
            backgroundColor: "transparent"
        },
        '&.button-elm.secondary-btn': {
            backgroundColor: "#fff2e3"
        },
        '&.button-elm.disabled-btn': {
            backgroundColor: "#7d7d7d"
        },
        '&.button-elm.disabled-btn, &.button-elm.secondary-btn': {
            color: "#121212"
        },
        '&.button-elm.default-btn': {
            color: "#fff2e3",
            backgroundColor: "#0d0d0d"
        }
    },
    tagsElm: {
        '& .tags': {
            color: "#fff2e3",
            height: "37px",
            fontSize: "12px !important",
            lineHeight: "14.52px",
            textAlign: "center",
            verticalAlign: "middle",
            border: "0.5px solid #fff2e3",
            backgroundColor: "transparent",
            borderRadius: "18.2px",
            fontFamily: "Inter, sans-serif !important",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingLeft: "22px",
            paddingRight: "22px",
            whiteSpace: "nowrap",
            cursor: "pointer",
            width: "max-content"
        },
        '& .tags-active': {
            borderWidth: "1px",
            fontFamily: "Inter-SemiBold, sans-serif !important",
            backgroundColor: "#323232",
            borderRadius: "21px",
            columnGap: "10px"
        }
    }
};
// Customizable Area End
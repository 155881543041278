Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account_block/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.passwordError = "Password not valid.";
exports.emailError = "Email cannot be blank.";
exports.userNameError = "Username cannot be blank.";
exports.fullNameError = "Name cannot be blank.";
exports.acceptConditionsError= "Please accept terms and conditions";
exports.userTypeError = "Please select a user type.";
exports.imageUploadError = "Please select a valid image.";
exports.fileSizeErrorMsg = " is too large. Allowed maximum size is 20MB!"
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";
exports.createProfile = "bx_block_profile/profiles";
exports.baseURL = require('../../../framework/src/config');
exports.loginAPIEndPointPath = "bx_block_login/login"
exports.sendBirdRegistrationEndPoint = "bx_block_chat/users/create_user";
exports.getDetailsOfLinkedInUserApiEndPoint = "bx_block_login/get_linkdin_details"
exports.redirectURL = "&redirect_uri="
exports.apiMethodTypeProfile = "PUT";
exports.googleLoginApi = "account_block/accounts";
exports.errorText = "Error";
exports.successText = "Success";
exports.errorMessage = "Something went wrong";
exports.acceptText = "Accept";
exports.tncText = "terms & conditions";
exports.designerText = "Designer";
exports.clientText = "Client";
exports.accountBtn="Create Account";
exports.welcomeHeading = "Welcome to Deziner’s Knot";
exports.paraText= "Please take a moment to complete your account";
exports.profileSelect = "Select your profile";
exports.usernameText = "Username";
exports.userErrorMsg = "Enter valid username";
exports.userNameErrorMsg = "Username is not valid";
exports.sendbirdSuccessMessage = ".";
// Customizable Area End

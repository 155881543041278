Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.getAuctionApiEndPoint = "/bx_block_auctionbidding/auction_biddings";
exports.getAuctionApiMethod = "GET";
exports.getAuctionApiContentType = "application/json";

exports.getAuctionDetailApiEndPoint = "/bx_block_auctionbidding/auction_biddings";
exports.getAuctionDetailApiMethod = "GET";
exports.getAuctionDetailApiContentType = "application/json";

exports.getBiddingApiEndPoint = "/bx_block_auctionbidding/bids_listing";
exports.getBiddingApiMethod = "GET";
exports.getBiddingApiContentType = "application/json";

exports.placeBidApiEndPoint = "/bx_block_auctionbidding/place_bid";
exports.placeBidApiMethod = "POST";
exports.placeBidApiContentType = "application/json";

exports.loginApiEndPoint = "/login/login";
exports.loginApiMethod = "POST";
exports.loginApiContentType = "application/json";

exports.AuctionAcceptedNotificationApiEndPoint ="/bx_block_push_notifications/push_notifications";
exports.AuctionAcceptedNotificationApiMethod = "GET";
exports.AuctionAcceptedNotificationApiContentType = "application/json";

exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "AuctionBidding";
exports.labelBodyText = "AuctionBidding Body";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End
import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  withStyles,
  WithStyles,
  Theme,
  createStyles,
  Box,
  Divider
} from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    dialog: {
      backgroundColor: '#222222',
      width: '478px',
      borderRadius: '12px',
      margin: '0'
    },
    title: {
      color: '#FFF2E3',
      fontWeight: 600,
      textAlign: 'center' as const,
      fontFamily: 'Inter',
      fontSize: '20px',
      lineHeight: '24.2px',
    },
    typography: {
      color: '#F5E8D3',
      fontSize: '16px',
      fontFamily: 'Inter-Medium, sans-serif',
      fontWeight: 400,
      lineHeight: '24.88px',
    },
    cancelButton: {
      textTransform: "none",
      height: "44px",
      borderRadius: "7px",
      alignItems: "center",
      justifyContent: "center",
      verticalAlign: "middle",
      fontFamily: "Inter-Medium,sans-serif",
      lineHeight: "24px",
      letterSpacing: "0.38px",
      fontSize: "16px",
      display: "flex",
      border: "none",
      gap: "10px",
      color: "#fff2e3",
      backgroundColor: "#0d0d0d",
      width: '142px',
      marginRight: '20px',
      "&:hover": {
        backgroundColor: "#0d0d0d",
      }
    },
    deleteButton: {
      textTransform: "none",
      height: "44px",
      borderRadius: "7px",
      alignItems: "center",
      justifyContent: "center",
      verticalAlign: "middle",
      fontFamily: "Inter-Medium,sans-serif",
      lineHeight: "24px",
      letterSpacing: "0.38px",
      fontSize: "16px",
      display: "flex",
      border: "none",
      gap: "10px",
      color: "#121212",
      backgroundColor: "#fff2e3",
      width: '142px',
      "&:hover": {
        backgroundColor: "#FFF2E3",
      }
    },
    actions: {
      display: 'flex',
      justifyContent: 'center',
      padding: '40px 0',
    },
    dividerStyle: {
      backgroundColor: "rgba(84, 84, 84, 1)",
      marginTop: '4px'
    },
    content: {
      padding: '40px 55px 10px',
    },
    opportunity_card_main_container: {
      borderRadius: 13,
      padding: "22px 20px 22px 20px",
      gap: 24,
      border: "1px solid #3E3E3E",
    },
    opportunity_card_title_box: {
      borderColor: '#3E3E3E',
      display: 'flex',
      flexDirection: 'column',
      fontFamily: "Inter , sans-serif",
      alignItems: "stretch",
      gap: 14,
      justifyContent: "space-between",
    },
    opportunity_card_title: {
      fontSize: "20px",
      fontFamily: "Inter,sans-serif",
      color: "#FFF2E3",
      fontWeight: 600,
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 2,
      overflow: "hidden",
      textOverflow: "ellipsis",
      lineHeight: 1.5,
      maxHeight: "3em",
    },
    opportunity_card_price_duration_box: {
      display: 'flex'
    },
    opportunity_card_price_duration: {
      padding: "6px 16px 6px 16px",
      fontSize: "14px",
      borderRadius: "24px",
      backgroundColor: "#1D1D1D",
      fontFamily: "Inter,sans-serif",
      color: "#1CBAE0",
      fontWeight: 600,
    },
    opportunity_card_duration_color: {
      padding: "6px 16px 6px 16px",
      fontSize: "14px",
      borderRadius: "24px",
      backgroundColor: "#1D1D1D",
      fontFamily: "Inter,sans-serif",
      color: "#FFB9C7",
      marginLeft: 20,
      fontWeight: 600,
    },
    opportunity_card__description: {
      fontSize: "12px",
      height: 50,
      fontFamily: "Inter,sans-serif",
      fontWeight: 400,
      color: "#FFF2E3",
      flexGrow: 1,
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 2,
      overflow: "hidden",
      textOverflow: "ellipsis",
      lineHeight: 1.5,
      maxHeight: "3em",
    },
    opportunity_card_person_detain_box: {
      display: "flex",
      justifyContent: 'space-between',
      alignItems: "baseline",
      marginTop: 10,
    },
    opportunity_card_hour: {
      color: "#66625C",
      fontSize: "11px",
      fontWeight: 400,
      fontFamily: "Inter,sans-serif",
    },
    descriptionBox: {
      marginTop: '27px'
    },
    contentDescription: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '21.84px',
      textAlign: 'center',
      fontFamily: "Inter,sans-serif",
      color: "#FFF2E3"
    },
    titleBox: {
      padding: '30px 0 20px'
    }
  });

interface DeleteProjectModalProps extends WithStyles<typeof styles> {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
  title: string
  cardTitle: string
  rsValue: string
  rsTitle: string
  month: number
  weekMonth: string
  description: string
  postedTime: string
  descriptionBottom: string
  button1Text: string
  button2Text: string
}

const DeletePostModal: React.FC<DeleteProjectModalProps> = ({ classes, open, onClose, onDelete, title, cardTitle, rsValue, rsTitle, month, weekMonth, description, postedTime, descriptionBottom, button1Text, button2Text }) => {
  return (
    <Dialog open={open} onClose={onClose}
     classes={{ paper: classes.dialog }}
     >
      <DialogTitle className={classes.titleBox}><Typography className={classes.title}>{title}</Typography></DialogTitle>
      <Divider className={classes.dividerStyle} />
      <DialogContent className={classes.content}>
        <Box className={classes.opportunity_card_main_container} >
          <Box className={classes.opportunity_card_title_box}>
            <Typography className={classes.opportunity_card_title}>{cardTitle}</Typography>
            <Box className={classes.opportunity_card_price_duration_box}>
              <Typography className={classes.opportunity_card_price_duration}>Rs. {rsValue} {" "} {rsTitle}</Typography>
              <Typography className={classes.opportunity_card_duration_color}>{month}{" "}{weekMonth}</Typography>
            </Box>
            <Typography className={classes.opportunity_card__description}>{description}</Typography>
          </Box>
          <Box>
            <Box className={classes.opportunity_card_person_detain_box}>
              <Box >
                <Typography className={classes.opportunity_card_hour}>{postedTime}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={classes.descriptionBox}>
          <Typography className={classes.contentDescription}>{descriptionBottom}</Typography>
        </Box>
      </DialogContent>

      <DialogActions className={classes.actions}>
        <Button onClick={onClose} className={classes.cancelButton}>
          {button1Text}
        </Button>
        <Button
          onClick={() => {
            onDelete();
            onClose();
          }}
          className={classes.deleteButton}
        >
          {button2Text}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(DeletePostModal);

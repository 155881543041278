import React from 'react';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Box, Divider, ListItem, Popover, TextField, styled } from '@material-ui/core';
import { BlockComponent } from 'framework/src/BlockComponent';
import SearchIcon from '@material-ui/icons/Search';
import { ExpandMore } from '@material-ui/icons';

const styles = (theme: Theme) =>
    createStyles({
        menuPaper: {
            zIndex: theme.zIndex.modal + 1,
        },
        checkboxLabel: {
            display: 'flex',
            alignItems: 'center',
        },
    });
interface Props {
    classes: {
        menuPaper: string;
        checkboxLabel: string;
    };
    fields: string[];
    selectedFields: string[];
    handleValue: (value: string[]) => void
    locationKey?:string|undefined
}

interface S {
    anchorEl: HTMLElement | null;
    selectedLocations: string[]
    searchedLocations:string[]
    search:string
}

interface SS { }

class SelectLocation extends BlockComponent<Props, S, SS> {
    constructor(props: Props) {
        super(props);
        this.state = {
            anchorEl: null,
            selectedLocations: props.selectedFields,
            searchedLocations:props.fields,
            search:""
        };
    }

    // async componentDidMount(): Promise<void> {
    //     this.setState({ searchedLocations:this.props.fields });
    // }

    MainWrapper = styled(Box)(({ theme }) => ({
        "& .select_location_apply_button": {
            color: '#FFF2E3',
            border: '1px solid #FFF2E3',
            fontSize: 12,
            paddingBottom: 1,
            paddingTop: 4,
        },
        "& .expand_more_button": {
            fontSize: 14
        },
        "& .location_container": {

        },
        "& .creative_field_apply_button": {
            padding: '10'
        },
        "& .creative_field_list_box": {

        },
        "& .creative_field_gray_color": {
            color: '#FFF2E3'
        }, "& .menu_list.MuiList-root.MuiMenu-list.MuiList-padding": {
            paddingTop: '0px !important',
            paddingBottom: '0px !important'
        }, "& .MuiDivider-root.divider": { backgroundColor: '#3E3E3E', width: '100%', borderBottomWidth: 2 }
    }))

    StyledCheckbox = styled(Checkbox)(({ theme }) => ({
        color: '#FFF2E3',
        '&.Mui-checked': {
            color: '#FFF2E3',
        },
    }));

    StyledListItem = styled(ListItem)(({ theme }) => ({
        paddingTop: 0,
        paddingBottom: 0,
    }));

    StyledDivider = styled(Divider)(({ theme }) => ({
        backgroundColor: '#3E3E3E', width: '100%', borderBottomWidth: 2
    }));

    StyledDropMenu = styled(Button)(({ theme }) => ({
        color: '#FFF2E3',
        border: '1px solid #FFF2E3',
        fontFamily: "Inter , sans-serif",
        paddingBottom: 1,
        paddingTop: 4,
        textTransform: 'none', fontSize: 14,
        "& ..MuiButton-root": {
            textTransform: 'none'
        }
    }));

    StyledTextField = styled(TextField)(({ theme }) => ({
        padding: '5px 15px',
        '& .MuiInputBase-root': {
            color: '#FFF2E3',
        },
        '& .MuiInputLabel-root': {
            color: '#FFF2E3',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&:hover fieldset': {
                borderColor: 'transparent',
            },
            '&:focus fieldset': {
                borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'transparent',
            },
        },
    }));

    StyledMenu = styled(Popover)(({ theme }) => ({
        '& .MuiPaper-root': {
            backgroundColor: '#111111',
            border: "1px solid #3E3E3E",
            scrollbarWidth:'none'
        }
    }));

    CustomTextField = styled(TextField)({
        fontSize: 14,
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&:hover fieldset': {
                borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'transparent',
            },
        },
        '& .MuiInputBase-input': {
            color: '#fff',
            fontSize: 14,
            '&:placeholder': {
                textDecoration: 'italic',
            },
        },
        '& .MuiInputAdornment-root': {
            color: '#66625C',
        }
    });


    handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ anchorEl: event.currentTarget, selectedLocations:this.props.selectedFields, searchedLocations:this.props.fields });
      };

    handleClose = () => {
        this.setState({ anchorEl: null, search:"" });
    };

    formattedLocation = (locationArray: string) => {
        return locationArray.split(',')
            .map((field: string) => {
                return this.capitalizeFirstLetter(field.trim());
            })
            .join(', ');
    };
    
    capitalizeFirstLetter = (string: string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };
    
    handleChange = (value:string) =>{
        this.setState({search:value},()=>{
            if (this.state.search.length > 0) {
                const searchedLocations = this.props.fields.filter((location) =>
                    location.toLowerCase().includes(this.state.search.toLowerCase())
                );
                this.setState({ searchedLocations });
            } else {
                this.setState({ searchedLocations: this.props.fields });
            }
        })
    }

    handleToggle = (value:string) => {
        const newSelectedLocations = this.state.selectedLocations.includes(value)
          ? this.state.selectedLocations.filter((item) => item !== value)
          : [...this.state.selectedLocations, value];
        this.setState({ selectedLocations: newSelectedLocations, search:"" } , () => {this.props.handleValue(this.state.selectedLocations)
            this.handleChange("")
        })
      };

    render() {
        const { classes } = this.props;
        const { anchorEl, searchedLocations, search } = this.state;
        const styleFindWork = this.props.locationKey === "FindWorkKey" ? {
            maxWidth: "450px",
            maxHeight: "400px"
        } : {};
  const styleBreak={
    fontFamily: "Inter , sans-serif",wordBreak:"break-word" as"break-word" ,
    whiteSpace: 'pre-wrap' as "pre-wrap"

  }
        return (
            <Box style={{ margin: 0 }}>
                <this.StyledDropMenu size="small" variant='outlined' aria-controls="creative-fields-menu" aria-haspopup="true" endIcon={<ExpandMore className='expand_more_button' />} onClick={this.handleClick}>
                    Select Location
                </this.StyledDropMenu>
                <this.StyledMenu
                    id="creative-fields-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    anchorPosition={{
                        top: 10,
                        left: 0
                    }}
                    style={styleFindWork}
                >
                    <Box>
                        <Box>
                            <this.CustomTextField
                                variant='outlined'
                                size="small"
                                fullWidth
                                onChange={(e)=>this.handleChange(e.target.value)}
                                value={search}
                                placeholder="Search Location"
                                InputProps={{
                                    startAdornment: (<SearchIcon style={{ color: "#66625C" }} />)
                                }}
                            />
                        </Box>
                        <this.StyledDivider />
                        <Box className='location_container'>
                            {searchedLocations.map((field,index) => (
                                <this.StyledListItem button>
                                    <FormControlLabel
                                        key={index}
                                        control={<this.StyledCheckbox checked={this.state.selectedLocations.includes(field)} onChange={() => this.handleToggle(field)} />}
                                        label={<Box style={styleBreak}>{this.formattedLocation(field)}</Box>}
                                        style={{ color: '#FFF2E3', marginTop: 3 }}
                                        className={classes.checkboxLabel}
                                    />
                                </this.StyledListItem>
                            ))}
                        </Box>
                    </Box>
                </this.StyledMenu>
            </Box>
        );
    }
}

export default withStyles(styles)(SelectLocation);

export const userProfile = require("../../assets/user-profile.png");
export const chatIcon = require("../../assets/chat.png");
export const activeChatIcon = require("../../assets/activeChat.png");
export const userIcon = require("../../assets/user.png");
export const activeUserIcon = require("../../assets/activeUser.png");
export const notificationIcon = require("../../assets/notification.png");
export const activeNotificationIcon = require("../../assets/activeNotification.png");
export const logoIcon = require("../../assets/headerLogo.png");
export const searchIcon = require("../../assets/search.png");
export const postOpportunity = require("../../assets/postOpportunity.png");
export const postOpportunityActive = require("../../assets/postOpportunityActive.png");
export const shareProject = require("../../assets/defaultBtn.png");
export const shareProjectActive = require("../../assets/selectedBtn.png");

import React from 'react';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Box, Divider, ListItem, MenuList, Paper, Popover, styled } from '@material-ui/core';
import { BlockComponent } from 'framework/src/BlockComponent';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

const styles = (theme: Theme) =>
    createStyles({
        menuPaper: {
            zIndex: theme.zIndex.modal + 1,
            marginTop: 0,
            paddingTop: 0,
            paddingBottom: 0
        },
        checkboxLabel: {
            display: 'flex',
            alignItems: 'center',
        },
    });

export interface CreativeField {
    id: number;
    name: string;
    created_at: Date;
    updated_at: Date;
}

interface Props {
    classes: {
        menuPaper: string;
        checkboxLabel: string;
    };
    fields: CreativeField[];
    selectedFields: number[];
    onApply: (value: number[]) => void;
}

interface S {
    anchorEl: HTMLElement | null;
    selectedIds: number[];
    isApplyButton:boolean;
}

interface SS { }

class CreativeFields extends BlockComponent<Props, S, SS> {
    constructor(props: Props) {
        super(props);
        this.state = {
            anchorEl: null,
            isApplyButton: true,
            selectedIds: []
        };
    }

    MainWrapper = styled(Box)(({ theme }) => ({
        "& .creative_field_button": {
            color: '#FFF2E3',
            border: '1px solid #FFF2E3',
            fontFamily: 'Inter, sans-serif !important',
            fontSize: 14,
            paddingBottom: 1,
            paddingTop: 4,
            textTransform: 'none'
        },
        "& .disabled-btn":{
            backgroundColor:'rgb(157, 155, 153)',
            color:"black"
          },
        "& .creative_field_and_appy_button_container":{
            
        },

        "& .fields_container":{
            
        },
        "& .complete_label":{
            color: '#FFF2E3', marginTop: 3
        },
        "& .icon_size":{
            fontSize: 14
        },
        "& .creative_field_apply_button": {

        },
        "& .creative_field_list_box": {

        },
        "& .apply_disabled_btn": {
            backgroundColor: 'gray !important'
        },
        "& .apply_button": {
            color: "black", backgroundColor: '#FFF2E3'
        },
        "& .creative_field_gray_color": {
            color: '#FFF2E3'
        }, "& .menu_list.MuiList-root.MuiMenu-list.MuiList-padding": {
            paddingTop: '0px !important',
            paddingBottom: '0px !important'
        }
    }))

    StyledCheckbox = styled(Checkbox)(({ theme }) => ({
        color: '#FFF2E3',
        '&.Mui-checked': {
            color: '#FFF2E3',
        },
    }));

    StyledListItem = styled(ListItem)(({ theme }) => ({
        padding: "3 0",
        display:'flex',
        alignItems: "first baseline",
        "& .label_box":{
            fontFamily: 'Inter, sans-serif !important',
            marginTop: 12,
            wordBreak: 'break-word',
            overflowWrap: 'break-word',
            width: '165px'
        }
    })); 

    StyledCloseDropMenu = styled(Button)(({ theme }) => ({
        color: '#FFF2E3',
        fontSize: 16,
        fontWeight:600,
        fontFamily: "Inter, sans-serif",
        paddingBottom: 1,
        paddingTop: 4,
        textTransform: 'none'
    }));

    StyledDivider = styled(Divider)(({ theme }) => ({
        backgroundColor: '#3E3E3E', width: '100%', borderBottomWidth: 2
    }));

    StyledMenu = styled(Popover)(({ theme }) => ({
        '& .MuiPaper-root': {
            backgroundColor: '#111111',
            color: '#FFF2E3',
            border: "1px solid #3E3E3E",
            width:464,
            borderRadius:15,
        },
    }));

    StyledApplyButton = styled(Button)(({ theme }) => ({
        fontFamily: "Inter, sans-serif",
        fontSize: 16,
        textTransform: 'none',
        padding:'6px 8px',
        '&.Mui-disabled': {
            backgroundColor:'rgb(157, 155, 153)',
            color:"black"
        },
        '&:not(.Mui-disabled)': {
            color: 'black',
            backgroundColor: '#FFF2E3',
        },
    }));

    handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ anchorEl: event.currentTarget, selectedIds:this.props.selectedFields });
    };

    handleClose = () => {
        this.setState({ anchorEl: null, isApplyButton:true, selectedIds:this.props.selectedFields });
    };

    handleToggle = (id: number) => {
        const { selectedIds } = this.state
        let findValue = selectedIds.includes(id)

        if (!findValue) {
            this.setState({ selectedIds: [...selectedIds, id] })
        } else {
            let removedValue = selectedIds.filter((ele) => ele !== id)
            this.setState({ selectedIds: removedValue })
        }

        this.setState({isApplyButton:false})
    };

    handleApply = () => {
        this.props.onApply(this.state.selectedIds)
        this.handleClose()
    }

    render() {
        const { classes, fields } = this.props;
        const { anchorEl } = this.state;

        return (
            <this.MainWrapper>
                <Box>
                    <Button id='basic-button' endIcon={<ExpandMore  className='icon_size' />} aria-controls={Boolean(anchorEl) ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={Boolean(anchorEl) ? 'true' : undefined} className='creative_field_button' size="small" variant='outlined' onClick={this.handleClick}>
                        Creative fields
                    </Button>
                    <this.StyledMenu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={this.handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        anchorPosition={{
                            top: 10,
                            left: 0
                        }}
                    >

                        <Box>
                            <Box sx={{display: 'flex', borderBottom:'1px solid #3E3E3E', justifyContent: 'space-between', padding: '12px 21px 12px 31px', fontSize: 14}}>
                                <this.StyledCloseDropMenu  variant='text' endIcon={<ExpandLess />} onClick={this.handleClose}>
                                    Creative Fields
                                </this.StyledCloseDropMenu>

                                <this.StyledApplyButton
                                    onClick={this.handleApply}
                                    disabled={this.props.selectedFields === this.state.selectedIds}
                                    variant="contained"
                                >
                                    Apply
                                </this.StyledApplyButton>
                            </Box>
                            <this.StyledDivider />
                            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                <Box style={{padding:15}}>
                                    {fields.map((field, index) => (
                                        (index + 1) % 2 !== 0 &&
                                        <this.StyledListItem button>
                                            <Box key={field.id}>
                                            <this.StyledCheckbox checked={this.state.selectedIds.includes(field.id)} onClick={() => this.handleToggle(field.id)} />
                                            </Box>
                                            <Box className='label_box'>{field.name}</Box>
                                        </this.StyledListItem>
                                    ))}
                                </Box>
                                {this.props.fields.length > 1 &&
                                <>
                                    <Box style={{borderLeft:'1px solid #3E3E3E',padding:15}}>
                                        {fields.map((field, index) => (
                                            (index + 1) % 2 === 0 &&
                                            <this.StyledListItem button>
                                                <Box key={field.id}>
                                            <this.StyledCheckbox checked={this.state.selectedIds.includes(field.id)} onClick={() => this.handleToggle(field.id)}/>
                                            </Box>
                                            <Box className='label_box'>{field.name}</Box>
                                            </this.StyledListItem>
                                        ))}
                                    </Box>
                                    </>
                                }
                            </Box>
                        </Box>
                    </this.StyledMenu>
                </Box>
            </this.MainWrapper>
        );
    }
}

export default withStyles(styles)(CreativeFields);
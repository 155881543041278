import React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  IconButton,
  Slider,
  Modal,
  Button
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles, styled } from "@material-ui/core/styles";
import { Rating } from "@material-ui/lab";
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';

import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import MultiSelect from "../../../components/src/MultiSelect.web";
import SingleSelect from "../../../components/src/SingleSelect.web";
import ProfileCard from "../../../components/src/ProfileCard.web"

import { 
  leftIcon, 
  searchText, 
  searchIcon, 
  unCheckIcon, 
  dropIcon, 
  checkIcon,
  musicIcon, 
  noResultsFound
} from "./assets";
const typoWhite = "#FFF2E3"

const theme = createTheme({
  palette: {
    primary: {
      main: typoWhite,
      contrastText: "#8C8C8C",
    },
  },
  typography: {
    fontFamily: "Inter, sans-serif",
    h1: {
      color: typoWhite,
      fontSize: "2.25rem",
      fontWeight: 700,
      lineHeight: "24px",
      letterSpacing: "0.38px",
      textAlign: "left",
      fontFamily: "Inter-SemiBold, sans-serif",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    h2: {
      color: typoWhite,
      fontSize: "1.5rem",
      fontWeight: 700,
      lineHeight: "29.05px",
      textAlign: "left",
      fontFamily: "Inter-SemiBold, sans-serif",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    h4: {
      color: typoWhite,
      fontSize: "1.25rem",
      fontWeight: 700,
      lineHeight: "24.2px",
      textAlign: "left",
      fontFamily: "Inter-SemiBold, sans-serif",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    body1: {
      color: typoWhite,
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: "19.36px",
      textAlign: "left",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    body2: {
      color: typoWhite,
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: "16.94px",
      textAlign: "left",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    caption: {
      color: typoWhite,
      fontSize: "0.75rem",
      fontWeight: 400,
      lineHeight: "14.52px",
      textAlign: "left",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    button: {
      color: `${typoWhite}`,
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: "19.36px",
      textTransform: "none",
      letterSpacing: "0.38px",
    }
  },
});

const CustomModal = styled(Modal)({
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  height:'100vh',
  width:'100vw',
  "& .close_button": {
    display:'flex',justifyContent:'flex-end'
  }, "& .close_button_icon": {
    color:'white', right: -1,
                  position: "absolute",
                  marginBottom: 10,
                  fontSize: "18px"
  },"& .backgroundColor":{
    backgroundColor:"#222222"
  }
})
// Customizable Area End

import AdvancedSearchController, { Props , configJSON } from "./AdvancedSearchController";

export default class AdvancedSearch extends AdvancedSearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

// Customizable Area Start
  renderRatingFilter = () => {
    const { classes } = this.props;
    const { ratingObject } = this.state;
    return (
      <div ref={this.onClickAwayRef} className={`${classes.paperItemCon} ${classes.menuInnerCon}`}>
        <Box className="newResponsive bottomBorder">
          <Typography data-testid="ratingHeading" variant="body2" className="subHeading">{configJSON.ratingText}&#58;</Typography>
        </Box>
        <Box className="newResponsiveTwo">
        {configJSON.ratingsData.map((rateItem: { count: number, keyValue: string }) => {
          return (
            <Box className={classes.ratingCountCon} key={rateItem.count}>
              <IconButton 
                data-testid={`rating${rateItem.keyValue}`}
                onClick={()=>{this.handleRatingFilter(rateItem.count)}}>
                <img src={this.isRatingSelected(rateItem.count) ? checkIcon :unCheckIcon} />
              </IconButton>
              <Rating
                defaultValue={rateItem.count}
                readOnly
                icon={<StarIcon style={styles.starIcon} />}
                emptyIcon={<StarBorderIcon style={styles.emptyStarIcon} fontSize="inherit" />}
              />
              <Typography className="count">{ratingObject[rateItem.keyValue] || configJSON.zeroCount }</Typography>
            </Box>
          )
        })}
        </Box>
      </div>
    )
  }

  renderRateComponent = () => {
    const { classes } = this.props;
    return (
      <div ref={this.onClickAwayRef} className={`${classes.paperItemCon} ${classes.menuInnerCon}`}>
        <Box className="newResponsive bottomBorder">
          <Typography data-testid="rateHeading" variant="body2" className="subHeading">{configJSON.rateText}&#58;</Typography>
        </Box>
        <Box className="newResponsive">

        <Box className={classes.sliderCon}>
          <Box className="musicImageSliderCon">

            <img src={musicIcon} className="musicImage" />
            <Slider
              value={[this.state.rateMin, this.state.rateMax]}
              onChange={this.handleRangeSliderChange}
              min={100}
              max={5000}
              valueLabelDisplay="auto"
              className="slider"
              data-testid="slider"
            />
          </Box>
          <Box className="toMinMaxCon">
            <Box className="minMaxCon">
              <Typography variant="caption">{configJSON.minPerHour}</Typography>
              <Typography variant="body1">{this.state.rateMin}</Typography>
            </Box>
            <Typography>{configJSON.toText}</Typography>
            <Box className="minMaxCon">
              <Typography variant="caption">{configJSON.maxPerHour}</Typography>
              <Typography variant="body1">{this.state.rateMax}</Typography>
            </Box>
          </Box>
        </Box>
        </Box>
      </div>
    )
  }

  renderCircularProgressBar = () => {
    const { classes } = this.props;
    return (
      <Box className={classes.progressCon}>
        <CircularProgress className="circular" />
      </Box>
    )
  }

  renderBackDrop = () => {
    return (
      <Box
        className={this.props.classes.backdrop} >
        {this.renderCircularProgressBar()}
      </Box>
    )
  }

  renderProfileCards = () => {
     const {classes} = this.props;
    if ((this.state.desingerProfileList.length === 0 && (!this.state.initialLoading)) || (this.state.activeTab !== "designer")) {
      return (
        <Box className={classes.emptyResultsCon}>
          <img src={noResultsFound} />
          <Typography variant="h4" className="emptyResultHeading">{configJSON.noResultsFoundHeading}</Typography>
          <Typography data-testid="errorMessageText">
            {`${this.state.search.trim().length > 0 ? "`"+this.state.search+"` "+configJSON.searchNoResultFoundDes : "" } ${configJSON.noResultsFoundDescription}`}
          </Typography>
        </Box>
      )
    }

    const cards = this.state.desingerProfileList.map(
      (designer, idIndex) => {
        return (
          <div ref={
            this.state.desingerProfileList.length === (idIndex + 1) ? 
            this.designerCardRef :
            null
          }
           >
            <ProfileCard
              completedSkills={[]}
              handleNavigation={this.handleNavigations}
              data={designer.attributes}
              handleOpen={this.handleOpen}
              isOpen={this.state.isOpen}
              index={idIndex}
              data-testid="profileCard"
              outerClassName = "profileCard"
            />
           </div>
        )
      }
    )
    return (<Box className={this.props.classes.gridCon}>
      {cards}
    </Box>)
  }
// Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    return (
      <Box style={styles.newResponseCon}>
        <NavigationMenu {...this.props} />
        <ThemeProvider theme={theme}>
         <Box className={classes.container}>
            <img src={searchText} className="searchImage" alt="search client" />
            <Box className={classes.responsiveCon}>
              <Box className={classes.backButtonCon}>
                <IconButton data-testid="backButton" onClick={this.handleNavigateToLandingPage}>
                  <img src={leftIcon} />
                </IconButton>
                <Typography variant="h1" data-testid="searchHeading">{configJSON.searchResultText}</Typography>
              </Box>
              <Box className={classes.searchTabsCon}>
                <Box className={classes.searchCon}>
                  <img src={searchIcon} className="searchIcon" alt="search icon" />
                  <input
                    data-testid="searchBar"
                    className="searchBar"
                    onChange={this.handleInputChange}
                    onKeyDown={this.handleKeyDown}
                    value={this.state.search}
                  />
                  {this.state.search.length > 0 && <IconButton data-testid="closeButton" onClick={this.handleCloseButton}>
                    <CloseIcon className="closeIcon" />
                  </IconButton>}
                  <Box className="searchTextCon">
                    <Typography 
                      className="searchText"  
                      data-testid="searchText"
                      onClick={this.handleSearchButtonClick}>
                        {configJSON.searchText}
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.tabsCon}>
                  {this.state.tabsList.map(tabItem => (
                    <Box className={this.getActiveTabBorder(tabItem.tabId)} key={tabItem.tabId}>
                      <Box 
                        className={this.getActiveTabBackground(tabItem.tabId)} 
                        data-testid={"tabItem"+tabItem.tabName}
                        onClick={() => this.handleTabChange(tabItem.tabId)}>
                        <Typography className={this.getActiveTabFont(tabItem.tabId)}>{tabItem.tabName}</Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
              <Box className={classes.filterListCon}>
                <Box className="multiCon">
                  <MultiSelect
                    toggleState={this.toggleMultiCreativeFeild}
                    singleColumn={false}
                    open={this.state.openMulitCreateFeild}
                    title={configJSON.creativeFeildHeading}
                    data={this.state.allCategory}
                    selected={this.state.selectedCreativeFeilds}
                    onChange={this.handleChangeCreativeFeilds}
                    data-testid="creativeField"
                  />
                </Box>
               {
               this.state.userRole !== "designer" && 
               <MultiSelect
                  data-testid="skillsFilter"
                  toggleState={this.toggleMultiSkills}
                  singleColumn={false}
                  open={this.state.openMulitSkills}
                  title={configJSON.skillsHeading}
                  data={this.state.allSkillsList}
                  selected={this.state.selectedSkills}
                  onChange={this.handleChangeSkills} />
               }
                <Box 
                  data-testid="rateFilter"
                  className={classes.ratingDropDownCon} 
                  onClick={this.handleRateMenuOpen}>
                  <Typography variant="body2">{configJSON.rateText}</Typography>
                  <IconButton>
                    <img src={dropIcon} />
                  </IconButton>
                  {this.state.rateOpen && 
                    this.renderRateComponent()}
                </Box>
                <Box 
                  className={classes.ratingDropDownCon} 
                  data-testid="ratingFilter"
                  onClick={this.handleRatingMenuOpen}>
                  <Typography variant="body2">{configJSON.ratingText}</Typography>
                  <IconButton>
                    <img src={dropIcon} />
                  </IconButton>
                  {this.state.ratingOpen && this.renderRatingFilter()}
                </Box>            
                <SingleSelect 
                  data-testid="singleSelect"
                  toggleState={this.toggleSingle} 
                  open={this.state.openSingle} 
                  title={configJSON.sortTitle} 
                  data={
                    this.state.userRole === "client" ? 
                    configJSON.clientSortData : 
                    configJSON.designerSortData 
                  } 
                  selected={this.state.selectedElm} 
                  onChange={this.handleSelectChange} />
                <Button 
                  onClick={this.handleResetClick} 
                  disabled={this.handleDisableResetButton()} 
                  className={`apply_button ${this.handleDisableResetButton() ? "disabled-btn" : ""}`} 
                  size="small">
                  {configJSON.resetButton}
                </Button>
              </Box>
              {this.renderProfileCards()}
              <CustomModal
                open={this.state.isOpen}
                className="modal"
                data-testid="modal"
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
              >
                <Box className="modal_main_container">
                  <Box>
                    <Box className="close_button">
                      <IconButton onClick={this.handleClose} data-testid="profile_modal_cross">
                        <CloseIcon className="close_button_icon" />
                      </IconButton>
                    </Box>
                    {
                      this.state.openedData?.skills.length ?
                        <ProfileCard
                          handleNavigation={this.handleNavigations}
                          completedSkills={this.state.openedData.skills}
                          data={this.state.openedData}
                          handleOpen={this.handleOpen}
                          isOpen={this.state.isOpen}
                          index={0} /> :
                        null
                    }

                  </Box>
                </Box>
              </CustomModal>
              {this.state.initialLoading && this.renderBackDrop() }
            </Box>
         </Box>
        </ThemeProvider>
      </Box>

    );
    // Customizable Area End
  }
}

// Customizable Area Start

const styles = {
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: "transparent",
    height: "200px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  progressCon: {
    width: "100%",
    height: "3rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .circular": {
      color: "#fff"
    }
  },
  newResponseCon: {
    minHeight: "100vh",
    width: "100%",
    backgroundColor: "#111111",
  },
  container: {
    width: '100%',
    paddingTop: "76px",
    backgroundColor: "#111111",
    "& .apply_button": {
      backgroundColor: "#FFF2E3", 
      fontFamily: "Inter , sans-serif",
      textTransform: 'none',
      marginRight: 10,
      height: "28px"
    },
    "& .disabled-btn": {
      backgroundColor: 'rgb(157, 155, 153)',
      color: "black"
    }, 
    "& .searchImage": {
      width: "100%",
      height: "307px",
      objectFit: "cover",
    },
    "& .profileCard": {
      width: "100% !important",
      height: "100%"
    },
    "& .select-btn": {
      width: "fit-content !important",
      paddingTop: "1px",
      paddingBottom: "1px",
      paddingLeft: "16px",
      paddingRight: "15px",
    },
    "& *": {
      boxSizing: "border-box",
    }
  },
  responsiveCon: {
    width: "min(100%,1920px)",
    margin: "0 auto",
    padding: "1rem 3rem",
  },
  backButtonCon: {
    display: "flex",
    alignItems: "center", 
    marginBottom: "2.5rem",
    marginTop: "1rem", 
  },
  searchCon: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#252525",
    borderRadius: "24px",
    padding: "0 24px",
    height: "48px",
    "& .searchIcon": {
      width: "16px",
      height: "16px",
      objectFit: "contain",
      flexShrink: 0,
      marginRight: ".5rem",
    },
    "& .searchBar": {
      outline: "none",
      border: "none",
      fontFamily: "Inter",
      flexGrow: 1,
      height: "100%",
      backgroundColor: "transparent",
      color: typoWhite,
      "&::placeholder": {
        color: "#676767",
        fontFamily: "Inter",
      },
    },
    "& .searchTextCon": {
      borderLeft: "1px solid #434343",
      paddingLeft: "2rem",
      paddingRight: "1rem",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "& .searchText": {
      textDecoration: "underline",
      fontFamily: "Inter-SemiBold",
      cursor: "pointer",
    }
  },
  searchTabsCon: {
    display: "grid",
    gridTemplateColumns: "3fr 2fr",
    alignItems: "center",
    gap: "2rem",
    "& .closeIcon": {
      color: typoWhite,
      fontSize: "1rem",
    }
  },
  tabsCon: {
    backgroundColor: "#0D0D0D",
    padding: "8px 16px",
    borderRadius: "50px",
    display: "flex",
    alignItems: "center",
    gap: "1.5rem",
    "& .tabOuterCon": {
      border: '1px solid transparent',
      borderRadius: "2rem",
      height: "3.75rem",
      width: "fit-content",
      cursor: "pointer",
    },
    "& .gradientCon": {
        backgroundImage: 'linear-gradient(90.83deg, #3FA7E1 -0.01%, #7699EF 31.13%, #AE9DF2 51.64%, #E8A5CA 72.11%, #F1B2C6 99.99%)',    
    },
    "& .activeTab": {
      backgroundColor: "#313131",
    },
    "& .tab": {
      width: "100%",
      height: "100%",
      borderRadius: "inherit",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "11px 22px"
    },
    "& .activeFont": {
      fontFamily: "Inter-SemiBold"
    },
    "& .inActiveFont": {
      color: "#8C8C8C"
    }
  },
  filterListCon: {
    display: "flex",
    gap: "1rem",
    alignItems: "flex-start",
    marginTop: "3rem",
    position: "relative" as "relative",
    "& .multiCon": {
      width: "145px",
    },
    "& *": {
      boxSizing: "content-box",
    }
  },
  menuInnerCon: {
    position: "absolute" as "absolute",
    top: -2,
    left: -1,
    zIndex: 100,
    "& .subHeading": {
      fontFamily: "Inter-SemiBold",
      color: "#ffffff"
    },
    "& .newResponsive": {
      padding: "1.25rem 2rem 1.25rem 2rem",
    },
    "& .newResponsiveTwo": {
      padding: "1.25rem 2rem 1.25rem calc(2rem - 12px)",
    },
    "& .bottomBorder": {
      borderBottom: "1px solid #5F5F5F",
    },
    "& .count": {
      marginLeft: "1.5rem",
      color: "#ffffff"
    }
  },
  ratingCountCon: {
    display: "flex",
    alignItems: "center",
  },
  starIcon: {
    color: "#BFC06F", 
    marginRight: ".4rem"
  },
  emptyStarIcon: {
    color: "#535353",
    marginRight: ".4rem"
  },
  ratingDropDownCon: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    border: `1px solid #66625c`,
    width: "fit-content",
    padding: ".2rem 0.5rem .2rem 1.5rem",
    borderRadius: "6px",
    height: 40,
    boxSizing: "border-box" as "border-box",
    position: "relative" as "relative",
  },
  paperItemCon: {
    backgroundColor: "#111111",
    border: "1px solid #5F5F5F",
    borderRadius: "10px",
    padding: 0,
  },
  menuMenuItemCon: {
    padding: 0,
  },
  sliderCon: {
    "& *": {
      boxSizing: "border-box",
    },
    "& .toMinMaxCon": {
      display: "flex",
      gap: "1rem",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: "2.5rem",
    },
    "& .minMaxCon": {
      backgroundColor: "#222222",
      padding: ".8rem",
      borderRadius: "10px",
      display: "flex",
      flexDirection: "column",
      gap: ".5rem",
      width: "fit-content",
      "& span": {
        color: "#797979"
      }
    },
    "& .musicImageSliderCon": {
      width: "329px",
      height: "62px",
      position: "relative",
      "& .musicImage": {
        width: "100%",
        height: "100%",
        objectFit: "fill",
       
      },
      "& .slider": {
        position: "absolute",
        bottom: -6,
        left: 0,
      }
    },
    
    '& .MuiSlider-track': {
      border: 'none',
      height: 8,
      background: 'linear-gradient(224.84deg, #FFC29F 0%, #FFB9C7 16.15%, #CF8CCC 33.85%, #AB9EF5 50.52%, #6C98EE 67.71%, #1CBAE0 83.85%, #A8E5EC 100%)'
    },
    "& .MuiSlider-root": {
      width: "97%",
    },
    '& .MuiSlider-thumb': {
      height: 20,
      width: 20,
      backgroundColor: '#fff',
      border: '2px solid #171717',
      marginTop: -6.5,
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
    },
    '& .MuiSlider-rail': {
      height: 8,
      opacity: 1,
      backgroundColor: '#bfbfbf',
      borderRadius: "10px",
    },
    '& .MuiSlider-valueLabel': {
      display: 'none',
    },
  },
  gridCon: {
    width: "100%",
    marginTop: "2rem",
    display: "grid",
    gridTemplateColumns: "repeat(3,1fr)",
    gap: "2.5rem",
    [theme.breakpoints.up(1635)]: {
      gridTemplateColumns: "repeat(4,1fr)",
    }
  },
  emptyResultsCon: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "1rem",
    justifyContent: "center",
    alignItems: "center",
    padding: "5rem",
    "& .emptyResultHeading": {
      marginBottom: "2rem",
    }
  }
}
const NewAdvancedSearch = withStyles(styles)(AdvancedSearch);
export { NewAdvancedSearch }
// Customizable Area End

// Customizable Area Start
import React from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { BackgroundImage, logoImage } from "./assets";
import ForgotPasswordController, { Props } from "./ForgotPasswordController";
import ToastMSG from "../../../components/src/ToastMSG.web";
import Divider from '@material-ui/core/Divider';
import { Formik, Form } from 'formik';
export const configJSON = require("./config");
import {
  Box,
  Button,
  withStyles,
  TextField,
  Grid,
  InputLabel
} from "@material-ui/core";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

const CustomTextField = withStyles({
  root: {
      '& .MuiOutlinedInput-root': {
          '& fieldset': {
              borderColor: '#252525',
              borderRadius: "8px"
          },
          '&:hover fieldset': {
              borderColor: '#252525',
              borderRadius: "8px"
          },
          '&.Mui-focused fieldset': {
              borderColor: '#252525',
              borderRadius: "8px"
          },
      },
      '& p.MuiFormHelperText-root': {
          fontSize: "14px",
          lineHeight: "16.94px",
          color: "#FF7878",
          fontFamily: "Inter, sans-serif"
      }
  },
})(TextField);

const custombox = {
  mainContainer: {
      backgroundImage: `url(${BackgroundImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: "173px, 0, 137px, 0",
  },
  root: {
    opacity: "0.92",
    background: '#111111',
    height: "auto",
    borderRadius: "24.11px",
    overflow: "unset",
    width: "639px",
    marginTop: "30px",
    marginBottom: "30px"
},
  inputLabel: {
    color: '#6C6C6C',
    fontFamily:"Inter, sans-serif",
    lineHeight: "16.94px",
    marginBottom: "12.65px",
    fontSize: "14px"
},
}

export default class NewPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks

      <ThemeProvider theme={theme}>
         <Box sx={custombox.mainContainer} >
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          data-test-id="formikform"
          onSubmit={()=>{}}
        >
          {({ values, errors, handleSubmit, handleChange }) => (
          <Form data-test-id="formikformData" onSubmit={handleSubmit} noValidate autoComplete="off" style={{ margin: "50px" }}>
          <Box style={custombox.root}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "32.64px 1.16px 0 0",
              borderRadius:"24.48px",
            }}
          >
            <Grid item xs={6} style={{ display: "flex", justifyContent: "center", width:"248.84px", height:"64.58px", marginTop:"41px", flexBasis:"13%"}}>
              <img src={logoImage} style={{width:"248.84px", height:"64.58px"}} />
            </Grid>
 
            <Box sx={{ width: "439px", marginTop: "40.57px"}}>
            <Grid item xs={12} style={{ display: "flex", flexDirection: "column", marginTop: "21.31px" }}>
              <InputLabel style={custombox.inputLabel}>{configJSON.newPassword}</InputLabel>
                <CustomTextField
                  fullWidth
                  type="password"
                  name="newPassword"
                  variant='outlined'
                  required
                  data-test-id="newPasswordInput"
                  onChange={(event) =>
                    this.handleNewPassword(event.target.value)
                  }
                  inputProps={{ style: { fontFamily: 'Inter-SemiBold, sans-serif', fontSize: "14px", color: '#fff2e3' } }}
                />
                    {this.state.isChangePasswordClicked &&
                  !this.state.isNewPasswordValid && (
                    <p
                      className="error-text"
                      data-test-id="newPasswordCriteria"
                      style={{ color: '#FF7878', fontFamily: 'Inter, sans-serif', marginTop: "10px"}}
                    >
                      {configJSON.errorNewPassword}
                    </p>
                  )}
                {this.state.isChangePasswordClicked &&
                  this.state.samePasswordErrorMSG && (
                    <p
                      className="error-text"
                      data-test-id="newPasswordCriteria"
                      style={{ color: '#FF7878', fontFamily: 'Inter, sans-serif', marginTop: "10px"}}
                    >
                      {this.state.samePasswordErrorMSG}
                    </p>
                  )}
              </Grid>
            <Grid item xs={12} style={{ display: "flex", flexDirection: "column", marginTop: "21.31px" }}>
              <InputLabel style={custombox.inputLabel}>{configJSON.confirmPassword}</InputLabel>
                <CustomTextField
                  fullWidth
                  type="password"
                  name="confirmPassword"
                  variant='outlined'
                  required
                  data-test-id="confirmPasswordInput"
                  onChange={(event) =>
                    this.handleConfirmNewPassword(event.target.value)
                  }
                  inputProps={{ style: { fontFamily: 'Inter-SemiBold, sans-serif', fontSize: "14px", color: '#fff2e3' } }}
                />
                 {this.state.isChangePasswordClicked &&
                  this.state.newPasswordValue !=
                    this.state.newConfirmPasswordValue && (
                    <p className="error-text"
                    style={{ color: '#FF7878', fontFamily: 'Inter, sans-serif', marginTop: "10px"}}>{configJSON.errorConfirmPassword}</p>
                  )}
              </Grid>
            </Box>
          </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  margin: "25px 0px",
                }}
              >
                <Button
                  variant="contained"
                  data-test-id="confirmBtn"
                  onClick={() => this.handleChangePassword()}
                  type="submit"
                  style={{ backgroundColor: '#FFF2E3', width: "200px", height: "44px"}}
                >
                  <span style={{ textTransform: "none", fontSize:"16px", letterSpacing: "0.38px", lineHeight: "24px", color: "#121212", fontFamily:"Inter-SemiBold, sans-serif" }}>
                  {configJSON.changePassword} {/*UI Engine::From Sketch*/}
                  </span>
                </Button>
              </Box>
              <Grid item xs={12} style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                <Divider style={{ border: '1px solid #fff2e342', height: '0px', width: "100%", margin: '0px', marginTop:"41px"}} />
                <Box style={{ color: '#FFF2E3', margin: '19.73px 0 10.47px', height: "41.8px", textAlign: "center", lineHeight: "16.94px", fontSize: "14px", fontFamily:"Inter, sans-serif" }}>
                {configJSON.noAccount} <span data-test-id={"btnSignUp"} style={{ textDecoration:"underline", textUnderlineOffset: "3px", fontFamily:"Inter-SemiBold, sans-serif", cursor:"pointer" }} 
                  onClick={() =>
                    this.redirectToSignUP("EmailAccountRegistration")
                  }
                  >{configJSON.signUp}</span>
                </Box>
              </Grid>
            </Box>
          </Form>
          )}
          </Formik>
          </Box>
        <ToastMSG close={() => this.handleCloseToast()} open={this.state.open} message={this.state.message} action={this.state.action} />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area End

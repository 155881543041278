
import { Box, styled,Typography,Grid,Chip,Divider} from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import { BlockComponent } from 'framework/src/BlockComponent'
import React from 'react'
const mainlogo=require('./Treesss.png')
const config = require("../../framework/src/config.js");
const ProfilePic = require("./ic_userlogo.png");

export interface Props {
    data:any
}
const webStyle={
  
  ProjectCtn:{
    fontSize:"12px",
    fontWeight:400,
    fontFamily:'Inter, sans-serif',
    
  },
  RandomNme:{
    fontSize:"14px",
    fontWeight:700,
    fontFamily:'Inter, sans-serif',
  },
  cardbgd:{
    
    backgroundColor:"rgba(0, 0, 0, 0.7)",
    padding:"10px auto auto 15px ",
    borderBottomRightRadius:"7px",
    borderBottomLeftRadius:"7px"
  } as React.CSSProperties,
  userNme:{
    fontSize:"12px",
    fontWeight:400,
    fontFamily:'Inter, sans-serif',
  },
  UsernameCont :{
    display:"flex",
    paddingTop:'15px',
    paddingLeft:'15px',
    alignItems:"center",
    fontWeight:400,
    fontSize:"12px",

  }
}
interface Data {
    'attributes' :{
        account_id: number;
        background_color: string;
        collection_name: string;
        cover_image_url:string;
        description:string;
        end_date: null
        is_liked: false;
        is_saved: false;
        likes: number;
         profile_photo: string;
        project_images: any [];
       project_name: string;
        start_date: null;
        username:string;
        
      }
    
}

interface S {

baseUrl:string;
skillList:boolean
}

interface SS {
}

export default class MultipleCardlap extends BlockComponent<Props, S, SS> {

  constructor(props: Props) {
    super(props)

    this.state = {
      baseUrl:config.baseURL,
      skillList : false,

    }

  }

  
  MainWrapper = styled(Box)(({ theme }) => ({
    "& .card_container":{
        width:"100%", height:'100%'  
     },
     '& .container' :{
      position: 'relative',
      maxWidth: '270px',
      maxHeight: '250px',
      marginTop:'10px',
      minHeight: '200px'
    },
    '& .div1' :{
      position: 'absolute',
      width: 'calc(100% - 10px)',
      height: 'calc(100% - 10px)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '20px',
      color: 'white'
    },
    '& .image-div':{
      position: 'absolute',
      cursor:'pointer',
      left:'10px',
      height: 'calc(100%)',
      width: 'calc(100% - 10px)',
      zIndex:4
    },
    '& image-div img':{
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius:"7px",
      cursor:'pointer'
      
    },
    '& .div01' :{
      backgroundColor: 'rgba(90, 90,90)',
    top:'20px',
    left:'20px',
    zIndex:3,
    borderRadius:"7px",
    boxShadow: '9.92px 9.92px 21.5px 0px rgba(0, 0, 0, 0.25)'
    },
    '& .div02' :{
    backgroundColor: 'rgba(90, 90,90)',
    top:'30px',
    left:'30px',
    zIndex:2,
    borderRadius:"7px",
    boxShadow: '9.92px 9.92px 21.5px 0px rgba(0, 0, 0, 0.25)'
    },
    '& .div03' :{
      backgroundColor: 'rgba(90, 90,90)',
      top:'40px',
      left:'40px',
      zIndex:1,
      borderRadius:"7px",
      boxShadow: '9.92px 9.92px 21.5px 0px rgba(0, 0, 0, 0.25)'
    },
    '& .ProjectCtn':{
      fontSize:"12px",
      fontWeight:400,
      fontFamily:'Inter, sans-serif',
      
    },
    '&RandomNme':{
      fontSize:"14px",
      fontWeight:700,
      fontFamily:'Inter, sans-serif',
    },
    '& .cardbgd':{
      
      backgroundColor:"rgba(0, 0, 0, 0.7)",
      padding:"10px auto auto 15px ",
      borderBottomRightRadius:"7px",
      borderBottomLeftRadius:"7px"
    } ,
    '& .userNme':{
      fontSize:"12px",
      fontWeight:400,
      fontFamily:'Inter, sans-serif',
    },
    '& .UsernameCont' :{
      display:"flex",
      paddingTop:'15px',
      paddingLeft:'15px',
      alignItems:"center",
      fontWeight:400,
      fontSize:"12px",
    },  
    '& .MainContaner' :{
      width:'100%',
    },
    
    
[theme.breakpoints.down(1635)]: {
 
 "& .skills_container": {
   
  },
  
  }
  }));

  imgProfile =(profilePic:string | null ,backgroundColor:string | null) =>{
    if(profilePic){
      return (
      <img
                  src={`${this.state.baseUrl}${profilePic}`}
                  alt="Profile"
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "7px",
                    position: "absolute",
                    
                  }}
                />
      )
  } else if(backgroundColor){
      return(
          <Box style={{ width: "100%",
                    height: "100%",
                    borderRadius: "7px",
                    position: "absolute",
                    backgroundColor:`${backgroundColor}`
                    }}>

          </Box>  
      )
  } else {
      return(
        
            <Box style={{ width: "100%",
                    height: "100%",
                    borderRadius: "7px",
                    position: "absolute",
                    backgroundColor:'grey'
                    }}>
          </Box>   
      ) 
  }
 }
 ProfilePicImg =(profilePic:string | null ,backgroundColor:string | null) =>{
      if(profilePic) {
        return (
          <img src={this.state.baseUrl+profilePic} width={28} height={28} style={{borderRadius:"50px",marginRight:"10px"}}  alt="img"></img>
        )
      }else if(backgroundColor) {
        return (
          <Box style={{ backgroundColor:`${backgroundColor}`,width:"28px",height:"28px", borderRadius:"50px",marginRight:"10px"}}>
          </Box>
        )
      }else {
        return (
          <img src={ProfilePic} width={28} height={28} style={{borderRadius:"50px",marginRight:"10px"}}  alt="img"></img>
        )
      }
 }
  render() {
    const {attributes}=this.props.data;
    
    return (
      <this.MainWrapper  style={{width:"100%"}}>
        <Box className='MainContaner'>
        <div className="container">
        <div className="div1 div01">Div 1</div>
        <div className="div1 div02">Div 2</div>
        <div className="div1 div03">Div 3</div>
        <div className="image-div">
          <div style={{
            width:"100%",
            height:"100%",
            display:"flex",
            alignItems:"end",
          }}>
            
                {this.imgProfile(attributes.profile_photo, attributes.profile_background_color)}
            <Box style={{position:"relative",width:"100%"}}>

            <Box className='cardbgd' style={webStyle.cardbgd}>
              <Box className='UsernameCont' style={webStyle.UsernameCont}>
                {this.ProfilePicImg(attributes.profile_photo,attributes.profile_background_color)}
               
                <Typography className='userNme' style={webStyle.userNme}>{attributes.user_name}</Typography>
              </Box>
              <Box style={{paddingTop:"15px",paddingLeft:"10px",paddingBottom:"10px"}}>
                <Typography className='RandomNme' style={webStyle.RandomNme}>{attributes.name}</Typography>
                <Typography className='ProjectCtn' style={webStyle.ProjectCtn}>{attributes.project_count} projects</Typography>
              </Box>

            </Box>
            
            </Box>

          </div>
            {/* <img src={SampleImg} width={220} height={220} style={{borderRadius:"7px"}} alt="Your Image" /> */}
        </div>
    </div>
               
        </Box>
      </this.MainWrapper>
    )
  }
}

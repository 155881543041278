// Customizable Area Start
import React from "react";
import { Snackbar } from "@material-ui/core";

const successIcon = require('../../blocks/assets/success.png');
const closeIcon = require('../../blocks/assets/close.png');

// Define props interface
interface ToastProps {
  open: boolean;
  close: () => void;
  autoHideDuration?: number;
  vertical?: "bottom" | "top";
  horizontal?: "left" | "center" | "right";
  message: string;
  action: string;
}

const customStyle = {
    contailerElm: {
        display: "flex",
        flex: 1
    },
    mainContainer: {
        width: "505px",
        paddingLeft: "20px",
        backgroundColor: "#595959",
        paddingBottom: "26px",
        borderRadius: "0 11.5px 11.5px 0"
    },
    leftBorder: {
        width: "17px",
        borderRadius: "11.5px 0px 0px 11.5px"
    },
    danger: {
        backgroundColor: "#ED4F4F"
    },
    info: {
        backgroundColor: "#59BDD3"
    },
    success: {
        backgroundColor: "#61CA78"
    },
    sideElm: {
        display: "flex",
        alignItems: "center",
        paddingLeft: "20px"
    },
    textStyle: {
        lineHeight: "24px",
        letterSpacing: "0.38px",
        color: "#fff2e3",
    },
    subText: {
        fontSize: "14px",
        fontFamily: "Inter, sans serif",
        marginRight: "27px"
    },
    hedingText: {
        fontSize: "20px",
        fontFamily: "Inter-SemiBold, sans serif",
    },
    flexData: {
        display: "flex",
        alignItems: "center",
        gap: "20px"
    },
    closeIcon: {
        marginBottom: "5px",
        marginTop: "14px",
        marginRight: "18px",
        display: "flex",
        justifyContent: "end"
    }
}
// Define class component
class ToastMSG extends React.Component<ToastProps> {
  render() {
    const { open, close, autoHideDuration, vertical, horizontal, message, action } = this.props;

    return (
      <Snackbar
        open={open}
        autoHideDuration={autoHideDuration || 3000}
        onClose={close}
        anchorOrigin={{
          vertical: vertical || "top",
          horizontal: horizontal || "center",
        }}
        action={action}
        style={{ zIndex: 99999 }}
      >
        <div style={customStyle.contailerElm}>
            <div style={{...customStyle.leftBorder, ...(this.props.action === "info" ? customStyle.info: (this.props.action === "danger" ? customStyle.danger: customStyle.success))}}></div>
            <div style={customStyle.mainContainer}>
                <div style={customStyle.closeIcon}>
                    <img onClick={() => close()} width="9" height="9" src={closeIcon} alt="close icon not found" />
                </div>
                <div style={customStyle.flexData}>
                    <img width={27} height={27} src={successIcon} alt={`${this.props.action} icon not found`} />
                    <div style={{...customStyle.subText, ...customStyle.textStyle}}>{message}</div>
                </div>
            </div>
        </div>
      </Snackbar>
    );
  }
}

export default ToastMSG;

// Customizable Area End

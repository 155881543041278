// Customizable Area Start
import React from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { logoImage, leftArrow } from "./assets";
import "./styles.css";
import ToastMSG from "../../../components/src/ToastMSG.web";
// Customizable Area End

import ForgotPasswordController, {
  Props,
} from "./ForgotPasswordController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class ForgotPasswordOTP extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start
 
 // Customizable Area End
  render() {
    return (
       // Customizable Area Start
      // Required for all blocks
    
      <ThemeProvider theme={theme}>
        <div className="forgot-password-otp-container">
            <div className="form-container">
                <div className="data-container">
                <img src={`${logoImage}`} className="logo-image"/>
                <div className="otp-name">
                    Verify OTP
                </div>
                <div className="hint-text">
                    Kindly enter the confirmation code sent to the following email: {sessionStorage.getItem("emailValue")}
                </div>
                <div>
                    <div className="label-text">OTP</div>
                    <input 
                        type="text" 
                        className="input-elm"
                        value={this.state.otpData}
                        onChange={(event) => this.handleOTP(event?.target.value)}
                    />
                    {this.state.isSubmit && this.state.errorMSG.otpData && <p className="error-text">{this.state.errorMSG.otpData}</p>}
                </div>
                </div>
                <button 
                    className="cnfrm-btn rippleBtn"
                    data-test-id="submitBtn"
                    onClick={() => this.verifyOTP()}
                >Submit</button>
                <hr className="devider-field"/>
                <div className="bottom-elem">
                    Don’t have an account? <span className="font-w-500" data-test-id="signupOtpBtn" onClick={() => this.redirectToSignUP("EmailAccountRegistration")}>Sign up</span>
                </div>
                <ToastMSG close={() => this.handleCloseToast()} open={this.state.open} message={this.state.message} action={this.state.action} />
        </div>
        </div>
          
      </ThemeProvider>
       // Customizable Area End
    );
  }
}


// Customizable Area End

// Customizable Area Start
import React from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { logoImage, leftArrow } from "./assets";
import "./styles.css";

// Customizable Area End

import ForgotPasswordController, {
  Props,
} from "./ForgotPasswordController";
import ForgotPasswordOTP from "./ForgotPasswordOTP.web";
import ToastMSG from "../../../components/src/ToastMSG.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start
 
 // Customizable Area End
  render() {
    return (
       // Customizable Area Start
      // Required for all blocks
    
      <ThemeProvider theme={theme}>
        <div>
        {this.state.component === "ForgotPassword" ? <div className="forgot-password-container">
            <div className="form-container">
                <div className="data-container">
                <img src={`${logoImage}`} className="logo-image"/>
                <div 
                  className="password-name"
                >
                    <img data-test-id="backButton" onClick={() => this.redirectToSignUP("EmailAccountLoginBlock")} src={`${leftArrow}`} className="left-arrow"/>
                    <span>Forgot password</span>
                </div>
                <div className="hint-text">
                    No worries, we’ll send you an OTP to your mail id
                </div>
                <div>
                    <div className="label-text">Email</div>
                    <input 
                        type="email" 
                        className="input-elm"
                        placeholder="Enter your email"
                        onChange={(event) => this.handleEmail(event.target.value)}
                    />
                    {this.state.isSubmit && this.state.errorMSG.emailValue && <p className="error-text">{this.state.errorMSG.emailValue}</p>}
                </div>
                </div>
                <button className="cnfrm-btn rippleBtn" data-test-id="confirmBtn" onClick={() => this.handleForgotPassword()}>Confirm</button>
                <hr className="devider-field"/>
                <div className="bottom-elem">
                    Don’t have an account? <span data-test-id="signupBtn" className="font-w-500" onClick={() => this.redirectToSignUP("EmailAccountRegistration")}>Sign up</span>
                </div>
        </div>
        </div>
  : null}
  {this.state.component === "verifyOTP" ? <div><ForgotPasswordOTP {...this.props }/></div>: null}
  <ToastMSG close={() => this.handleCloseToast()} open={this.state.open} message={this.state.message} action={this.state.action} />
  </div>
      </ThemeProvider>
       // Customizable Area End
    );
  }
}


// Customizable Area End

// Customizable Area Start
import React from "react";
import  { profileIcon } from "../assets";
import { FlatList, StyleSheet, View, Text, ListRenderItem, Image } from "react-native";
import "../styles.css";

import WorkOpportunityController, { Props } from "./WorkOpportunityController";
import WorkOppertunityModal from "./WorkOppertunityModal.web";
import { Box, Typography } from "@material-ui/core";
const config = require("../../../../framework/src/config.js");

class WorkOpportunity extends WorkOpportunityController {
  constructor(props: Props) {
    super(props);
    this.renderItem = this.renderItem.bind(this);
  }

  handleVisibility = (data: string, location?: string) => {
    return data && data.trim() ? <Text style={location ? styles.itemUserLocationText: styles.itemUserNameText}>{data}</Text>: null
  }

  ItemSeparator = () => <View style={styles.separator} />;
  ProfileImage = (profilePic:string | null ,backgroundColor:string | null) => {
    if(profilePic){
        return <img style={styles.itemImage} src={config.baseURL + profilePic}/>
    } else if(backgroundColor){
        return <Box style={{width: 44,height: 44,borderRadius: 22,marginRight: 8,backgroundColor:`${backgroundColor}`}}></Box>
    } else {
        return <img style={styles.itemImage} src={profileIcon}/>
    }
  }
  renderItem:ListRenderItem<{
    work_opportunity_id: number | string,
    account_id: number | string,
    title: string,
    hourly_rate: string | number,
    project_timeline: string | number,
    project_timeline_type?: string,
    description: string,
    name: string,
    rate_type:string,
    location: string,
    posted_time_ago: string,
    profile_image: string | null,
    background_color: string | null
}> = ({  item }) => {
  return(
  <Box style={webStyle.itemContainer} flexDirection={"column"} boxSizing={"border-box"} data-testId="workOppertunityModal" onClick={() => this.handleOpen(item.work_opportunity_id)}
  >
      <View style={styles.itemHeading} >
          <Text style={styles.itemHeadingText} data-testId="worktitle" >{item.title}</Text>
          <View style={styles.itemRateDurationContainer}>
              <View style={{...styles.itemRateDuration, ...styles.marginRate}}>
                  <Text style={styles.itemRateText}>Rs.{item.hourly_rate?.toLocaleString()} {this.formatToLocaleString(item)}</Text>
              </View>
              <View style={styles.itemRateDuration}>
                  <Text style={styles.itemDurationText}>{this.formatProjectTimeline(item)}</Text>
              </View>
          </View>
          <Text style={styles.itemDescriptionText} numberOfLines={2} ellipsizeMode="tail">
              {item.description}
          </Text>
      </View>
            <div data-testId="NavigatetoPublic" onClick={(event)=>this.HandleNavigationitem(event,item)}>
      <View style={styles.itemFooterData}>
          <View style={styles.flexItem}>
            {this.ProfileImage(item.profile_image,item.background_color)}
              <View style={styles.flexItemData}>
              {this.handleVisibility(item.name)}
              {this.handleVisibility(item.location, "location")}
              </View>      
          </View>
          <Text style={styles.itemPostedText}>{item.posted_time_ago}</Text>
      </View>
            </div>
  </Box>
)};
  render() {
    return (<>
      {this.handleViewMoreShaow() && <div className="work-opportunity-container">
        <div className="heading-text">Work Opportunities</div>
        
        <Box style={webStyle.btnBox}>
            <Typography  data-test-id="view-more" onClick={() => this.handleNavigation("FindWork")} style={webStyle.btnStyle}> View more</Typography>
           </Box>
       
        <div className="tags-container">
            <View style={styles.container}>
                <FlatList
                    data-test-id="flat-list"
                    data={this.state.opportunityList}
                    renderItem={this.renderItem as any}
                    keyExtractor={(item: any) => item.id}
                    horizontal
                    showsHorizontalScrollIndicator={false}
                    contentContainerStyle={styles.flatListContent}
                    ItemSeparatorComponent={this.ItemSeparator}
                />
            </View>
            {this.state.openOppertunity && (
                <WorkOppertunityModal
                    id="some-id"
                    selectedOpportunityId={this.state.selectedOpportunityId}
                    handleClose={this.handleClose}
                    openOppertunity={this.state.openOppertunity}
                    handleNavigate = {this.handleNavigation}
                />
            )}
        </div>
      </div>}
      </>
    );
  }
}

const styles = StyleSheet.create({
    container: {
      paddingTop: 24,
    },
    flatListContent: {
        display: "flex",
        flexDirection: "row"
    },
    separator: {
        width: 24
    },
    itemHeading: {
      display: "flex",
      flexDirection: "column"
    },
    itemHeadingText: {
        color: "#fff2e3",
        fontSize: 20,
        lineHeight: 24,
        letterSpacing: 0.38,
        fontFamily: "Inter-SemiBold,sans-serif"
    },
    itemDescriptionText: {
        fontSize: 12,
        lineHeight: 16.02,
        marginTop: 24,
        color: "#fff2e3",
        fontFamily: "Inter,sans-serif",
        overflow: 'hidden',
    }, 
    itemRateDurationContainer: {
        display: "flex",
        marginTop: 24,
        flexDirection: "row"
    },
    marginRate: {
        marginRight: 16
    },
    flexItem: {
        display: "flex",
        marginTop: 8,
        width: "max-content",
        flexDirection: "row",
        alignItems: "center"
    },
    flexItemData: {
        display: "flex"
    },
    itemRateDuration: {
        backgroundColor: "#1d1d1d",
        borderRadius: 24,
        paddingVertical: 6,
        paddingHorizontal: 16,
        height: 29,
        textAlign: "center",
    },
    itemRateText: {
        color: "#1CBAE0",
        fontSize: 14,
        lineHeight: 16.94,
        fontFamily: "Inter-SemiBold,sans-serif",
        width:'131px',
        height:"28px"
    },
    itemDurationText: {
        color: "#FFB9C7",
        fontSize: 14,
        lineHeight: 16.94,
        fontFamily: "Inter-SemiBold,sans-serif"
    },
    itemFooterData: {
        marginTop: 16,
        borderTopWidth: 1.05,
        borderTopColor: "#3E3E3E",
        borderStyle: "solid",
        paddingTop: 11,
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        flexDirection: "row",
    },
    itemImage: {
      width: 44,
      height: 44,
      borderRadius: 22,
      marginRight: 8
    },
    itemUserNameText: {
        color: "#fff2e3",
        fontFamily: "Inter-SemiBold,sans-serif",
        fontSize: 14,
        lineHeight: 16.94,
        marginBottom: 9
    },
    itemUserLocationText: {
        color: "#fff2e3",
        fontFamily: "Inter,sans-serif",
        fontSize: 12,
        lineHeight: 14.52,
        textTransform:"capitalize"
    },
    itemPostedText: {
        color: "#66625C",
        fontFamily: "Inter,sans-serif",
        fontSize: 11,
        lineHeight: 13.31,
        alignSelf: "flex-end"
    }
});
  export const webStyle={
    btnBox:{display:"flex", justifyContent:"flex-end", marginRight:"52px"},
    btnStyle:{color:'#FFF2E3', borderBottom:"1px solid #FFF2E3", fontSize:"16px", fontWeight:400, fontFamily:"Inter", lineHeight:"19.36px",cursor:"pointer"},
    itemContainer:{
        width: "372px",
        padding: "20px",
        backgroundColor: 'transparent',
        borderRadius: "12px",
        border: "1px solid #3E3E3E", 
        display: "flex",
        justifyContent: "space-between",
        cursor:"pointer",
    }
   }

export default WorkOpportunity;

// Customizable Area End
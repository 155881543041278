Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.getProfilesApiEndPoint = "bx_block_profile/profiles/get_designers_profile"
exports.page = "?page="
exports.perPage = "&per_page="
exports.cardsList = [
    {
      "id": "2",
      "type": "designers_profile",
      "attributes": {
        "id": 2,
        "country": "USA",
        "address": "123 Main St",
        "city": "New York",
        "postal_code": "10001",
        "account_id": 2,
        "photo": "https://www.w3schools.com/w3images/avatar2.png",
        "background_color": "blue",
        "website_link": "https://example.com",
        "about": "A creative designer based in NY.",
        "expertise_category": ["Web Design"],
        "skills": [
          {
            "id": 1,
            "name": "UI/UX Design",
            "created_at": "2024-08-12T11:52:50.714Z",
            "updated_at": "2024-08-12T11:52:50.714Z",
            "category_id": 2
          },
          {
            "id": 2,
            "name": "Graphic Design",
            "created_at": "2024-08-12T11:52:50.718Z",
            "updated_at": "2024-08-12T11:52:50.718Z",
            "category_id": 2
          }
        ],
        "languages": ["English"],
        "min_per_hour": 50,
        "max_per_hour": 100,
        "dribble": "https://dribbble.com/user1",
        "instagram": "https://instagram.com/user1",
        "linkedin": "https://linkedin.com/in/user1",
        "member_since": "Member Since 2023",
        "followers_count": 100,
        "following_count": 50,
        "work_experience": ["3 years at XYZ Studio"],
        "profile_image_id": 101,
        "profile_cover_image": null,
        "name": "John Doe",
        "ratings": 4.5,
        "opportunities_count": 10,
        "views": 2000,
        "likes": 500,
        "user_name": "john_doe"
      }
    },
    {
      "id": "3",
      "type": "designers_profile",
      "attributes": {
        "id": 3,
        "country": "UK",
        "address": "456 Elm St",
        "city": "London",
        "postal_code": "EC1A 1AA",
        "account_id": 3,
        "photo": null,
        "background_color": "green",
        "website_link": "https://example2.com",
        "about": "Expert in branding and identity.",
        "expertise_category": ["Branding"],
        "skills": [
          {
            "id": 3,
            "name": "Logo Design",
            "created_at": "2024-08-13T11:52:50.714Z",
            "updated_at": "2024-08-13T11:52:50.714Z",
            "category_id": 3
          },
          {
            "id": 4,
            "name": "Corporate Identity",
            "created_at": "2024-08-13T11:52:50.718Z",
            "updated_at": "2024-08-13T11:52:50.718Z",
            "category_id": 3
          }
        ],
        "languages": ["English"],
        "min_per_hour": 60,
        "max_per_hour": 120,
        "dribble": "https://dribbble.com/user2",
        "instagram": "https://instagram.com/user2",
        "linkedin": "https://linkedin.com/in/user2",
        "member_since": "Member Since 2022",
        "followers_count": 150,
        "following_count": 60,
        "work_experience": ["5 years at ABC Agency"],
        "profile_image_id": 102,
        "profile_cover_image": null,
        "name": "Jane Smith",
        "ratings": 4.8,
        "opportunities_count": 12,
        "views": 2500,
        "likes": 800,
        "user_name": "jane_smith"
      }
    }
  ];
exports.productAPiEndPoint = "catalogue/catalogues";
// Customizable Area End
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const dribble = require("../../assets/dribble.svg");
export const profile = require("../../assets/userProfileImg.png");
export const insta = require("../../assets/instagram.svg");
export const linkdin = require("../../assets/linkdin.svg");
export const share = require("../../assets/share.svg");
export const edit = require("../../assets/secondaryEdit.svg");
export const profileImg = require("../../assets/userProfileLogo.png");
export const rating = require("../../assets/rating.svg");
export const expertise = require("../../assets/expertise.svg");
export const rightArrow = require("../../assets/leftArrowIcon.svg");
export const closeIcon = require("../../assets/closeIcon.svg");
export const dancingBar = require("../../assets/dancingBar.png");
export const dropDown = require("../../assets/dropDownIcon.png");
export const rateGradient = require("../../assets/rateGradient.svg");
export const deleteSymbol = require("../../assets/deleteSymbol.png");
export const userLogo = require("../assets/ic_userlogo.png");
export const backgroundImage = require("../assets/ic_backgroundImg.png");
export const editBackground = require("../assets/editBackground.png");
export const addImgeIcon = require("../assets/addImgeIcon.png");


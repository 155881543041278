export const slider1 = require("../assets/slider-1.jpg");
export const slider2 = require("../assets/slider-2.jpg");
export const slider3 = require("../assets/slider-3.jpg");
export const leftIcon = require("../assets/leftIcon.svg");
export const rightIcon = require("../assets/rightIcon.svg");
export const loveIcon = require("../assets/loveIcon.svg");
export const likedIcon = require("../../assets/likedIcon.png");
export const eyeIcon = require("../assets/eyeIcon.svg");
export const likeIcon = require("../../assets/likeIcon.svg");
export const userProfile = require("../assets/ic_userlogo.png");

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
const fileSaver = require("file-saver");
const vCardsJS = require("vcards-js");
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedDate: string;
  gender: boolean;
  firstName: string;
  middleName: string;
  lastName: string;
  organization: string;
  contactNumber: string;
  title: string;
  websiteUrl: string;
  note: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
export default class ContactListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fileReader = new FileReader();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      selectedDate: "",
      gender: true,
      firstName: "",
      middleName: "",
      lastName: "",
      organization: "",
      contactNumber: "",
      title: "",
      websiteUrl: "",
      note: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleChangeGender = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({
      gender: event.target.value === "false" ? false : true,
    });
  };

  handleChange = (event: React.ChangeEvent<{ name: string; value: unknown }>) => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  };

  generateCard = () => {
    let uservCards = vCardsJS();
    uservCards.firstName = this.state.firstName;
    uservCards.middleName = this.state.middleName;
    uservCards.lastName = this.state.lastName;
    uservCards.organization = this.state.organization;
    uservCards.workPhone = this.state.contactNumber;
    uservCards.url = this.state.websiteUrl;
    uservCards.note = this.state.note;
    uservCards.title = this.state.title;
    if (this.state.selectedDate !== "") {
      uservCards.birthday = new Date(this.state.selectedDate);
    }
    if (this.state.gender) {
      uservCards.photo.attachFromUrl(
        "https://images.app.goo.gl/8MTbux3zY6nZoQDo7",
        "JPEG"
      );
    } else {
      uservCards.photo.attachFromUrl(
        "https://images.app.goo.gl/5pWpcWuuioBxu1Kh6",
        "JPEG"
      );
    }
    const blob = new Blob([uservCards.getFormattedString()], {
      type: "text/vcard;charset=utf-8",
    });
    fileSaver.saveAs(
      blob,
      `${this.state.firstName}-${this.state.lastName}.vcf`
    );
  };

  onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.fileReader.onloadend = this.handleFileRead;
    this.fileReader.readAsText(event!.target!.files![0]);
  };

  handleFileRead = () => {
    const content = this.fileReader.result;
    const result = this.convertVcardData(content as string);
    const displayName = result.displayName.split(" ");
    let selectedDate = "";
    if (result.birthday) {
      const tempDateArray = result.birthday.split("");
      tempDateArray.splice(6, 0, "-");
      tempDateArray.splice(4, 0, "-");
      selectedDate = tempDateArray.join("");
    }

    if (displayName.length === 3) {
      this.setState({
        firstName: displayName[0],
        middleName: displayName[1],
        lastName: displayName[2],
      });
    } else if (displayName.length === 2) {
      this.setState({
        firstName: displayName[0],
        lastName: displayName[1],
      });
    } else {
      this.setState({
        firstName: displayName[0],
      });
    }
    this.setState({
      organization: result.company,
      contactNumber: result.phoneNumbers,
      selectedDate,
      title: result.jobTitle,
      websiteUrl: result.websiteUrl,
      note: result.note,
    });
  };

  convertBirtDayFormat = (date: string) => {
    const currentDate = new Date();
    if (date) {
      return {
        day: Number(date.substring(6, 8)),
        month: Number(date.substring(4, 6)),
        year: Number(date.substring(0, 4)),
      };
    }
    return {
      day: currentDate.getDay(),
      month: currentDate.getMonth(),
      year: currentDate.getFullYear(),
    };
  };

  findContentByLabel = (item: Array<string>, label: string) => {
    let content = "";
    const contactData = item?.find((item: string) => {
      return item.startsWith(label);
    });
    if (contactData) {
      content = contactData?.split(":")[1];
    }
    return content;
  };

  convertVcardData = (fileContents: string) => {
    const contents = fileContents?.split("\r\n");
    return {
      displayName: this.findContentByLabel(contents, "FN;"),
      company: this.findContentByLabel(contents, "ORG;"),
      phoneNumbers: this.findContentByLabel(contents, "TEL;"),
      jobTitle: this.findContentByLabel(contents, "TITLE;"),
      note: this.findContentByLabel(contents, "NOTE;"),
      birthday: this.findContentByLabel(contents, "BDAY:"),
      websiteUrl: this.findContentByLabel(contents, "URL;"),
      photo: this.findContentByLabel(contents, "PHOTO;"),
    };
  };
  // Customizable Area End
}

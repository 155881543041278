import React from "react";

// Customizable Area Start
import {
  Box,
  Input,
  Modal,
  Button,
  Typography
} from "@material-ui/core";
import { ThemeProvider, createTheme, withStyles, styled } from "@material-ui/core/styles";
import { 
  searchIcon, 
  chatIcon, 
  notificationIcon, 
  userIcon, 
  logoIcon, 
  activeChatIcon, 
  activeNotificationIcon, 
  activeUserIcon,
  postOpportunityActive,
  postOpportunity,
  shareProjectActive,
  shareProject 
} from "./assets";
const typoWhite = "#FFF2E3"

const MyComponent = styled('div')({
  backgroundColor: '#171717',
  borderRadius: "24px",
  "& *": {
    boxSizing: "border-box"
  },
  "& .searchCon": {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#252525",
    borderRadius: "24px",
    padding: "0 24px",
    height: "48px",
  },
  "& .searchIcon": {
    width: "16px",
    height: "16px",
    objectFit: "contain",
    flexShrink: 0,
    marginRight: ".5rem",
  },
  "& .searchBar": {
    outline: "none",
    border: "none",
    fontFamily: "Inter",
    flexGrow: 1,
    height: "100%",
    backgroundColor: "transparent",
    color: typoWhite,
    "&::placeholder": {
      color: "#676767",
      fontFamily: "Inter",
    },
  },
  "& .searchTextCon": {
    paddingLeft: "1rem",
    paddingRight: ".4rem",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .searchText": {
    textDecoration: "underline",
    fontFamily: "Inter-SemiBold",
    cursor: "pointer",
  },
  "& .tabsCon": {
    padding: "24px",
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    borderBottom: "1px solid #434343",

  },
  "& .tabOuterCon": {
      border: '.5px solid #FFF2E3',
      borderRadius: "2rem",
      height: "2.5rem",
      width: "fit-content",
      cursor: "pointer",
    },
    "& .gradientCon": {
      border: '1px solid #FFF2E3',
    },
    "& .activeTab": {
      backgroundColor: "#313131",
    },
    "& .tab": {
      width: "100%",
      height: "100%",
      borderRadius: "inherit",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "11px 22px"
    },
    "& .activeFont": {
      fontFamily: "Inter",
      fontSize: "14px",
    },
    "& .inActiveFont": {
      fontFamily: "Inter",
      color: typoWhite,
    },
    "& .suggestedUserCon": {
      paddingTop: "1rem",
      paddingLeft: "1.5rem",
      paddingRight: "1.5rem",
      paddingBottom: "2rem"
    },
    "& .suggestedUsers": {
      color: "#8D8D8D",
      marginBottom: "1rem"
    },
    "& .suggestionsCon":{
      display: "flex",
      flexDirection: "column" as "column",
      gap: "1rem"
    },
    "& .suggestedUserList": {
      cursor: "pointer",
    }
});

const CustomTextField = withStyles({
  root: {
    '& .MuiInputBase-input': {
      backgroundColor: "transparent",
      color: "#FFF2E3",
      border: "none",
      fontFamily: "Inter, sans-serif",
      fontSize: "13px",
      lineHeight: "15.73px",
      textAlign: "left",
      width: "90%",
      borderBottom: "none",
      '&::placeholder': {
        color: "#676767 !important",
        opacity: 1 
      }
    },
    '&::before': {
      borderBottom: "none"
    },
    '&::after': {
      borderBottom: "none"
    },
    '&:hover:not(.Mui-disabled)::before': {
      borderBottom: "none !important"
    }
  },
})(Input);
// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleButtonElm = () => {
    return (
      <Button 
        data-test-id="tabButton"
        style={{...webStyle.buttonElm, ...(this.state.userType === "designer") ? webStyle.shareProjectBtn: webStyle.postOpportunityBtn, ...((this.state.activeTab === "share project") || (this.state.activeTab === "post opportunity")) ? ((this.state.activeTab === "post opportunity") ? webStyle.postOpportunityBtnActive: webStyle.shareProjectBtnActive): {}}}
        onClick={() => this.handleActiveTab((this.state.userType === "designer" ? "share project": "post opportunity"), this.state.userType === "designer" ? "ShareProject": "PostOpportunity")}
      />
    );
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    
    return (
      <ThemeProvider theme={theme}>
        <Box style={{
          ...webStyle.headerContainer, 
          position: 'fixed',
          width: '95%',
          zIndex: 999,
        }}>
          <img 
            src={`${logoIcon}`} 
            style={webStyle.logoCenter}
            alt="logo icon"
            data-test-id="tabButton"
            onClick={() => this.handleActiveTab("", "LandingPage")}
          />
          <Box style={webStyle.navbar}>
            <Box style={webStyle.nav}>
              <Box 
                style={{...navItem, ...(this.state.activeTab === "find work" || this.state.activeTab === "my work") ? activeNavItem: {}}}
                data-test-id="tabButton"
                onClick={() => {{this.state.userType === "designer" ? this.handleActiveTab("find work", "FindWork"): this.handleActiveTab("my work", "MyWork")}}}
              >
                {this.state.userType === "designer" ? "Find": "My"} Work
              </Box>
              <Box 
                style={{...navItem, ...(this.state.activeTab === "contracts") ? activeNavItem: {}}}
                data-test-id="tabButton"
                onClick={() => this.handleActiveTab("contracts", "LandingPage")}
              >
                Contracts
              </Box>
              <Box 
              className={`${(this.state.activeTab === "forum") ? "nav-active": ""}`}
                style={{...navItem, ...(this.state.activeTab === "forum") ? activeNavItem: {}}}
                data-test-id="tabButton"
                onClick={() => this.handleActiveTab("forum", "LandingPage")}
              >
                Forum
              </Box>
            </Box>
            <Box 
              data-testid="searchBarReadOnly"
              style={this.isSearchBarVisiable() ? webStyle.displayNone : webStyle.searchContainer} 
              onClick={this.toggleSearchFeild}>
              <img src={`${searchIcon}`} alt="search icom" />
              <CustomTextField placeholder="Search" readOnly/>
            </Box>
            <Modal 
              data-testid="modal"
              BackdropProps={{style: webStyle.modalCon}}
              onClose={this.toggleSearchFeild}
              open={this.state.isSearchFeildOpen}>
                <Box style={webStyle.searchCon}>
                  <MyComponent>
                <Box className={"searchCon"}>
                  <img src={searchIcon} className="searchIcon" alt="search icon" />
                  <input
                    data-testid="searchBar"
                    className="searchBar"
                    onChange={this.handleInputChange}
                    onKeyDown={this.handleKeyDown}
                    value={this.state.search}
                    ref={this.inputRef}
                  />
                  <Box className="searchTextCon">
                    <Typography 
                      variant="body2"
                      className="searchText"  
                      data-testid="searchText"
                      onClick={this.handleSearchButtonClick}
                      >
                        {configJSON.searchText}
                    </Typography>
                  </Box>
                </Box>
                <Box className={"tabsCon"}>
                {this.state.tabsList.map(tabItem => (
                    <Box className={this.getActiveTabBorder(tabItem.tabId)} key={tabItem.tabId}>
                      <Box 
                        className={this.getActiveTabBackground(tabItem.tabId)} 
                        data-testid={"tabItem"+tabItem.tabName}
                        onClick={() => this.handleTabChange(tabItem.tabId)}>
                        <Typography variant="body2" className={this.getActiveTabFont(tabItem.tabId)}>{tabItem.tabName}</Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
                <Box className="suggestedUserCon">
                  <Typography variant="body2" className="suggestedUsers">{configJSON.suggestedUsers}</Typography>
                  <Box className="suggestionsCon">
                    {configJSON.listOfUsers.map(
                      (user:string,idIndex:number) => (
                        <Typography 
                          key={idIndex} 
                          data-testid="suggestedUserList"
                          className="suggestedUserList"
                          onClick={()=>{this.handleUserNameNavigate(user)}}
                        >
                          {user}
                        </Typography>
                      )
                    )}
                  </Box>
                </Box>
                </MyComponent>
                </Box>
            </Modal>
            <Box style={webStyle.endDataContainer}>
              {this.handleButtonElm()}
              <Box style={webStyle.iconContainer}>
                <img 
                  src={`${(this.getActiveTabName() === "chat") ?  activeChatIcon: chatIcon}`} 
                  onClick={() => this.handleActiveTab("chat", "Chat")}
                  data-test-id="tabButton"
                  alt="chat icon"
                  style={webStyle.iconCss}
                />
                <img 
                  src={`${(this.state.activeTab === "notification") ?  activeNotificationIcon: notificationIcon}`} 
                  onClick={() => this.handleActiveTab("notification", "Notifications")}
                  style={webStyle.mx2}
                  data-test-id="tabButton"
                  alt="notification icon"
                />
                <img 
                  src={`${(this.state.activeTab === "user") ?  activeUserIcon: userIcon}`} 
                  onClick={() => this.handleActiveTab("user", "UserProfile")}
                  data-test-id="tabButton"
                  alt="user icon"  
                  style={webStyle.iconCss}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Inter, sans-serif",
    h1: {
      color: typoWhite,
      fontSize: "2.25rem",
      fontWeight: 700,
      lineHeight: "24px",
      letterSpacing: "0.38px",
      textAlign: "left",
      fontFamily: "Inter-SemiBold, sans-serif",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    h2: {
      color: typoWhite,
      fontSize: "1.5rem",
      fontWeight: 700,
      lineHeight: "29.05px",
      textAlign: "left",
      fontFamily: "Inter-SemiBold, sans-serif",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    h4: {
      color: typoWhite,
      fontSize: "1.25rem",
      fontWeight: 700,
      lineHeight: "24.2px",
      textAlign: "left",
      fontFamily: "Inter-SemiBold, sans-serif",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    body1: {
      color: typoWhite,
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: "19.36px",
      textAlign: "left",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    body2: {
      color: typoWhite,
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: "16.94px",
      textAlign: "left",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    caption: {
      color: typoWhite,
      fontSize: "0.75rem",
      fontWeight: 400,
      lineHeight: "14.52px",
      textAlign: "left",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    button: {
      color: `${typoWhite}`,
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: "19.36px",
      textTransform: "none",
      letterSpacing: "0.38px",
    }
  },
});

const navItem = {
  color: "#FFF2E3",
  borderWidth: 0,
  borderBottom: "2px solid",
  borderBottomColor: "transparent",
  borderImage: "none",
  backgroundColor: "transparent",
  backgroundImage: "none",
  height: "48px",
  paddingTop: "26px",
  fontFamily: "Inter, sans-serif",
  fontSize: "16px",
  lineHeight: "19.36px",
  width: "max-content",
  textAlign: "left",
  cursor: "pointer"
} as React.CSSProperties;

const activeNavItem = {
  borderImageSource: "linear-gradient(90.83deg, #3FA7E1 -0.01%, #7699EF 31.13%, #AE9DF2 51.64%, #E8A5CA 72.11%, #F1B2C6 99.99%)",
  backgroundImage: "linear-gradient(90.83deg, #3FA7E1 -0.01%, #7699EF 31.13%, #AE9DF2 51.64%, #E8A5CA 72.11%, #F1B2C6 99.99%)",
  WebkitBackgroundClip: "text",
  MozBackgroundClip: "text",
  backgroundClip: "text",
  color: "transparent",
  borderImageSlice: 1,
  borderImageRepeat: "round",
  fontFamily: "Inter-SemiBold, sans-serif"
} as React.CSSProperties;

const webStyle = {
  modalCon: {
    width: "100%",
    height: "100%",
    position: "relative" as "relative",
  },
  displayNone: {
    display: "none"
  },
  logoCenter: {
    alignItems: "center",
    marginRight: "47px",
    width: "28px",
    height: "38.38px",
    cursor:'pointer',
    marginTop: "18px"
  },
  mx2: {
    marginLeft: "2px",
    marginRight: "2px",
    cursor: "pointer"
  },
  headerContainer: {
    background: "#1D1D1D",
    display: "flex",
    paddingLeft: "48px",
    paddingRight: "48px"
  },
  navbar: {
    height: "76px",
    display: "flex",
    flex: 1,
    justifyContent: "space-between"
  },
  nav: {
    width: "293px",
    display: "flex",
    justifyContent: "space-between",
    marginRight: "2px"
  },
  buttonElm: {
    borderRadius: "7px",
    height: "44px",
    backgroundRepeat: "no-repeat",
    border: "none"
  },
  shareProjectBtnActive: {
    backgroundImage: `url(${shareProjectActive})`
  },
  postOpportunityBtnActive: {
    backgroundImage: `url(${postOpportunityActive})`
  },
  shareProjectBtn: {
    width: "155px",
    backgroundImage: `url(${shareProject})`
  },
  postOpportunityBtn: {
    width: "186px",
    backgroundImage: `url(${postOpportunity})`
  },
  searchCon: {
    position: "absolute" as "absolute",
    top: 16,
    left: `36%`,
    minWidth: "261px",
    maxWidth: "590px",
  },
  searchContainer: {
    height: "44px",
    alignSelf: "center",
    background: "#252525",
    borderRadius: "32px",
    display: "flex",
    alignItems: "center",
    maxWidth: "461px",
    minWidth: "261px",
    margin: "0 auto",
    gap: "7px",
    paddingLeft: "10px",
    flex: 1,
    marginLeft: "50px",
    marginRight: "50px",
    cursor: "pointer",
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "152.33px"
  },
  endDataContainer: {
    width: "363.33px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "2px"
  }, 
  iconCss:{
    cursor:"pointer"
  }
};
// Customizable Area End

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getToken as getTokenOnMount } from "../../../components/src/Base64Conversion";
import { setStorageData } from "../../../framework/src/Utilities"
import React from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  isOpen:boolean;
  profileDataList:any
  isIdSelected:number;
  openedData:any;
  isLoading:boolean;
  isFirstTimeLoading:boolean;
  totalPage:number;
  perPage:number;
  page:number;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CatalogueController extends BlockComponent<Props, S, SS> {
  getProductApiCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      // Customizable Area Start
      isOpen:false,
      profileDataList:[],
      isIdSelected:0,
      isLoading:false,
      isFirstTimeLoading:true,
      openedData:null,
      totalPage:0,
      perPage:10,
      page:1,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.itemsContainerRef = React.createRef<HTMLDivElement>()
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    await this.getTokenData()
    this.setState({isLoading:true,isFirstTimeLoading:true})
    this.getProfileList()
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getListRequest = (token: any) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getProfileApiCallId) {
        this.setState({
          profileDataList: [...this.state.profileDataList, ...(responseJson?.designers_profiles?.data || [])], totalPage:responseJson?.designers_profiles_count,isLoading:false,isFirstTimeLoading:false
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getProfileApiCallId:string = "";
  itemsContainerRef: React.RefObject<HTMLDivElement>;
  handleClose = () =>{
    this.setState({isOpen:false})
  }

  handleScroll = () => {
    const loader = this.itemsContainerRef.current;
    if (loader) {
      const { scrollLeft, scrollWidth, clientWidth } = loader;
      if (scrollLeft + clientWidth >= scrollWidth - 300 && !this.state.isLoading) {
        this.setState(prevState => ({ page: prevState.page + 1 }), () => {
            if (this.state.profileDataList.length < this.state.totalPage) {
                this.setState({ isLoading: true });
                this.getProfileList();
            }
        });
    }
    }
  };

  handleOpen = (e: React.MouseEvent<HTMLElement, MouseEvent>,id:number) =>{
    e.stopPropagation()
    if(this.state.isOpen){
      return
    }
    this.setState({isIdSelected:id,openedData:this.state.profileDataList[id].attributes},()=>{
      this.setState({isOpen:true})
    })
  }

  HandleNavigations = async(item:any) =>{
    let localdata = {
      account_id: item.account_id,
      user_role : item.user_role.role_name
    }


    await setStorageData("PublicId",JSON.stringify(localdata));
    const message: Message =
    new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationTargetMessage),
    "PublicUserProfile");
  message.addData(getName(MessageEnum.NavigationPropsMessage),
    this.props);
  this.send(message);
  }

  handleNavigation = (path:string) => {   
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), path);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  getTokenData = async () => {
    const token = await getTokenOnMount();
    this.setState({token:token})
  };

  getProfileList = () => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProfileApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProfilesApiEndPoint + configJSON.page + this.state.page +  configJSON.perPage + this.state.perPage
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}

import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  withStyles,
  WithStyles,
  Theme,
  createStyles,
  Box
} from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    dialog: {
      backgroundColor: '#222222',
      padding: '50px',
      width: '572px',
      borderRadius: '12px',
      margin: '0'
    },
    title: {
      color: '#F5E8D3',
      fontWeight: 600,
      textAlign: 'center' as const,
      marginTop: '6px',
      fontFamily: 'Inter-Medium, sans-serif',
      fontSize: '20px',
      lineHeight: '24.2px',
    },
    content: {
      backgroundColor: '#171717',
      borderRadius: '8px',
      padding: '20px',
      textAlign: 'center' as const,
      margin: '0 auto'
    },
    contentBox: {
      width: '423px'
    },
    typography: {
      color: '#F5E8D3',
      fontSize: '16px',
      fontFamily: 'Inter-Medium, sans-serif',
      fontWeight: 400,
      lineHeight: '24.88px',
    },
    cancelButton: {
      textTransform: "none",
      height: "44px",
      borderRadius: "7px",
      alignItems: "center",
      justifyContent: "center",
      verticalAlign: "middle",
      fontFamily: "Inter-Medium,sans-serif",
      lineHeight: "24px",
      letterSpacing: "0.38px",
      fontSize: "16px",
      display: "flex",
      border: "none",
      gap: "10px",
      color: "#fff2e3",
      backgroundColor: "#0d0d0d",
      width: '142px',
      marginRight: '20px',
      "&:hover": {
        backgroundColor: "#0d0d0d",
      }
    },
    deleteButton: {
      textTransform: "none",
      height: "44px",
      borderRadius: "7px",
      alignItems: "center",
      justifyContent: "center",
      verticalAlign: "middle",
      fontFamily: "Inter-Medium,sans-serif",
      lineHeight: "24px",
      letterSpacing: "0.38px",
      fontSize: "16px",
      display: "flex",
      border: "none",
      gap: "10px",
      color: "#121212",
      backgroundColor: "#fff2e3",
      width: '142px',
      "&:hover": {
        backgroundColor: "#FFF2E3",
      }
    },
    actions: {
      display: 'flex',
      justifyContent: 'center',
      padding: '40px 0',
    },
  });

interface DeleteProjectModalProps extends WithStyles<typeof styles> {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
  title: string
  content: string
  button1Text: string
  button2Text: string
}

const DeleteProjectModal: React.FC<DeleteProjectModalProps> = ({ classes, open, onClose, onDelete, title, content, button1Text, button2Text }) => {
  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.title}>{title}</DialogTitle>
      <DialogContent className={classes.content}>
        <Box className={classes.contentBox}>
          <Typography className={classes.typography}>
            {content}
          </Typography>
        </Box>
      </DialogContent>

      <DialogActions className={classes.actions}>
        <Button onClick={onClose} className={classes.cancelButton}>
          {button1Text}
        </Button>
        <Button
          onClick={() => {
            onDelete();
            onClose();
          }}
          className={classes.deleteButton}
        >
          {button2Text}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(DeleteProjectModal);

Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ContactList";
exports.labelBodyText = "ContactList Body";
exports.labelShareVCard = "Share VCard";
exports.labelNoContactText = "No Contact Found";
exports.labelContactPermission = "You have to give permission of contact";
exports.labelError = "SomeThing Went Wrong";
exports.labelFileError = "File Must Be VCF Type";
exports.fileSupport = 'text/x-vcard'
exports.organization = 'Organization'

exports.btnExampleTitle = "CLICK ME";
exports.generateCardButton = "Generate vCard";
exports.uploadFileButton = "Upload File";
exports.btnShare = "SHARE";
exports.btnAllow = "ALLOW";
exports.firstName = "First Name";
exports.middleName = "Middle Name";
exports.lastName = "Last Name";
exports.contactNumber = "Contact Number";
exports.birthDate = "Birth Date";
exports.gender = "Gender";
exports.male = "Male";
exports.female = "Female";
exports.title = "Title";
exports.url = "URL";
exports.note = "Note";

//mobile
exports.btnAdd = "+";
exports.btnTryAgain = "Try Again";
exports.btnCancel = "CANCEL";
exports.btnContinous = "CONTINOUS";
exports.importVcard = 'Import VCard'
exports.newContact = 'New Contact'
exports.name = 'Name'
exports.phone = 'Phone'
// Customizable Area End
import React from "react";
// Customizable Area Start
import ViewWorkDetailController, {
  Props,
} from "./ViewWorkDetailController.web";

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { withStyles, Box, Button, Divider, Grid, styled, Typography } from "@material-ui/core";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
export const configJSON = require("../../../framework/src/config.js")
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import CircularProgress from '@mui/material/CircularProgress';
import DeletePostModal from "../../../components/src/DeletePostModel.web";
import ToastMSG from '../../../components/src/ToastMSG.web';

import {
  likeIcon2,
  eyeIcon,
  featureIcon,
  deleteIcon,
  location,
  leftArrowIcon
} from '../../dashboard/src/assets';

const typoBlack = "#66625C";
const typoWhite = "#FFF2E3";

const theme = createTheme({
  palette: {
    primary: {
      main: '#fff2e3'
    }
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          color: '#fff2e3',
          backgroundColor: '#222'
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#fff2e3 !important',
          fontFamily: 'Inter,sans-serif !important'
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: '#fff2e3 !important',
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          color: '#7d7d7d !important',
          fontFamily: 'Inter,sans-serif !important',
          '&.Mui-selected': {
            backgroundColor: '#fff2e3 !important',
            color: '#222 !important'
          },
          '&.MuiPickersDay-today': {
            border: '1px solid #fff2e3 !important'
          }
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingBottom: 0,
          paddingTop: 0,
          overflowY: 'auto',
          backgroundColor: "transparent",
          boxShadow: "none",
          scrollbarWidth: 'none',
          overflowX: "hidden",
          msOverflowStyle: 'none',
          '&::-webkit-scrollbar': {
            height: '0',
            width: '0'
          }
        }
      }
    }
  }
});
// Customizable Area End

export class ViewWorkDetail extends ViewWorkDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  }

  renderPropsDetails = () => {
    const formatDateHistory = (dateString: string): string => {
      const date = new Date(dateString);
      const options: Intl.DateTimeFormatOptions = {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
      };
      return date.toLocaleDateString('en-GB', options).replace(/(\d{2})\/(\d{2})\/(\d{2})/, '$1/$2/$3');
    };
    return (
      <>
        {this.state.question.length > 0 &&
          <Box style={webStyle.borderBox}>
            <Box style={webStyle.paddingBox}>
              <Typography style={webStyle.title}>Additional requirements for proposal</Typography>
            </Box>
            <Box style={webStyle.paddingBoxNew}>
              {this.state.question
                .map((line, index) => (
                  <Box style={webStyle.questionBox}>
                    <Typography style={webStyle.font16Gray}>{index + 1}.</Typography>{" "}
                    <Typography style={webStyle.font16White}>{line}</Typography>
                  </Box>
                ))}
            </Box>
          </Box>
        }
        <Box style={{margin: '50px 0'}}>
          <Typography style={webStyle.title}>Post History</Typography>
          <Box style={{ margin: '25px 0' }}>
            <Box style={webStyle.postHistoryUl}>
              {this.state.postHistory.map((item, index) => (
                <>
                  <Box style={webStyle.postHistoryLi}>
                    <Box style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                      <div style={{ height: "14px", width: "14px", borderRadius: "14px", backgroundColor: "#FFF2E3" }}></div>
                      <Typography style={webStyle.openTitle}>{item.attributes.event}</Typography>
                    </Box>
                    <Typography style={webStyle.historydate}>{formatDateHistory(item.attributes.event_date)}</Typography>
                  </Box>
                  {index < this.state.postHistory.length - 1 &&
                    <Box style={webStyle.divider}></Box>
                  }
                </>
              ))}
            </Box>
          </Box>
        </Box>
      </>
    )
  }

  renderBackDrop = () => {
    return (
      <Box
        className={this.props.classes.backdrop} >
        <CircularProgress className="circular" />
      </Box>
    )
  }

  renderHourMonth = () => {
    return (
      <>
        <BoxStyle style={webStyle.btnHourBox}>
          <Typography style={this.state.rateData ? webStyle.priceHour : webStyle.priceOverAll}>
            Rs. {(Number(this.state.rateData || this.state.overallRate)).toLocaleString('en-IN')}{" "}
            {this.state.rateData ? "pr/hr" : "overall"}
          </Typography>
          <Typography style={this.state.rateData ? webStyle.month : webStyle.overallMonth}>
            {this.state.projectTimeline}{" "}
            {this.state.selectedElm}
          </Typography>
        </BoxStyle>
        <Box>
          {this.state.projectTimeline ? (
            <>
              <Typography style={webStyle.titleStyle}>
                Start date
              </Typography>
              <Typography style={webStyle.description}>
                {this.state.dateData &&
                  this.formatDate(
                    this.state.dateData
                  )}
              </Typography>
            </>
          ) : null}
        </Box>
      </>
    )
  }

  renderDelete = () => {
    return (
      this.state.mainTitle !== "Delete post" &&
        <CustomBox
          className='discard-container'
          data-test-id='discard-container'
          onClick={this.handleDeleteModel}
        >
          <img src={`${deleteIcon}`} alt='delete icon' />
          <Typography className='discard-text'>Delete post</Typography>
        </CustomBox>
    )
  }

  renderViewProposal = () => {
    return (
      this.state.mainTitle !== "Delete post" ?
        <Typography className='discard-text' onClick={this.navigateMyWork}>View proposals</Typography>
        :
        <Typography className='discard-text' data-test-id="navigateMyWorkWithDelete" onClick={this.navigateMyWorkWithDelete}>View archived Proposals</Typography>
    )
  }

  renderDeleteModal = () => {
    return (
      <DeletePostModal
          data-test-id="deleteModel"
          open={this.state.deleteModal}
          onClose={this.handleDeleteModel}
          onDelete={this.handleShowDiscard}
          title="Delete post?"
          cardTitle={this.state.title}
          rsValue={(Number(this.state.rateData || this.state.overallRate)).toLocaleString('en-IN')}
          rsTitle={this.state.rateData ? "pr/hr" : "overall"}
          month={this.state.projectTimeline}
          weekMonth={this.state.selectedElm}
          description={this.state.description}
          postedTime={this.state.postedTime}
          descriptionBottom="Are you sure you want to delete this work post? All proposals will be archived and can be accessed in My work > deleted posts section"
          button1Text="Cancel"
          button2Text="Delete"
        />
    )
  }

  worktype = () => {
    return (
      this.state.workType &&
      <Box style={{ marginTop: "14px" }}>
        <Button
          style={{
            ...webStyle.workBtn,
          }}
        >
          {this.state.workType.charAt(0).toUpperCase() + this.state.workType.slice(1).toLowerCase()}
        </Button>
      </Box>
    )
  }

  skillTag = () => {
    return (
      this.state.skills.map(
        (skill) => {
          return (
            <Button
              key={skill.id}
              style={{ ...webStyle.btnStyle, textTransform: "none" }}
            >
              {skill.name}
            </Button>
          );
        }
      )
    )
  }

  experienceLevel = () => {
    return (
      <Typography style={webStyle.description}>
        {this.state.workExperience && this.state.workExperience.charAt(0).toUpperCase() + this.state.workExperience.slice(1).toLowerCase()}
      </Typography>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    let newLocation;
    if (this.state.multiLocationData.length > 0) {
      newLocation = this.state.multiLocationData.map((value) => {
        if (typeof value === 'string') {
          let arr = value.split(',');
          return arr[0].toLowerCase();
        }
        return '';
      });
    }
    return (
      <Box className={this.props.classes.background}>
        <ThemeProvider theme={theme}>
          <NavigationMenu {...this.props} activeTab="my work" />
          {
            this.state.isLoading ?
              this.renderBackDrop() :
          <Box className={this.props.classes.responsiveCon}>
            <Box style={webStyle.openBox}>
              <DropDownBoxTypography>
                <div style={{ height: "10px", width: "10px", borderRadius: "10px", backgroundColor: this.state.titleColor }}></div>
                <Typography style={webStyle.openTitle}>{this.state.mainTitle}</Typography>
                <Typography style={webStyle.posted}>{this.state.postedTime}</Typography>
              </DropDownBoxTypography>
                  {this.renderDelete()}
            </Box>
            <Box className={this.props.classes.revisedAllPropsCon}>
              <Box data-testid="allProposals"
                className={`${this.props.classes.allPropsCon}`}>
                <img src={leftArrowIcon} className={this.props.classes.cursonPointer} onClick={this.state.mainTitle !== "Delete post" ? this.navigateMyWork : this.navigateMyWorkWithDelete} alt="left-icon" />
                <Typography style={{ ...webStyle.title, marginLeft: '15px' }}>{this.state.title}</Typography>
              </Box>
              <CustomBox className='discard-container'>
              {this.renderViewProposal()}

              </CustomBox>
            </Box>
            <Box className={this.props.classes.middleCon}>
              <Box sx={webStyle.paper}>
                <Box>
                  <Grid container>
                    <Grid
                      item
                      xl={8}
                      lg={8}
                      md={7}
                      sm={6}
                      xs={12}
                      style={{ borderRight: "1px solid rgba(84, 84, 84, 1)" }}
                    >
                      <Box style={webStyle.paddingBox}>
                        <Typography
                          style={webStyle.descriptionTitle}
                          data-testId="workdescription"
                        >
                          {" "}
                          Work description
                        </Typography>
                        <Box style={webStyle.container}>
                          <Typography
                            style={{
                              ...webStyle.description,
                              wordWrap: "break-word",
                              overflowWrap: "break-word",
                              whiteSpace: "pre-line",
                            }}
                          >
                            {this.state.description}
                          </Typography>
                        </Box>
                      </Box>
                      <Divider style={webStyle.dividerStyle} />
                      <Box
                        style={{
                          ...webStyle.btnBox,
                          boxSizing: "border-box",
                          flexDirection: "column",
                        }}
                      >
                        <Typography style={{ ...webStyle.skillTag }}>
                          Skill tags
                        </Typography>
                        <Box
                          style={{ ...webStyle.btnContainer, flexWrap: "wrap" }}
                        >
                          {this.skillTag()}
                        </Box>
                      </Box>
                      <Divider style={webStyle.dividerStyle} />
                      <Box style={webStyle.paddingBox}>
                        <Typography style={webStyle.skillTag}>
                          Deliverables
                        </Typography>
                        <Box
                          style={{
                            ...{
                              ...webStyle.DeliverablesList,
                              ...webStyle.container,
                            },
                            maxWidth: "900px",
                            height: "auto",
                            overflow: "auto",
                          }}
                        >
                          <Box
                            style={{
                              ...{
                                ...webStyle.listItem,
                                wordWrap: "break-word",
                                overflowWrap: "break-word",
                                whiteSpace: "pre-line",
                              },
                              width: "100%",
                              minHeight: "auto",
                              maxHeight: "100%",
                              overflowY: "auto",
                            }}
                          />
                          <ul style={{ ...webStyle.deliverableUl }}>
                            {this.state.deliverables
                              .split("\n")
                              .filter((line) => line.trim() !== "")
                              .map((line, index) => (
                                <li key={index} dangerouslySetInnerHTML={{ __html: line }} />
                              ))}
                          </ul>
                        </Box>
                      </Box>
                      {(this.state.linkList.length > 0 || this.state.receivedProjectList.length > 0 || this.state.uploadedFile.length > 0) && <>
                        <Divider style={webStyle.dividerStyle} />
                        <Box style={webStyle.paddingBox}>
                          <Box>
                            <Typography style={webStyle.descriptionTitle}>
                              Project references
                            </Typography>
                            <Box
                              style={{
                                ...webStyle.DeliverablesList,
                                display: "flex",
                                flexDirection: "column",
                                gap: "10px",
                              }}
                            >
                              <Box>
                                <Box>
                                  {this.state.uploadedFile && (
                                    <>
                                      {this.state.uploadedFile.map(
                                        (fileName: string, fileIndex: number) => (
                                          <Box style={webStyle.commonBox}>
                                            <Typography style={webStyle.indexNumberStyle}>{fileIndex + 1}.</Typography>
                                            <Typography data-test-id="pdfDownload"
                                              key={fileIndex}
                                              style={{
                                                ...webStyle.listItem1,
                                                whiteSpace: "pre-wrap",
                                                wordBreak: "break-word",
                                                marginBottom: "10px"
                                              }}
                                            >
                                              {fileName}
                                            </Typography>
                                          </Box>
                                        )
                                      )}
                                    </>
                                  )}
                                </Box>
                                {this.state.linkList && (
                                  this.state.linkList.map((link, index) => (
                                    <Box style={webStyle.commonBox}>
                                      <Typography style={webStyle.indexNumberStyle}>{this.state.uploadedFile.length + index + 1}.</Typography>
                                      <Typography
                                        key={index}
                                        component={"a"}
                                        href={link.trim()}
                                        target="_blank"
                                        style={{ ...webStyle.listItem2, display: "block" }}
                                      >
                                        {link}
                                      </Typography>
                                    </Box>
                                  ))
                                )}
                                {this.state.receivedProjectList && (
                                  this.state.receivedProjectList.map((item, index) => (
                                    <Box style={webStyle.commonBox}>
                                      <Typography style={webStyle.indexNumberStyle}>{this.state.uploadedFile.length + this.state.linkList.length + index + 1}.</Typography>
                                      <Box style={webStyle.DeliverItem3}  >
                                        <Box key={item.id}>
                                          <img
                                            style={webStyle.listImg}
                                            src={configJSON.baseURL + item?.project_cover_image}
                                            alt={item?.project_title}
                                            height="281.67px"
                                            width="415.09px"
                                          />
                                          <Box
                                            style={webStyle.refContainer}
                                          >
                                            <Box>
                                              <Typography
                                                style={webStyle.imgTitle}
                                              >
                                                {item?.project_title}
                                              </Typography>
                                              <Box
                                                style={{
                                                  display: "flex",
                                                  gap: "10px",
                                                  alignItems: "center",
                                                  marginTop: "4px",
                                                }}
                                              >
                                                <Button
                                                  style={{
                                                    ...webStyle.refBtn,
                                                    textTransform: "none",
                                                    fontSize: "11px",
                                                  }}
                                                >
                                                  club
                                                </Button>
                                                <Typography
                                                  style={webStyle.imgUser}
                                                >
                                                  {item?.user_name}
                                                </Typography>
                                              </Box>
                                            </Box>
                                            <Box
                                              style={webStyle.imgeContainer}
                                            >
                                              <Box
                                                style={webStyle.imageIcon}
                                              >
                                                <Typography
                                                  style={
                                                    webStyle.iconNumber
                                                  }
                                                >
                                                  {item?.view_count}
                                                </Typography>
                                                <img
                                                  src={eyeIcon}
                                                  alt="view"
                                                  height="17.6px"
                                                  width="24.2px"
                                                />
                                              </Box>
                                              <Box
                                                style={webStyle.imageIcon}
                                              >
                                                <Typography
                                                  style={
                                                    webStyle.iconNumber
                                                  }
                                                >
                                                  {item?.like_count}
                                                </Typography>
                                                <img
                                                  src={likeIcon2}
                                                  alt="like"
                                                  height="16.5px"
                                                  width="18.7px"
                                                />
                                              </Box>
                                              <Box
                                                style={webStyle.imageIcon}
                                              >
                                                <Typography
                                                  style={
                                                    webStyle.iconNumber
                                                  }
                                                >
                                                  {item?.saved_count}
                                                </Typography>
                                                <img
                                                  src={featureIcon}
                                                  alt="save"
                                                  height="22px"
                                                  width="24px"
                                                />
                                              </Box>
                                            </Box>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Box>
                                  ))
                                )}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </>}
                    </Grid>
                    <Grid item xl={4} lg={4} sm={6} xs={12} style={{padding: "51px"}}>
                      <Box
                        style={{ ...webStyle.workBox }}
                      >
                        <Box>
                          <Typography style={webStyle.titleStyle}>
                            Work type
                          </Typography>
                          {this.worktype()}
                        </Box>
                        <Box>
                          <Box style={webStyle.location}>
                            <img src={location} />
                            <Typography style={webStyle.titleStyle}>
                              Location
                            </Typography>
                          </Box>
                          <Box
                            style={{ ...webStyle.locationContainer }}
                          >
                            {newLocation ?
                              newLocation.map((item: string) => (
                                <Button
                                  style={{ ...webStyle.btnStyle, ...webStyle.locationBtnStyle }}
                                >
                                  {item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()}
                                </Button>
                              ))
                              :
                              this.state.location &&
                              <Button
                                style={{ ...webStyle.btnStyle, ...webStyle.locationBtnStyle }}
                              >
                                {this.state.location.charAt(0).toUpperCase() + this.state.location.slice(1).toLowerCase()}
                              </Button>
                            }
                          </Box>
                        </Box>
                        <Box>
                          <Typography style={webStyle.titleStyle}>
                            Experience level
                          </Typography>
                          {this.experienceLevel()}
                        </Box>
                        <Typography style={webStyle.titleStyle}>
                          {
                            this.state.workHour
                          }{" "}
                          Hours pr/week
                        </Typography>
                        {this.renderHourMonth()}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
            {this.renderPropsDetails()}
          </Box>
          }
        </ThemeProvider>
        <ToastMSG data-test-id="toast" open={this.state.open} close={() => this.handleClose()} message={this.state.message} action={this.state.action} />
        {this.renderDeleteModal()}
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: "transparent",
    height: "100vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .circular": {
      color: "#fff"
    }
  },
  responsiveHeight: {
    marginTop: "2.5rem"
  },
  reponsiveHeightTwo: {
    marginTop: "3.5rem",
  },
  responsiveMargin: {
    marginBottom: "2rem",
  },
  gridCon: {
    margin: "1.5rem 0",
    padding: 0,
    marginLeft: "-1rem",
  },
  container: {
    width: '100%',
    backgroundColor: "#111111",
    display: "flex",
    "& *": {
      boxSizing: "border-box",
    }
  },
  background: {
    backgroundColor: '#000'
  },
  responsiveCon: {
    margin: "0 auto",
    marginTop: "50px",
    padding: "5rem 3rem",
    backgroundColor: '#000'
  },
  revisedAllPropsCon: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    marginTop: '30px',
    justifyContent: 'space-between'
  },
  allPropsCon: {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    alignSelf: "flex-start"
  },
  reviewText: {
    color: typoBlack,
  },
  workpostText: {
    margin: "2rem 0 1rem 0",
  },
  middleCon: {
    backgroundColor: "#171717",
    width: "100%",
    minHeight: "30vh",
    borderRadius: "10px",
    marginTop: '20px'
  },
  responsiveConOne: {
    padding: "3rem 3rem 2rem 3rem",
  },
  responsiveConTwo: {
    padding: "3rem",
  },
  profileCon: {
    display: "flex",
    gap: "1.5rem",
  },
  profileImageCon: {
    width: "90px",
    height: "90px",
    borderRadius: "50%",
  },
  profileImage: {
    width: "90px",
    height: "90px",
    objectFit: "cover" as "cover",
    borderRadius: "50%",
  },
  userDetailsAdjustment: {
    paddingTop: ".35rem",
  },
  userDescriptionCon: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "0.25rem",
    justifyContent: "space-between",
  },
  viewProfileText: {
    color: "#828282"
  },
  fontWeightBold: {
    fontWeight: 700,
    fontFamily: "Inter-SemiBold, sans-serif",
  },
  underline: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  cursonPointer: {
    cursor: "pointer",
  },
  shortListText: {
    letterSpacing: "0.38px",
    cursor: "pointer",
  },
  buttonCon: {
    display: "flex",
    gap: "1rem",
    alignSelf: "center",
  },
  archiveButton: {
    backgroundColor: "#0D0D0D",
    color: typoWhite,
    padding: "10px 30px",
    "&:hover": {
      backgroundColor: "#0D0D0D",
    }
  },
  offerContractButton: {
    backgroundColor: typoWhite,
    color: "#121212",
    padding: "10px 30px",
    "&:hover": {
      backgroundColor: typoWhite,
    }
  },
  profileOfferCon: {
    display: "flex",
    justifyContent: "space-between",
    gap: "2rem"
  },
  shortListButtonsCon: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "1rem",
    justifyContent: "space-between",
  },
  marginLeftAuto: {
    marginLeft: "auto",
  },
  profileConMaxWidthCon: {
    display: "flex",
    minWidth: "470px",
    flexShrink: 0,
  },
  borderCon: {
    display: 'flex',
    flexDirection: "column" as "column",
    gap: ".5rem",
    "&:not(:last-child)": {
      borderRight: `1.24px solid #545454`,
    },
    padding: "0rem 1rem",
    alignSelf: "flex-start",
  },
  profileInfoHeading: {
    color: "#7D7D7D",
  },
  greenBg: {
    backgroundColor: "#349E4B",
    borderRadius: "3px",
    padding: ".2rem .5rem",
  },
  redBg: {
    backgroundColor: "#545454",
    borderRadius: "3px",
    padding: ".2rem .5rem",
  },
  chipCon: {
    display: "flex",
    gap: ".75rem",
    flexWrap: "wrap" as "wrap",
    flexGrow: 1,
  },
  chip: {
    backgroundColor: "#1D1D1D",
    borderRadius: "24px",
    height: "26px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px 22px"
  },
  chipTwo: {
    backgroundColor: "#363636",
    width: "fit-content",
    marginBottom: "2rem"
  },
  ratePerHourText: {
    color: "#1CBAE0"
  },
  borderBottomCon: {
    borderBottom: ".97px solid #545454"
  },
  hourlyCon: {
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    marginTop: "4.5rem",
  },
  hourRateDescription: {
    color: "#545454"
  },
  overEstimationCon: {
    backgroundColor: "#202020",
    display: "flex",
    borderRadius: "11.97px",
    width: "fit-content",
    marginBottom: "3rem",
  },
  overAllEstTextCon: {
    display: "flex",
    alignItems: "center",
    padding: "1rem",
    color: "#545454",
  },
  durationHourCon: {
    display: "flex"
  },
  borderLeftCon: {
    borderLeft: "1px solid #4A4A4A"
  },
  shadowTextOne: {
    color: "#545454",
    "&:not(:last-child)": {
      borderRight: "1px solid #4A4A4A",
    }
  },
  borderTopCon: {
    borderTop: "1px solid #4A4A4A",
    display: "flex",
  },
  hourlyRateValueText: {
    color: "#1CBAE0"
  },
  startDateDesTextCon: {
    border: "1.16px solid #575656",
    borderRadius: "33px",
    width: "fit-content",
    padding: ".75px 1.25px",
  },
  filesListCon: {
    padding: 0,
    margin: 0,
    listStyleType: "none"
  },
  fileList: {
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    marginBottom: "1.5rem",
  },
  fileNameCon: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
  },
  fileCon: {
    backgroundColor: "#252525",
    display: "flex",
    alignItems: "flex-start",
    padding: ".8rem 1.25rem",
    width: "215px",
    overflow: "hidden",
    borderRadius: "10px",
  },
  countTypo: {
    width: "1rem",
    color: "#545454",
    fontWeight: 600,
  },
  collectionNameText: {
    color: "#66625C",
    marginBottom: "1rem",
  },
  collectionCon: {
    display: "flex",
    gap: "2rem",
    flexWrap: "wrap" as "wrap"
  },
  collectionCard: {
    width: "min(100%,413.84px)",
  },
  coverImage: {
    width: "100%",
    height: "285.89px",
    objectFit: "cover" as "cover",
    borderRadius: "10px",
  },
  dateTextNewCon: {
    display: "flex",
    padding: ".8rem 1.25rem",
    justifyContent: "center",
    alignItem: "center",
    border: "1.16px solid #575656",
    borderRadius: "33px",
    marginBottom: "4rem",
    width: "fit-content",
  },
  projectNameCon: {
    display: "flex",
    gap: "1rem",
    alignItems: "flex-start",
    justifyContent: "space-between",
    marginTop: "1rem",
  },
  projectName: {
    fontFamily: "Inter-SemiBold, sans-serif",
  },
  iconsCon: {
    display: "flex",
    gap: "1rem",
  },
  iconCon: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: ".25rem",
    alignItems: "center",
    "& img": {
      width: "26.59px",
      height: "18.13px",
      objectFit: "contain" as "contain",
    }
  },
  iconInfo: {
    fontSize: "0.625rem",
  },
  blueColorText: {
    color: "#528ADF",
    cursor: "pointer",
  }


};

const webStyle: Record<string, CSSProperties> = {
  title: {
    fontFamily: "Inter, sans-serif",
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '24.2px',
    color: '#FFF2E3'
  },
  openTitle: {
    fontFamily: "Inter, sans-serif",
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '19.36px',
    color: '#FFF2E3'
  },
  posted: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '14.52px',
    marginLeft: '20px',
    fontFamily: "Inter, sans-serif",
    color: "#66625C"
  },
  openBox: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  paper: {
    scrollbarWidth: "none",
    "-ms-overflow-style": "none",
  },
  paddingBox: { padding: "50px 70px 50px 70px" },
  paddingBoxNew: {
    padding: '0 70px 40px'
  },
  descriptionTitle: {
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 700,
    linehHeigth: "24.2px",
    color: "#FFF2E3",
  },
  container: { width: "100%", height: "auto", overflow: "hidden" },
  description: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    linehHeigth: "30px",
    color: "#FFF2E3",
    marginTop: "11px",
  },
  dividerStyle: {
    backgroundColor: "rgba(84, 84, 84, 1)",
  },
  btnContainer: {
    display: "flex",
    gap: "16px",
    width: "100%",
  },
  skillTag: {
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 700,
    linehHeigth: "24.2px",
    color: "#FFF2E3",
  },
  btnBox: {
    display: "flex",
    alignItems: "flex-start",
    width: "100%",
    padding: "50px 70px 50px 70px",
  },
  btnStyle: {
    fontFamily: "Inter",
    marginTop: "24px",
    backgroundColor: "rgb(50, 50, 50)",
    fontSize: "12px",
    fontWeight: 600,
    linehHeigth: "14.52px",
    color: "#FFF2E3",
    borderRadius: "21px",
    border: " 1px solid #FFF2E3",
    padding: "11px 22px 11px 22px",
    width: "max-content",
    height: "37px",
  },
  DeliverablesList: {
    marginTop: "18px",
  },
  listItem: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "30px",
    color: "#FFF2E3",
  },
  deliverableUl: {
    color: "#fff2e3",
    fontFamily: "inter",
    margin: "0px 0px 0px -15px",
    whiteSpace: "pre-wrap",
    wordWrap: "break-word"
  },
  postHistoryUl: {
    color: "#fff2e3",
    fontFamily: "inter",
    margin: "0px 0px 0px -15px",
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
    marginLeft: '20px'
  },
  workBox: {
    display: "flex",
    width: "100%",
    gap: "51px",
    flexDirection: "column"
  },
  titleStyle: {
    fontSize: "20px",
    fontWeight: 700,
    fontFamily: "Inter",
    linehHeigth: "24.2px",
    color: "#FFF2E3",
  },
  workBtn: {
    fontFamily: "Inter",
    fontSize: "12px",
    linehHeigth: "14.52px",
    color: "#FFF2E3",
    fontWeight: 400,
    borderRadius: "18.2px",
    border: " 0.5px solid #FFF2E3",
    padding: "11px 22px 11px 22px",
    height: "37px",
    textTransform: "none",
    minWidth: "142px",
  },
  location: {
    display: "flex",
    gap: "6px",
    alignItems: "center",
    marginBottom: 24
  },
  locationContainer: {
    display: "flex",
    gap: "11px",
    width: "inherit",
    paddingRight: 51,
    flexWrap: "wrap"
  },
  locationBtnStyle: {
    marginTop: 0,
    textTransform: "none"
  },
  borderBox: {
    border: '1px solid #6D6D6D',
    borderRadius: '10px',
    margin: '50px 0 0'
  },
  btnHourBox: {
    display: "flex",
    gap: "19px",
  },
  questionBox: {
    display: "flex",
    gap: "10px",
    marginTop: '15px'
  },
  priceHour: {
    fontFamily: "Inter",
    fontSize: "16px",
    linehHeigth: "19.36px",
    fontWeight: 600,
    color: "#1CBAE0",
    padding: "6px 16px 6px 16px",
    borderRadius: "24px",
    width: "145px",
    backgroundColor: "#1D1D1D",
    display: "flex",
    height: "31px",
    justifyContent: "center",
    alignItems: "center",
  },
  priceOverAll: {
    borderRadius: "24px",
    padding: "12px 16px 12px 16px",
    backgroundColor: "#1D1D1D",
    width: "145px",
    height: "31px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Inter",
    textAlign: "center",
    linehHeigth: "19.36px",
    fontSize: "16px",
    color: "#1CBAE0",
    fontWeight: 600,
  },
  month: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 600,
    linehHeigth: "19.36px",
    color: "#FFB9C7",
    padding: "6px 16px 6px 16px",
    borderRadius: "24px",
    backgroundColor: "#1D1D1D",
    width: "105px",
    height: "31px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  overallMonth: {
    display: "flex",
    padding: "12px 16px 12px 16px",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#1D1D1D",
    borderRadius: "24px",
    fontWeight: 600,
    height: "31px",
    width: "105px",
    fontSize: "16px",
    linehHeigth: "19.36px",
    color: "#FFB9C7",
    fontFamily: "Inter",
  },
  iconNumber: {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "30px",
    color: "#FFF2E3",
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  commonBox: {
    display: "flex", alignItems: "flex-start"
  },
  indexNumberStyle: {
    paddingRight: "10px", color: "#7d7d7d", fontSize: "20px"
  },
  listItem1: {
    fontFamily: "Inter",
    fontSize: "16px",
    minWidth: "215px",
    fontWeight: 400,
    lineHeight: "30px",
    color: "#FFF2E3",
    maxWidth: "max-content",
    height: "auto",
    display: "block",
    padding: "6px 15px 6px 15px",
    borderRadius: "10px",
    backgroundColor: "#333231",
    overflow: "hidden",
  },
  DeliverItem3: {
    marginTop: "10px",
    display: "flex",
    gap: "14px",
    alignItems: "start",
    marginLeft: "10px",
  },
  listImg: {
    borderRadius: "4px",
    width: "350px",
  },
  refContainer: { display: "flex", gap: "55px", marginTop: "17px" },
  listItem2: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "30px",
    color: "#3a5680",
    marginLeft: "10px",
    lineBreak: "anywhere"

  },
  imgTitle: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    linehHeigth: "24px",
    color: "#FFF2E3",
  },
  imgUser: {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 400,
    linehHeigth: "24px",
    color: "#FFF2E3",
  },
  refBtn: {
    color: "#FFF2E3",
    lineHight: "13.33px",
    fontFamily: "Inter",
    fontWeight: 600,
    padding: "4px 13px 4px 13px",
    width: "51px",
    height: "21px",
    borderRadius: "13.99px",
    marginTop: "2px",
    backgroundColor: "828181",
  },
  imgeContainer: { display: "flex", gap: "15px", alignItems: "start" },
  imageIcon: {
    display: "flex",
    justifyContent: "center",
    gap: "6px",
    alignItems: "center",
  },
  postHistoryLi: {
    display: 'flex',
    width: '50%',
    justifyContent: 'space-between'
  },
  historydate: {
    color: "#7D7D7D",
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '19.36px',
  },
  divider: {
    width: '2px',
    height: '25px',
    backgroundColor: 'rgb(255, 255, 255)',
    marginLeft: '6px',
  },
  font16Gray: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '19.36px',
    color: '#545454'
  },
  font16White: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '19.36px',
    color: '#FFF2E3'
  }
}

const DropDownBoxTypography = styled(Typography)({
  fontFamily: "Inter",
  fontSize: "24px",
  fontWeight: 600,
  lineHeight: "29.05px",
  textAlign: "left",
  color: "#FFF2E3",
  display: "flex",
  alignItems: "center",
  gap: "10px",
  "@media (max-width:960px)": {
    fontSize: "15px !important",
    fontWeight: "300px !important",
    lineHeight: "29.05px !important",
    whiteSpace: "nowrap",
  },
  "@media (min-width:720px)": {

  },

});

const CustomBox = withStyles((theme) => ({
  root: {
    '&.discard-container': {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    '&.discard-container .discard-text': {
      fontSize: '16px',
      fontFamily: 'Inter-SemiBold, sans-serif',
      lineHeight: '19.36px',
      color: '#fff2e3',
      borderBottom: '1px solid #fff2e3',
      width: 'max-content',
      marginLeft: '18px',
    },
    '& .card-container': {
      marginTop: '32px',
      rowGap: '32px',
      columnGap: '24px',
      display: 'flex',
      justifyContent: 'flex-start',
      flexWrap: 'wrap !important'
    },
    '& .horizontal-scroll': {
      justifyContent: 'flex-start !important',
      flexWrap: 'nowrap !important',
      overflowX: 'auto',
      scrollbarWidth: 'none',
      msOverflowStyle: 'none',
    },
    '& .list-no': {
      color: '#7d7d7d',
      paddingRight: '12px',
      lineHeight: '24.2px',
      fontSize: '20px',
      fontFamily: 'Inter-SemiBold, sans-serif !important',
    },
    '& .hint-text': {
      fontFamily: 'Inter, sans-serif',
      lineHeight: '16.94px',
      fontSize: '14px',
    },
    '& .card-style': {
      width: '380px',
      height: '260px',
      marginBottom: '8px',
      position: 'relative',
      borderRadius: '10px',
    },
    '& .card-label, & .card-text': {
      width: '165px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      color: '#fff2e3'
    },
    '& .card-label': {
      fontFamily: 'Inter-SemiBold, sans-serif',
      lineHeight: '16.94px',
      marginBottom: '8px',
      fontSize: '14px',
    },
    '& .card-text': {
      fontFamily: 'Inter, sans-serif',
      lineHeight: '14.52px',
      fontSize: '12px',
    },
    '& .manage-gap': {
      gap: "19px"
    },
    '& .manage-space': {
      gap: "8px"
    },
    '& .manage-space-feature, & .manage-space': {
      display: 'flex',
      fontFamily: 'Inter-SemiBold, sans-serif',
      lineHeight: '13.31px',
      color: '#fff2e3',
      fontSize: '11px',
      alignItems: 'center',
      maxHeight: '22px'
    },
  }
}))(Box);

const BoxStyle = styled(Box)({
  '@media (max-width: 1330px)': {
    flexDirection: 'column'
  },
  '@media (max-width: 1279px)': {
    flexDirection: 'row'
  },
})

const ViewWorkDetails = withStyles(styles)(ViewWorkDetail);

export default ViewWorkDetails
// Customizable Area End
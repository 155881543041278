Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.httpGetMethod = 'GET'
exports.advancedsearchApiContentType = 'application/json'
exports.getAdvancedSearchApiEndPoint = 'advanced_search/search'
exports.getCategories = "bx_block_projectportfolio/get_categories";
exports.errorTitle = 'Error';
exports.searchResultText = "Search Results";
exports.searchText = "Search";
exports.creativeFeildHeading = "Creative fields";
exports.skillsHeading = "Skills";
exports.ratingText = "Rating";
exports.rateText = "Rate";
exports.minPerHour = "Min (per hour)";
exports.maxPerHour = "Max (per hour)";
exports.toText = "to";
exports.zeroCount = 0;
exports.sortTitle = "Sort";
exports.resetButton = "Reset";
exports.tabsData = [
  {
    tabName: "Designer",
    tabId: "designer",
  },
  {
    tabName: "Projects",
    tabId: "projects",
  },
  {
    tabName: "Client",
    tabId: "client",
  },
  {
    tabName: "Inspiration ",
    tabId: "inspiration ",
  },
];
exports.ratingsData = [
  {
    count: 5,
    keyValue: "five_star"
  },
  {
    count: 4,
    keyValue: "four_star"
  },
  {
    count: 3,
    keyValue: "three_star"
  },
  {
    count: 2,
    keyValue: "two_star"
  },
  {
    count: 1,
    keyValue: "one_star"
  },
];
exports.clientSortData = [
  "Oldest to latest",
  "Latest to oldest",
  "Service Rate: Low to high",
  "Service Rate: High to low",
  "Ratings"
];
exports.designerSortData = [
  "Recommended",
  "Followers",
  "Low to high",
  "High to low"
];
exports.designerEndPoint = "bx_block_advanced_search/search/designer_search";
exports.skillsListEndPoint = "bx_block_projectportfolio/get_skills";
exports.noResultsFoundHeading = "No search results found";
exports.noResultsFoundDescription = "Please change the filters if applied or try again with another term.";
exports.searchNoResultFoundDes = "did not match any results for designer."
// Customizable Area End

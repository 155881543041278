import React from "react";

// Customizable Area Start
import { bg, logo, linkedin, editIcon } from "./assets";
import { Box, Card, CardContent, Container,styled, FormControlLabel, Grid, InputLabel, Radio, RadioGroup, TextField, Typography, withStyles, Checkbox, Button, Input, CircularProgress } from "@material-ui/core";
import Divider from '@material-ui/core/Divider';
import PlacesAutocomplete from 'react-places-autocomplete';
import { LinkedIn } from 'react-linkedin-login-oauth2';
import { Formik, Form } from 'formik';
import ToastMSG from "../../../components/src/ToastMSG.web";
import * as Yup from 'yup';
import { GoogleLogin } from '@react-oauth/google';

const CustomTextField = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            height: "48.68px",
            padding: "12.35px 10.09px",
            '& fieldset': {
                borderColor: '#252525',
                borderWidth: "1.62px",
                borderRadius: "8.11px"
            },
            '&:hover fieldset': {
                borderColor: '#252525',
                borderWidth: "1.62px",
                borderRadius: "8.11px"
            },
            '&.Mui-focused fieldset': {
                borderColor: '#252525',
                borderWidth: "1.62px",
                borderRadius: "8.11px"
            }
        },
        '& p.MuiFormHelperText-root': {
            fontSize: "14px",
            lineHeight: "16.94px",
            color: "#FF7878",
            fontFamily: "Inter, sans-serif"
        }
    },
})(TextField);

const CustomRadio = withStyles({
    root: {
        color: "#fff2e3",
        '&.MuiTypography-body1': {
            color: "#fff2e3"
        }
    },
    checked: {
        '&.Mui-checked': {
            color: "#fff2e3"
        }
    },

})(Radio);

const CustomButton = withStyles({
    root: {
        textTransform: "none"
    }
})(Button);

const CustomCheckBox = withStyles({
    root: {
        color: "#d9d9d9",
        width: "20px",
        height: "20px",
        borderRadius: "2.3px",
        border: "none"
    },
    checked: {
        '&.Mui-checked': {
            color: "#111111"
        }
    },

})(Checkbox);

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required')
        .min(2, 'Name must be at least 2 characters')
        .max(50, 'Name must be at most 50 characters')
        .matches(
            /^[a-zA-Z\s]*$/,
            'Name should contain characters only'
        ),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    username: Yup.string().required('Username is required')
        .min(2, 'Username must be at least 2 characters')
        .max(20, 'Username must be at most 20 characters'),
    password: Yup.string()
        .required('Password is required')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,15}$/,
            'Password must 8-15 characters and should contain at least one uppercase letter, one lowercase letter, one digit, and one special character'
        ),

});
// Customizable Area End

import EmailAccountRegistrationController, {
    Props
  } from "./EmailAccountRegistrationController";

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start
    handleSignupButton = () => {
        let { conditionsCheckBox, userType } = this.state;
        if (conditionsCheckBox && userType) {
            this.setState({signUpLoader:true})
            this.handleOnSignup();
        }
    };
    PlaceAutoCompleteWrapper = styled(Box)(({ theme }) => ({
        "& .placeAutoComplete": { fontFamily: 'Inter-SemiBold, sans-serif', color: '#fff2e3', fontSize: "14px", lineHeight: "16.94px" },
        "& .suggestion_box": { position: 'absolute', zIndex: 100, backgroundColor: 'rgba(0, 0, 0, 0.9)', width: '100%' },
        "& .suggestion_container": { backgroundColor: 'transparent', padding: 2 },
        "& .suggestion": { padding: "3", color: '#FFF2E3', cursor: 'pointer' }
        }));
    formData(e: React.ChangeEvent<HTMLInputElement>, setterFnc: Function) {
        this.setState({ ...this.state, [e?.target?.name]: [e?.target?.value], isSubmit: false })
        setterFnc(e)
    }
    handleSignUpClicked() {
        this.setState({ isSubmit: true });
    }
    handleAvatarLocationClicked() {
        this.setState({ isLocationAdded: true });
        this.state.address && this.handleProfileData();
    }
    renderLocationField(getInputProps: any){
        return (
            <CustomTextField
                {...getInputProps()}
                onBlur={this.handleChangeLocationTextBlur}
                onKeyDown={this.handlePressEnter}
                fullWidth
                data-test-id="location-data"
                variant='outlined'
                inputProps={
                { className: "placeAutoComplete" }
                }
                error={this.state.isLocationAdded && !this.state.address}
                helperText={this.state.isLocationAdded && !this.state.address && "Location cannot be empty"}
                />
        );
    }
    renderAvatarError(){
        if(this.state.isLocationAdded && !(this.state.avatarDefaultColor  || this.state.imageUrl)){
            return <InputLabel style={{...custombox.errorText,marginTop: "12.21px"}}>Select profile image or Background colour</InputLabel>
        }
    }
 // Customizable Area End
  render() {
        // Customizable Area Start
        // Customizable Area End
    return (
            // Customizable Area Start
            <Box sx={custombox.mainContainer} >
                <div style={this.state.isCardOpen ? { display: 'none' } : {}}>
                    <Container style={{ maxWidth: "630px" }}>
                        <Card style={custombox.root}>
                            <Formik
                                initialValues={{
                                    name: '',
                                    email: '',
                                    username: '',
                                    password: '',
                                }}
                                data-test-id="formikForm"
                                validationSchema={validationSchema}
                                onSubmit={this.handleSignupButton}
                            >
                                {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
                                    <Form data-test-id="formikFormData" noValidate onSubmit={handleSubmit} autoComplete="off" style={{ margin: '0px', marginBottom: '0px', }}>
                                        <CardContent style={{margin: '0 96.87px',paddingLeft: '0px',paddingRight: '3px'}}>
                                            <Grid container style={{alignItems:'center',flexDirection:'column'}}>
                                                <Grid item xs={12}>
                                                    <img src={logo}></img>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography style={{ fontSize: "27.55px", lineHeight: "33.35px", marginTop: "21.37px", fontFamily: "Inter-SemiBold, sans-serif", color: "#fff2e3" }}>Join us</Typography>
                                                </Grid>
                                                <Grid item xs={12} style={{ display: "flex", flexDirection: "column", gap: "10.59px", width:"433.26px", marginTop:"5.02px"}}>
                                                    <InputLabel style={custombox.inputLabel}>Name</InputLabel>
                                                    <CustomTextField
                                                        variant="outlined"
                                                        fullWidth
                                                        name="name"
                                                        value={values.name}
                                                        data-test-id="textName"
                                                        required
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.formData(e, handleChange)}
                                                        inputProps={{ style: { fontFamily: 'Inter-SemiBold, sans-serif', color: '#fff2e3', fontSize: "14px", lineHeight: "16.94px" } }}
                                                        helperText={this.state.isSubmit && errors.name}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} style={{ display: "flex", flexDirection: "column", gap: "10.59px",width:"433.26px", marginTop:"19.48px" }}>
                                                    <InputLabel style={{ ...custombox.inputLabel}}>E-mail</InputLabel>
                                                    <CustomTextField
                                                        fullWidth
                                                        name="email"
                                                        variant='outlined'
                                                        data-test-id="textEmail"
                                                        value={values.email}
                                                        required
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.formData(e, handleChange)}
                                                        inputProps={{ style: { fontFamily: 'Inter-SemiBold, sans-serif', color: '#fff2e3', fontSize: "14px", lineHeight: "16.94px" } }}
                                                        helperText={this.state.isSubmit && errors.email}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} style={{ display: "flex", flexDirection: "column", gap: "10.59px",width:"433.26px", marginTop:"19.48px"}}>
                                                    <InputLabel style={{ ...custombox.inputLabel, marginTop: '0px' }}>Username</InputLabel>
                                                    <CustomTextField
                                                        fullWidth
                                                        variant='outlined'
                                                        name="username"
                                                        data-test-id="textUsername"
                                                        value={values.username}
                                                        required
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.formData(e, handleChange)}
                                                        helperText={this.state.isSubmit && errors.username}
                                                        inputProps={{ style: { fontFamily: 'Inter-SemiBold, sans-serif', color: '#fff2e3', fontSize: "14px", lineHeight: "16.94px"} }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} style={{ display: "flex", flexDirection: "column", gap: "10.59px", width:"433.26px", marginTop:"19.48px" }}>
                                                    <InputLabel style={{ ...custombox.inputLabel}}>Password</InputLabel>
                                                    <CustomTextField
                                                        fullWidth
                                                        type="password"
                                                        variant='outlined'
                                                        data-test-id="textPassword"
                                                        name="password"
                                                        required
                                                        value={values.password}
                                                        helperText={this.state.isSubmit && errors.password}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.formData(e, handleChange)}
                                                        inputProps={{ style: { fontFamily: 'Inter-SemiBold, sans-serif', color: '#fff2e3', fontSize: "14px", lineHeight: "16.94px" } }}
                                                    />
                                                </Grid>
                                                <Grid container style={{display:"block"}}>
                                                    <Grid item xs={12} style={{ display: "flex", flexDirection: "column", gap: "5px", marginTop:"26.69px" }}>
                                                        <InputLabel style={{...custombox.inputLabel, marginBottom: "15.2px"}}>Select your profile</InputLabel>
                                                        <RadioGroup
                                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                                            name="controlled-radio-buttons-group"
                                                            row
                                                        >
                                                            <FormControlLabel
                                                                data-test-id="btnToggle1"
                                                                value="client"
                                                                control={<CustomRadio style={{ marginLeft: '20px', backgroundColor: "#FFFFFF14" }} />}
                                                                label={<span style={{fontFamily:'Inter, sans-serif', color: '#fff2e3', fontSize: "14px", lineHeight: "16.94px", marginLeft: "11px" }}>Client</span>}
                                                                onClick={() => { this.handleToggle(1) }}
                                                            />
                                                            <FormControlLabel
                                                                data-test-id="btnToggle2"
                                                                value="designer"
                                                                control={<CustomRadio style={{ marginLeft: '50px', backgroundColor: "#FFFFFF14" }} />}
                                                                label={<span style={{ fontFamily:'Inter, sans-serif',color: '#fff2e3', fontSize: "14px", lineHeight: "16.94px", marginLeft: "11px" }}>Designer</span>}
                                                                onClick={() => { this.handleToggle(2) }}
                                                            />
                                                        </RadioGroup>
                                                        {!this.state.userType && this.state.isSubmit && <p style={custombox.errorText}>This field is required</p>}
                                                    </Grid>
                                                    <Grid item xs={12} style={{marginTop:'31.04px',display: 'flex', flexDirection: "row", alignItems: 'center', gap: "17px" }}>
                                                        <Box data-test-id="TnCBox" onClick={this.handleAcceptConditions} >
                                                            <CustomCheckBox data-test-id="customCheckbox" checked={this.state.conditionsCheckBox} style={{ backgroundColor: (!this.state.conditionsCheckBox && this.state.isSubmit) ? "#FF7878": "#d9d9d9", color: (!this.state.conditionsCheckBox && this.state.isSubmit) ? "#FF7878": "", padding: "0" }} />
                                                        </Box>
                                                        <Box sx={(!this.state.conditionsCheckBox && this.state.isSubmit) ? {...custombox.typoGraphy, color: "#FF7878"}: custombox.typoGraphy}>Accept terms & conditions</Box>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginTop: '27px' }}>
                                                    <CustomButton 
                                                    disabled={this.state.signUpLoader}
                                                    onClick={() => this.handleSignUpClicked()}
                                                    data-test-id="singnUpBtnSignUp"  type="submit" variant="contained" style={{ backgroundColor: '#FFF2E3',fontFamily: 'Inter-SemiBold, sans-serif', color: '#121212', fontSize: "16px", lineHeight: "24px", letterSpacing: "0.38px", width: "120px", height: "44px"}}>
                                                     	
{this.state.signUpLoader ? <CircularProgress color='inherit' size={25} /> : "Sign up"} 
                                                    </CustomButton>
                                                </Grid>
                                                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginTop: '41px' }}>
                                                    <Box sx={custombox.typoGraphysignup}>or sign in using</Box>
                                                </Grid>
                                                <Grid item xs={12} style={{ display: "flex", justifyContent: "center", columnGap: "16.87px", marginTop: "20px" }}>
                                                <GoogleLogin
                                                    type="icon"
                                                    shape="circle"
                                                    data-testId= "googleSignUpId"
                                                    onSuccess={(credentialResponse) =>this.successMessage(credentialResponse.credential as string)}
                                                    onError={this.onFailure} 
                                                    />
                                                <LinkedInBox>
                                                     <LinkedIn
                                                        clientId="86c8f7ce9xo82s"
                                                        scope="profile openid email"
                                                        data-testId= "linkedInSignUpId"
                                                        redirectUri={`${window.location.origin}/linkedin`}
                                                        onSuccess={this.onSuccess}
                                                        onError={this.onError}
                                                    >
                                                        {({ linkedInLogin }: { linkedInLogin: () => void }) => (
                                                        <img
                                                            onClick={linkedInLogin}
                                                            src={linkedin}
                                                            alt="Sign in with Linked In"
                                                            className="linkedInImg"
                                                        />
                                                        )}
                                                    </LinkedIn>
                                                </LinkedInBox>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Form>
                                )}
                            </Formik>
                            <Divider style={{  margin: '0', border: '1px solid #252525', height: '0', marginTop: '29px' }} />
                            <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                                <Box style={{ fontFamily:'Inter, sans-serif', color: '#FFF2E3', lineHeight: "16.94px", fontSize: "14px", margin: '26px 0 25px 0' }}>
                                    Already have an account? <span data-test-id="btnLogin" style={{ cursor: 'pointer', textDecoration: 'underline', textUnderlineOffset: "3px", fontFamily:'Inter-SemiBold, sans-serif' }} onClick={this.handleLoginClick}>Log in</span>
                                </Box>
                            </Grid>
                        </Card>
                    </Container>
                </div>
                <div style={!this.state.isCardOpen ? { display: 'none' } : {}}>
                    <Container style={{ maxWidth: "630px" }}>
                        <Card style={custombox.root}>
                            <form noValidate autoComplete="off" style={{}}>
                                <CardContent style={{padding:"100px",paddingTop:"16.04px",paddingBottom:"50px"}}>
                                    <Grid container >
                                        <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                                            <img src={logo}></img>
                                        </Grid>
                                        <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                                            <Typography style={{ fontFamily:"Inter-SemiBold, sans-serif",fontSize: "27.55px", color: "#fff2e3", marginTop: "21.35px", lineHeight: "33.35px" }}>Create profile</Typography>
                                        </Grid>
                                        <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                                            <Typography style={{ fontFamily:"Inter, sans-serif",fontSize: "14px", color: "#6C6C6C", marginTop: "56.83px", lineHeight: "16.94px" }}>Add an Avatar</Typography>
                                        </Grid>
                                        <Grid item xs={12} style={{ fontFamily:"Inter, sans-serif",display: "flex", justifyContent: "center", marginTop: "27px" }}>
                                            <Box style={{
                                                width: 180,
                                                height: 180,
                                                borderRadius: '50%',
                                                border: (this.state.avatarDefaultColor  || this.state.imageUrl) ? 'none': '1px dashed #fff2e3',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                position: 'relative',
                                                backgroundImage: `url(${this.state.imageUrl})`,
                                                backgroundRepeat: "no-repeat",
                                                backgroundColor: this.state.avatarDefaultColor,
                                                backgroundSize: "cover"
                                            }}>
                                                <InputLabel style={custombox.labelFileInput} htmlFor="fileInput">
                                                <Input
                                                    data-test-id="imageUploadClick"
                                                    id="fileInput"
                                                    type="file"
                                                    hidden
                                                    inputProps={{
                                                        accept: "image/*"
                                                    }}
                                                    onChange={this.handleImageUpload}
                                                    ref={this.refElm}
                                                    style={{display: "none"}}
                                                />
                                                {!this.state.imageUrl && <CustomButton style={{ fontFamily:'Inter-SemiBold, sans-serif', borderRadius:'7px', fontSize: "16px", lineHeight: "24px", letterSpacing: "0.38px", width: "133px", height: "44px", backgroundColor: '#0d0d0d', color: "#fff2e3"

                                                }} variant="contained"
                                                type="button"
                                                onClick={() => this.handleFileSelect()}
                                                data-test-id="add-image"
                                                >
                                                    Add image
                                                </CustomButton>}
                                                {this.state.imageUrl && <div style={{position: "absolute", bottom: "7%", right: "5%", padding: "10px", borderRadius: "50%", backgroundColor: "rgb(70,66,66)"}}><img data-test-id="edit-icon" width={16} src={editIcon} alt="edit icon" /></div>}
                                                </InputLabel>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} style={{ display: "flex", justifyContent: "center", marginTop: "41px" }}>
                                            <Typography style={{ fontFamily:'Inter, sans-serif',fontSize: "14px", lineHeight: "16.94px", color: "#6C6C6C" }}>or choose from the default options</Typography>
                                        </Grid>
                                        <Grid item xs={12} style={{ display: "flex", justifyContent: "center", marginTop: "21px", gap: "30px" }}>
                                            <Box data-test-id="toggleAvatar1" onClick={(value) => { this.handleAvatarDefaultColor('#EBEBEE') }} style={{
                                                width: 62,
                                                height: 62,
                                                cursor: 'pointer',
                                                borderRadius: '50%',
                                                backgroundColor: '#EBEBEE'
                                            }}>
                                            </Box>
                                            <Box data-test-id="toggleAvatar2" onClick={(value) => { this.handleAvatarDefaultColor("#E9B0B2") }} style={{
                                                height: 62,
                                                width: 62,
                                                borderRadius: '50%',
                                                backgroundColor: '#E9B0B2',
                                                cursor: 'pointer',
                                            }}>

                                            </Box>
                                            <Box data-test-id="toggleAvatar3" onClick={(value) => { this.handleAvatarDefaultColor("#BFB0FC") }} style={{
                                                height: 62,
                                                width: 62,
                                                borderRadius: '50%',
                                                backgroundColor: '#BFB0FC',
                                                cursor: 'pointer',

                                            }}>

                                            </Box>
                                            <Box data-test-id="toggleAvatar4" onClick={(value) => { this.handleAvatarDefaultColor("#EEC4A2") }} style={{
                                                width: 62,
                                                height: 62,
                                                borderRadius: '50%',
                                                backgroundColor: '#EEC4A2',
                                                cursor: 'pointer',
                                            }}>

                                            </Box>
                                            <Box data-test-id="toggleAvatar5" onClick={(value) => { this.handleAvatarDefaultColor("#AEDAA7") }} style={{
                                                height: 62,
                                                width: 62,
                                                borderRadius: '50%',
                                                backgroundColor: ' #AEDAA7',
                                                cursor: 'pointer'
                                            }}>

                                            </Box>
                                        </Grid>
                                        {this.renderAvatarError()}
                                        <Grid item xs={12} style={{ display: "flex", position:"relative", flexDirection: "column", gap: "12.21px", marginTop: "42px" }}>
                                            <InputLabel style={custombox.inputLabel}>Location</InputLabel>
                                            <this.PlaceAutoCompleteWrapper>
                                            <PlacesAutocomplete
                                            value={this.state.address}
                                            onChange={this.handleChangeLocationText}
                                            onSelect={this.handleChangeLocation}
                                            searchOptions={{
                                            types: ['locality']
                                            }}
                                            >
                                            {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                                            <Box>
                                            {this.renderLocationField(getInputProps)}
                                            <Box data-test-id="location-description" className="autocomplete-dropdown-container suggestion_box">
                                            {suggestions.map((suggestion, index) => {
                                            return (
                                            <Box className="suggestion_container"
                                            {...getSuggestionItemProps(suggestion, { key: { index } })}
                                            >
                                            <Typography  key={index} className="suggestion">{suggestion.description}</Typography>
                                            </Box>
                                            );
                                            })}
                                            </Box>
                                            </Box>
                                            )}
                                            </PlacesAutocomplete>
                                            </this.PlaceAutoCompleteWrapper>
                                        </Grid>
                                        <Grid item xs={12} style={{ display: "flex", flexDirection: "row", marginTop: "51.11px", justifyContent: 'space-between' }}>
                                            <CustomButton style={{
                                                fontFamily:'Inter-SemiBold, sans-serif', fontSize: "16px", lineHeight: "24px", letterSpacing: "0.38px", width: "83px", height: "44px", backgroundColor: '#0d0d0d', color: "#fff2e3"

                                            }} variant="contained" color="primary"
                                                onClick={this.handleSkipBtn} type="button" data-test-id="btnSkip">
                                                Skip
                                            </CustomButton>
                                            <CustomButton variant="contained" type="button" data-test-id="btnDone" style={{ fontFamily:'Inter-SemiBold, sans-serif', width: "102px", height: "44px", fontSize: "16px", lineHeight: "24px", letterSpacing: "0.38px",backgroundColor: '#FFF2E3', color: '#121212' }} 
                                                onClick={() => this.handleAvatarLocationClicked()}>
                                                Done
                                            </CustomButton>
                                        </Grid>
                                    </Grid>
                                </CardContent>

                            </form>
                        </Card>
                    </Container>
                </div>
                <ToastMSG data-test-id="toastMessage" close={() => this.handleCloseToast()} message={this.state.message} open={this.state.open} action={this.state.action} />
            </Box>
            // Customizable Area End
    );
  }
}
// Customizable Area Start

const LinkedInBox = styled(Box)({
    "& .linkedInImg":{
        cursor: 'pointer', 
        width: "40px", 
        height: "40px"
    }
})

const custombox = {
    labelFileInput:{
        cursor:'pointer',
        fontFamily:'Inter, sans-serif',
    },
    uploadBtn:{
        color:'#fff2e3',
        fontSize: "16px",
        letterSpacing: "0.38px",
        lineHeight: "24px",
        fontFamily:'Inter-SemiBold, sans-serif',
    },
    mainContainer: {
        backgroundImage: `url(${bg})`,
        backgroundSize: "100vw",
        backgroundRepeatX: "no-repeat",
        padding:'0px 40px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1
    },
    errorText: {
        fontSize: "14px",
        lineHeight: "16.94px",
        color: "#FF7878",
        fontFamily: "Inter, sans-serif",
        marginBottom: "0",
        marginTop: "5px"
    },
    root: {
        background: 'rgb(17, 17, 17, 0.9)',
        height: "auto",
        borderRadius: "24.11px",
        overflow: "unset",
        paddingTop:"32px",
        marginTop: "30px",
        marginBottom: "30px",
        width: "630px"
    },
    inputLabel: {
        color: '#6C6C6C',
        fontFamily: 'Inter, sans-serif',
        fontSize: "14px",
        lineHeight: "16.94px"
    },
    typoGraphy: {
        fontFamily:"Inter, sans-serif",
        fontSize: "14px",
        lineHeight: "16.94px",
        textAlign: "left",
        color: "#fff"
    },
    typoGraphysignup: {
        fontSize: "14px",
        lineHeight: "16.94px",
        letterSpacing: "0em",
        color: "#fff2e3",
        fontFamily: "Inter, sans-serif"
    },
    dottedBorder: {
        border: '1px dotted #000', 
        borderRadius: '50%'

    }
};

const menuStyle= {
    display: "flex",
    alignItems: "center",
    height: "50px",
    paddingLeft: "20px",
    paadingRight: "20px",
    color: "#fff2e3",
    fontFamily: "Inter,sans-serif",
    fontSize: "16px",
    lineHeight: "19.36px"
}
const menuContainer = {
    width: "100%",
    borderRadius: "0 0 8.11px 8.11px",
    backgroundColor: "#171717",
    maxHeight: "140px",
    overflow: "auto",
    top: "94%",
    borderTop: "1px solid #555",
    zIndex: 1000
}
const suggestedData = {
    color: "#8d8d8d",
    fontFamily: "Inter,sans-serif",
    fontSize: "14px",
    lineHeight: "16.94px",
    paddingTop: "20px",
    paddingLeft: "20px",
    paddingBottom: "25px"
}
// Customizable Area End

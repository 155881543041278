export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const likeIcon = require("../../assets/likeIcon.svg");
export const eyeIcon = require("../../assets/viewIcon.svg");
export const featureIcon = require("../../assets/saveIcon.svg");
export const checkIcon = require("../assets/checkIcon.svg");
export const uncheckIcon = require("../../assets/uncheckRing.svg");
export const downloadIcon = require("../assets/download.svg");
export const downloadIconFiiled = require("../assets/downloadIconFiiled.svg");
export const profileIcon = require("../../landingpage/assets/ic_userlogo.png");


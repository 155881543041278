// Customizable Area Start
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { getStorageData,setStorageData} from "framework/src/Utilities";
export interface IListItem  {
  id: string,
  type: string,
  attributes:{
    filename: null,
    file:{
      id: number,
      url: string,
      type: string
    },
    file_format: string
  }
}
export interface Itabs {
  label:string
}
export interface ICollectionList {
  id: number | null;
  name: string,
  count: number
}

export interface IProtfolio {
  attributes :{
    account_id: number;
    background_color: string;
    Inspiration_Name: string;
    cover_image_url:string;
    description:string;
    end_date: null
    is_liked: false;
    is_saved: false;
    likes: number;
     profile_photo: string;
    project_images: any [];
   project_name: string;
    start_date: null;
    username:string;
    
  }
 
}
export const configJSON = require("../config");

export interface Props {
  id: string;
  navigation: any;
  EditProfile:boolean;
}

interface S {
  
  tabsValues:number;
  CollectionList: ICollectionList[];
  ActiveCollection:string,
  PortfolioData:any;
  itemsPerPage: number;
  inspiration_id:number;
  collection_page:number;
  anchorEl:any;
  Openmenu:boolean;
  openCollection:boolean;
  Inspiration_Name:string;
  moreBtn:boolean;
  activecard:string;
  activeBoard:string;
  InspirationModal:boolean;
  deleteModal:boolean;
  inspirationMyboard:any[];
  ProjectsCount:number;
  followStatus:boolean;
  FollowersCount:number;
  Project_Id:number|null;
  follow_id:number;
  toastStatus:boolean;
  toastMsg:string;
  toastAction:string;
  total_inspiraitions:number;
  following_page:number;
  inspName:string;
  backBtnstatus:boolean;
  backGroundFollowingColor:string;
  followingUserName:string;
  user_Role:string;
  inspirationsingledtapData:any;
  InspirationDeleteModal:boolean;
}

interface SS {
  id: any;
}

export default class InspirationController extends BlockComponent<
  Props,
  S,
  SS
> {
  GetUserInspirationApiCallId:string='';
  GetUserInspirationProjectsApiCallId='';
  DeleteCollectionApiCallId:string='';
  UpdateCollectionNameApiCallId:string ='';
  DeleteInspirationSingleProjectApiCallId:string='';
  InspirationFollowingBoardsApiCallId:string='';
  FollowingBoardUnFollowApiCallId:string='';
  baseUrlStirng:string = configJSON.baseURL.baseURL;

  

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      PortfolioData:[],
      tabsValues:0,
      ActiveCollection:"All",
      CollectionList:[],
    itemsPerPage: 3,
    inspiration_id:0,
    collection_page:0,
    anchorEl:"",
    Openmenu:false,
    openCollection:false,
    Inspiration_Name:"",
    moreBtn :false,
    activecard:"Inspirations",
    activeBoard:"My Board",
  InspirationModal:false,
  deleteModal:false,
  inspirationMyboard:[],
  ProjectsCount:0,
  followStatus:false,
  FollowersCount:0,
  Project_Id:null,
  follow_id:0,
  toastStatus:false,
  toastMsg:"",
  toastAction:'',
  total_inspiraitions:0,
  following_page:1,
  inspName:'',
  backBtnstatus:false,
  backGroundFollowingColor:'',
  followingUserName:'',
  user_Role:'',
  inspirationsingledtapData:null,
  InspirationDeleteModal:false

    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    const apiRequestCallId1 = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson1 = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId1 && responseJson1) {
      this.InspirationsApi(apiRequestCallId1, responseJson1)
      
    }
  }


  handleUpdateCollectionName =(event:any)=>{
    this.setState({inspName:event.target.value})
  }

  async componentDidMount() {
    this.handleInspirations()
    
  }

  handleCloseProfile = () => {
    this.setState({ openCollection: false });
  };

  handleCloseListMenu =()=>{
    this.setState({  anchorEl: null,Openmenu: false,})
  }
  handleOpenListMenu = (event:any) =>{
    this.setState({ anchorEl: event.currentTarget,
      Openmenu: true,})
  }
  handleRenameInspiration=()=>{
   
    this.setState({ openCollection: true });
  }
  hanleupdateInspirationName =async () =>{
    const tokens = await getStorageData("userInfo");   
    let newToken = JSON.parse(tokens)
    const {meta: { token } } = newToken;
    const raw = JSON.stringify({
      "data": {
        "attributes": {
          "name": this.state.inspName
        }
      }
    });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token":token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.UpdateCollectionNameApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateInspirationProfilename+this.state.inspiration_id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      raw
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
   
  }
  handleDeleteInspiration =async()=>{
    const tokens = await getStorageData("userInfo");   
    let newToken = JSON.parse(tokens)
    const {meta: { token } } = newToken;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token":token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.DeleteCollectionApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.DeleteInspirationProfile+this.state.inspiration_id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
    
  }

  handleactiveBoard =(active:string)=>{
    this.setState({activeBoard:active,InspirationModal:false,inspirationMyboard:[],following_page:1})
    if (active == 'My Board') {
      this.handleInspirations()
    }
    if (active == 'Following Boards') {
      this.handleFollowingBoard()  
    }
  }

  handleFollowingBoard = async() =>{
    const tokens = await getStorageData("userInfo");  
    let newToken = JSON.parse(tokens)
    const {meta: { token,id} } = newToken;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token":token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.InspirationFollowingBoardsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.InspirationFollowingBoards+`page=${this.state.following_page}&per_page=8&account_id=${this.props.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleFollowingCount =() =>{
    this.setState({following_page:this.state.following_page+1})
    this.handleFollowingBoard()
  }
  handleUnfollow =async(id:number)=>{
    const tokens = await getStorageData("userInfo");  
    let newToken = JSON.parse(tokens)
    const {meta: { token} } = newToken;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token":token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.FollowingBoardUnFollowApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.FollowingboardUnFollow+id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleInspirations=async()=>{
    const tokens = await getStorageData("userInfo");   
    let newToken = JSON.parse(tokens)
    const {meta: { token ,id} } = newToken;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token":token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetUserInspirationApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.InspirationUserDetails+this.props.id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleInspirationsProject =async(data:any) =>{
    this.setState({inspirationsingledtapData:data})
    this.setState({inspiration_id:data.id,inspName:data.name,user_Role:data.user_role,backGroundFollowingColor:data.profile_background_color,followingUserName:data.user_name})
    const tokens = await getStorageData("userInfo");   
    let newToken = JSON.parse(tokens)
    const {meta: { token } } = newToken;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token":token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetUserInspirationProjectsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.InspirationUserProjects+data.id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleCloseInspirationModal =() =>{
    
    this.setState({InspirationModal:false})
    this.handleInspirations()
  }

  handleCloseFollowingModal =() =>{
    this.setState({InspirationModal:false,backBtnstatus:true,following_page:1})
    this.handleFollowingBoard()
  }
  handlePortfolioName =() =>{
    const inspirationname = this.state.inspName ? this.state.inspName : "No Name"
    return inspirationname;
  }
  handleCloseDeleteModal = () =>{
    this.setState({deleteModal:false,InspirationDeleteModal:false,Project_Id:null})

  }
  handledeletesingleproject =(data:any) =>{
    this.setState({deleteModal:true,Project_Id:data.id})
   
  }
  hanledeleteMyBoardProject = async ()=>{
     const tokens = await getStorageData("userInfo");   
    let newToken = JSON.parse(tokens)
    const {meta: { token } } = newToken;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token":token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.DeleteInspirationSingleProjectApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.DeleteInspirationSingleProject+`id=${this.state.inspiration_id}&project_id=${this.state.Project_Id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }
  
  InspirationsApi=(apicallId:string,response:any)=>{
    if (apicallId === this.GetUserInspirationApiCallId){
      this.setState({inspirationMyboard:response.data})
    }

    if (apicallId === this.GetUserInspirationProjectsApiCallId){
      if(!response.error) {    
        this.setState({InspirationModal:true,
          PortfolioData:response.data.attributes,
          follow_id:response.meta.follow_id,
          FollowersCount:response.meta.follwers_count,
          followStatus:response.meta.is_followed,
          ProjectsCount:response.meta.project_count,inspName:response.data.attributes.name})
      }
      else {
        this.setState({InspirationModal:true,PortfolioData:response.error,ProjectsCount:0})
      }

    }

    
    this.InspirationFollowingBoardsApi(apicallId,response)
    this.handledeleteandupdateApicallId(apicallId,response)
    this.handleUnFollowFollowingBoards(apicallId,response)

    if (apicallId === this.DeleteCollectionApiCallId) {
      if (!response.error) {
        this.setState({InspirationModal:false})
        this.handleCloseListMenu()
        this.handleInspirations()
        this.handleCloseDeleteModal()
    }
  }

  }
  InspirationFollowingBoardsApi =(apicallId:string,response:any) =>{
    if (apicallId  == this.InspirationFollowingBoardsApiCallId) {
      if (!response.errors) { 
       if (!this.state.backBtnstatus) {
        this.setState(prevState => ({
          inspirationMyboard: [...prevState.inspirationMyboard, ...response.data],
          total_inspiraitions:response.meta.total_inspiraitions 
      }));
      }else {
         this.setState({inspirationMyboard:response.data,total_inspiraitions:response.meta.total_inspiraitions,backBtnstatus:false,following_page:1})

       }
        
      } else {
        this.setState({inspirationMyboard:[],total_inspiraitions:0})
      }
    }
  }
  handleNavigation =() =>{
   
    let path =  "PublicUserProfile"
    let localdata = {
      account_id: this.state.PortfolioData.account_id,
      user_role : this.state.user_Role
    }
      if (path == "PublicUserProfile") {
        setStorageData("PublicId",JSON.stringify(localdata));
      }
      
      const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), path);
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
  }


  handleUnFollowFollowingBoards =(apicallId:string,response:any)=>{
    if (apicallId === this.FollowingBoardUnFollowApiCallId){
      
      if (!response.errors) {
        this.setState({followStatus:false,toastStatus:true,toastAction:"success",toastMsg:`sucessfully unfollowed`})
      }else {
        this.setState({toastStatus:true,toastAction:"danger",toastMsg:`${response.errors[0].message}`})
      }
    }

  }

  handledeleteandupdateApicallId =(apicallId:string,response:any)=>{
    if (apicallId === this.DeleteInspirationSingleProjectApiCallId) {
      if (!response.error) {
        this.handleInspirationsProject(this.state.inspirationsingledtapData)
        this.setState({deleteModal:false,toastStatus:true,toastAction:"success",toastMsg:"sucessfully deleted"})
      }else {
        this.setState({deleteModal:false,toastStatus:true,toastAction:"danger",toastMsg:`${response.error[0]}`})
      }
  }

  if (apicallId === this.UpdateCollectionNameApiCallId) {
    if (!response.error) {
      this.setState({toastStatus:true,toastAction:"success",toastMsg:"sucessfully Updated"})
      this.handleCloseProfile()
      this.handleCloseListMenu()
  }
  }
  }

  handleToastClose = () => {
    this.setState({ toastStatus: false });
  }
}

// Customizable Area End

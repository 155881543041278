// Customizable Area Start
import React from "react";
import { BlockComponent } from "../../framework/src/BlockComponent";

const dropIcon = require("../../blocks/assets/solidDropIcon.svg");
const dropUp = require("../../blocks/assets/dropUpIcon.svg");
const checkIcon = require("../../blocks/assets/checkIcon.svg");
const unCheckIcon = require("../../blocks/assets/uncheckIcon.svg");

interface MultiSelectProps {
    data: RowInt[];
    selected: RowInt[];
    onChange: Function;
    toggleState: Function;
    title: string;
    open: boolean;
    singleColumn?: boolean;
}

interface RowInt {
    id: string | number,
    name: string
}

interface S {
    selectedData: RowInt[]
}

interface SS {
    id: string;
}
import { StyleSheet } from 'react-native';
import { Box, styled } from "@material-ui/core";
import { Button } from "@mui/material";

const styles = StyleSheet.create({
    text: {
        color: "#fff2e3", 
        fontFamily: "Inter, sans-serif", 
        fontWeight: "normal", 
        fontSize: 14, 
        lineHeight: 16.94
    }
});

const stylesWeb = {
  'checkbox-elm': {
    cursor: 'pointer',
  },
  blockContainer: {
    display: 'block !important',
    flexWrap: 'wrap',
    maxHeight: '420px',
    overflowY: 'auto',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    borderRadius: '0 0 14.15px 14.15px',
    backgroundColor: '#111111',
    border: '0.71px solid #434343',
    borderTop: 'none',
    '& .menuTexContainer': {
      width: '100% !important',
      fontFamily: "Inter, sans-serif",
    },
    '& .menuItem': {
      paddingLeft: '37px !important',
      paddingRight: '23px !important',
    },
    '& .menuItem:last-child': {
      paddingBottom: '34px',
    },
    '::-webkit-scrollbar': {
      width: 0,
      height: 0,
    }
  },
  flexContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    maxHeight: '420px',
    overflowY: 'auto',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    borderRadius: '0 0 14.15px 14.15px',
    backgroundColor: '#111111',
    border: '0.71px solid #434343',
    borderTop: 'none',
    '& .menuItem': {
      width: '35%'
    },
    '& .menuItem:nth-child(odd)': {
      paddingLeft: '37px',
      paddingRight: '23px',
      borderRight: '0.33px solid #656565',
      ':last-child': {
        paddingBottom: '34px',
      }
    },
    '& .menuItem:nth-child(even)': {
      paddingLeft: '44px',
      paddingRight: '33px',
      ':last-child': {
        paddingBottom: '34px',
      }
    },
    '::-webkit-scrollbar': {
      width: 0,
      height: 0,
    }
  },
  menuItem: {
    fontFamily: 'Inter, sans-serif !important',
    fontSize: '14px !important',
    lineHeight: '16.94px !important',
    paddingTop: '18px !important',
    backgroundColor: 'transparent !important',
  },
};

const SelectButton = styled('button')({
  width: '145px',
  height: '37px',
  borderRadius: '6px',
  border: '1px solid #66625c !important',
  color: '#fff2e3',
  fontSize: '14px',
  lineHeight: '16.94px',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'transparent',
  columnGap: '8px',
  justifyContent: 'center',
  fontFamily: 'Inter, sans-serif',
  cursor: 'pointer',
  '&.hide-btn-elm': {
    display: 'none',
    border: '0 !important',
  },
});

export default class MultiSelect extends BlockComponent<MultiSelectProps, S, SS> {
  constructor(props: MultiSelectProps) {
    super(props);
    this.state = {
      selectedData: []
    }
  }

  async componentDidMount() {
    this.setState({ selectedData: [...this.props.selected] });
  }

  handleChange(rowData: RowInt, event?: React.MouseEvent<HTMLButtonElement, MouseEvent> ) {
    let storedData: RowInt[] = [...this.state.selectedData];
    let dataExist = storedData.find((item) => item.id === rowData.id);
    if (dataExist) {
      storedData = storedData.filter((item: RowInt) => item.id !== dataExist?.id);
    } else {
      storedData = [...storedData, { ...rowData }];
    }
    this.setState({ selectedData: [...storedData] });
    if (event) {
      event.stopPropagation();
  }
  }

  applyChanges(event:React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    this.props.onChange(this.state.selectedData);
    this.props.toggleState(event);
  }

  toggleMenu(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    this.props.toggleState(event);
    this.setState({ selectedData: [...this.props.selected] })
  }

  handleDisabled = () => {
    let enableBtn = false;
    if (!this.state.selectedData.length && !this.props.selected.length) {
      enableBtn = false;
      return enableBtn;
    }
    if (this.state.selectedData.length !== this.props.selected.length) {
      enableBtn = true;
      return enableBtn;
    }
    for (let startIndex = 0; startIndex < this.state.selectedData.length; startIndex += 1) {
      const matchedData = this.props.selected.find(item1 => item1.id === this.state.selectedData[startIndex].id);
      if (!matchedData) {
        enableBtn = true;
        break;
      }
    }
    return enableBtn;
  }

  handleSelected(row: RowInt) {
    const isSelected = this.state.selectedData.find(selectedList => selectedList.id === row.id);
    return isSelected ? true : false
  }

  handleEvent(event?: React.MouseEvent<HTMLElement, MouseEvent> ) {
    if (event) {
      event.stopPropagation();
  }
  }

  render() {
    return (
      <Box
        className="multi-select-container"
        sx={{
          position: 'relative',
        }}
      >                
        <Box>
          <SelectButton data-test-id="toggleButtonFirst" className={`select-btn ${this.props.open ? 'hide-btn-elm' : ""}`} type="button" onClick={(event) => this.toggleMenu(event)}>{this.props.title} <img src={dropIcon} alt="dropdown icon" /> </SelectButton>
        </Box>
        <Box onClick={(event)=>this.handleEvent(event)}
          className={`option-container ${this.props.open ? 'open-menu' : 'hide-btn'}`}
          sx={{
            position: 'absolute',
            left: 0,
            top: 0,
            maxWidth: '464px',
            minWidth: '320px',
            borderRadius: '14.15px',
            zIndex: 12,
            paddingBottom: '50px',
            width: this.props.data.length > 1 && !this.props.singleColumn ? '464px' : 'auto',
            display: this.props.open ? 'block' : 'none', 
          }}
        >
          <Box
            className="btn-container"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '12px 21px 12px 31px',
              borderRadius: '14.15px 14.15px 0 0',
              bgcolor: '#111111',
              border: '0.71px solid #434343',
              borderBottom: '0.33px solid #656565',
            }}
          >
            <Button
              data-test-id="toggleButtonSecond"
              onClick={(event) => this.toggleMenu(event)}
              disableRipple={true}
              className="text-btn"
              sx={{
                backgroundColor: 'transparent',
                border: 'none',
                color: '#fff2e3',
                display: 'flex',
                alignItems: 'center',
                columnGap: '8px',
                fontFamily: 'Inter-SemiBold, sans-serif',
                lineHeight: '16.94px',
                fontSize: '16px',
                textTransform: 'none',
              }}
            >
              {this.props.title} <img src={dropUp} alt="dropdown icon" />
            </Button>
            <Button
              data-test-id="applyButton"
              disabled={!this.handleDisabled()}
              onClick={(event) => this.applyChanges(event)}
              className={`apply-btn ${this.handleDisabled() ? "active-btn" : ""}`}
              sx={{
                width: '106px',
                height: '44px',
                borderRadius: '7px',
                backgroundColor: '#7d7d7d',
                lineHeight: '24px',
                letterSpacing: '0.38px',
                fontFamily: 'Inter-Medium, sans-serif',
                fontSize: '16px',
                color: '#121212 !important',
                border: 'none',
                textTransform: 'none',
                '&.active-btn': {
                  backgroundColor: '#fff2e3',
                },
              }}
            >
              Apply
            </Button>
          </Box>
          <Box
  className={`${this.props.data.length > 1 && !this.props.singleColumn ? "flex-container" : "block-container"}`}
  sx={{...this.props.data.length > 1 && !this.props.singleColumn ? stylesWeb.flexContainer : stylesWeb.blockContainer}}
>
            {this.props.data.map((list: RowInt) => (
              <Box className="menuItem" key={list.id} sx={stylesWeb.menuItem}>
                <Button
                  className="menuTexContainer checkbox-elm"
                  onClick={(event:React.MouseEvent<HTMLButtonElement, MouseEvent>) => this.handleChange(list, event)}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: this.props.singleColumn ? '100%' : '160px',
                    columnGap: '11px',
                    textAlign: 'left',
                    color: '#fff2e3',
                    ':hover':{
                      backgroundColor: 'transparent',
                    },
                    '& span': {
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textTransform: 'none',
                    },
                    '&:first-child': {
                      margin: 0,
                      padding: 0,
                      justifyContent: 'flex-start',
                    },
                    '& > div:first-child > div:first-child > div:first-child': {
                      height: '16px !important',
                      width: '16px !important',
                      borderRadius: '4px !important',
                      border: '0.5px solid #fff2e3 !important',
                    },
                    '& > div:first-child': {
                      padding: '0 !important',
                      margin: '0 !important',
                    },
                    '&.menuTexContainer-checked > div:first-child > div:first-child > div:first-child': {
                      height: '14px !important',
                      width: '14px !important',
                      borderRadius: '4px !important',
                      border: '1px solid #fff2e3 !important',
                      backgroundImage: 'linear-gradient(224.84deg, #FFC29F 0%, #FFB9C7 16.15%, #CF8CCC 33.85%, #AB9EF5 50.52%, #6C98EE 67.71%, #1CBAE0 83.85%, #A8E5EC 100%) !important',
                    },
                    '&.menuTexContainer-checked > div:first-child > div:first-child > div:first-child > div:first-child': {
                      width: '11px !important',
                      height: '9px !important',
                    },
                    '&.menuTexContainer-checked > div:first-child > div:first-child > div:first-child > div:first-child > img': {
                      display: 'none !important'
                    },
                    '&.menuTexContainer-checked > div:first-child > div:first-child > div:first-child > div:first-child > div': {
                      backgroundImage: 'url(\'../../blocks/assets/checkMark.png\') !important'
                    },
                    ...stylesWeb['checkbox-elm'],
                  }}
                >
                  <img
                    src={`${this.handleSelected(list) ? checkIcon : unCheckIcon}`}
                    width="16"
                    height="16"
                    alt="checkbox element"
                    className="checkbox-elm"
                    style={{
                      ...stylesWeb['checkbox-elm']
                    }}
                  />
                  <span title={list.name}>{list.name}</span>
                </Button>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    );
  }
}
// Customizable Area End
export const storybox = require("../../assets/story-box.png");
export const plusicon = require("../../assets/plus.png");
export const chatIcon = require("../../assets/chat.png");
export const activeChatIcon = require("../../assets/activeChat.png");
export const userIcon = require("../../assets/user.png");
export const activeUserIcon = require("../../assets/activeUser.png");
export const notificationIcon = require("../../assets/notification.png");
export const activeNotificationIcon = require("../../assets/activeNotification.png");
export const logoIcon = require("../../assets/headerLogo.png");
export const searchIcon = require("../../assets/search.png");
export const closeIcon = require("../../assets/closeIcon.svg");
export const uploadIcon = require("../../assets/upload.png");
export const imgIcon = require("../../assets/imgIcon.png");
export const likeIcon = require("../../assets/likeIcon.svg");
export const likeIcon2 = require("../../assets/likeIcon.png");
export const eyeIcon = require("../../assets/viewIcon.svg");
export const featureIcon = require("../../assets/saveIcon.svg");
export const featureIcon2 = require("../../assets/featureIcon.png");
export const showMoreIcon = require("../../assets/viewMoreIcon.svg");
export const dropIcon = require("../../assets/solidDropIcon.svg");
export const dropUp = require("../../assets/dropup.png");
export const checkIcon = require("../../assets/check.png");
export const unCheckIcon = require("../../assets/uncheck.png");
export const viewIcon = require("../../assets/viewIcon.png");
export const likedIcon = require("../../assets/likedIcon.png");
export const profileIcon = require("../assets/ic_userlogo.png");
export const arrowIcon = require("../../assets/arrow.png");
export const dummyImg = require("../../assets/dummy.png");
export const leftArrowNew = require("../../assets/leftArrowNew.png");
export const rightArrow = require("../../assets/rightArrow.png");
export const calender = require("../../assets/calender.png");
export const userProfile = require("../../assets/userProfileImg.png");
export const followIcon = require("../../assets/followImg.svg");
export const followingIcon = require("../../assets/followingImg.svg");
export const deleteIcon = require("../../assets/deleteIcon.svg");
export const infoIcon = require("../../assets/infoIcon.svg");
export const solidShareIcon = require("../../assets/solidShare.svg");
export const backBtn = require("../../assets/rightArrow.png");
export const AddInspirationIcon = require("../../assets/addInspiration.png");
export const fillLikeIcon = require("../../assets/fillViews.png");
export const faceBook = require("../../assets/Facebook_Logo.png");
export const gmail = require("../../assets/Gmail_Logo.png");
export const inst = require("../../assets/Instagram_logo.png");
export const whatsapp = require("../../assets/whatsapp_logo.png");
export const twitter = require("../../assets/twitter-logo.png");
export const location = require("../assets/location.png");
export const bookImg = require("../assets/book.png");
export const fbIcon = require("../assets/fbicon.png")
export const igIcon = require("../assets/idicon.png")
export const linkedinIcon = require("../assets/linkedinicon.png")
export const mainLogo = require("../assets/mainlogo.png")
export const subLogo = require("../assets/sublogo.png")
export const twitterIcon = require("../assets/twittericon.png")
export const editIcon = require("../assets/editIconNew.svg");
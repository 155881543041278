import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import dayjs, { Dayjs } from "dayjs";
import { getStorageData } from "framework/src/Utilities";

export const configJSON = require("./config");
let tokenData = "";

type DateInput = string | number | Date | Dayjs | null;

export interface ContractDetail {
  status?: string
  deadline?: string
  proposal?: ContractDetailProposal
  contract?: ContractDetailContract
}

export interface ContractDetailProposal {
  data?: ContractDetailProposalData
}

export interface ContractDetailProposalData {
  id: string
  type: string
  attributes: ContractDetailProposalDataAttributes
}

export interface ContractDetailProposalDataAttributes {
  id: number
  created_at: string
  updated_at: string
  service_rate: number
  give_your_pitch: string
  profile_photo: string
  background_color: string
  location: string
  user_name: string
  likes_count: number
  view_count: number
  rating: number
  received_time_ago: string
}

export interface ContractDetailContract {
  data?: ContractDetailContractData
}

export interface ContractDetailContractData {
  id: string
  type: string
  attributes: ContractDetailContractDataAttributes
}

export interface ContractDetailContractDataAttributes {
  id: number
  proposal_id: number
  account_id: number
  terms_and_condition_accept: boolean
  decline_contract: boolean
  project_timeline: number
  overall_budget: number
  created_at: string
  updated_at: string
  contract_status?: ContractDetailContractDataAttributesContractStatus
  milestones: ContractDetailContractDataAttributesMilestones
  contract_sent_on_date: string
  take_action_until: string
  edit_request_n_date: string
  awaing_action_till: string
}

export interface ContractDetailContractDataAttributesContractStatus {
  id: number
  receiver_id: number
  status: string
  contract_id: number
  created_at: string
  updated_at: string
}

export interface ContractDetailContractDataAttributesMilestones {
  data: ContractDetailContractDataAttributesMilestonesData[]
}

export interface ContractDetailContractDataAttributesMilestonesData {
  // deliverables: ContractDetailContractDataAttributesMilestonesDataAttributesDeliverables;
  id: string
  type: string
  attributes: ContractDetailContractDataAttributesMilestonesDataAttributes
}

export interface ContractDetailContractDataAttributesMilestonesDataAttributes {
  id: number
  contract_id: number
  name: string
  description: string
  amount: number
  due_date: string
  client_approval: boolean
  deliverables: ContractDetailContractDataAttributesMilestonesDataAttributesDeliverables
  edits?: ContractDetailContractDataAttributesMilestonesDataAttributesEdits | null
}

export interface ContractDetailContractDataAttributesMilestonesDataAttributesDeliverables {
  data: ContractDetailContractDataAttributesMilestonesDataAttributesDeliverablesData[]
}

export interface ContractDetailContractDataAttributesMilestonesDataAttributesDeliverablesData {
  id: string
  type: string
  attributes: ContractDetailContractDataAttributesMilestonesDataAttributesDeliverablesDataAttributes
}

export interface ContractDetailContractDataAttributesMilestonesDataAttributesDeliverablesDataAttributes {
  deliverable: string
}

export interface ContractDetailContractDataAttributesMilestonesDataAttributesEdits {
  data: ContractDetailContractDataAttributesMilestonesDataAttributesEditsData
}

export interface ContractDetailContractDataAttributesMilestonesDataAttributesEditsData {
  id: string
  type: string
  attributes: ContractDetailContractDataAttributesMilestonesDataAttributesEditsDataAttributes
}

export interface ContractDetailContractDataAttributesMilestonesDataAttributesEditsDataAttributes {
  id: number
  name: string
  description: string
  amount: number
  due_date: string
  contract_milestone_id: number
  edit_deliverables: ContractDetailContractDataAttributesMilestonesDataAttributesEditsDataAttributesEditDeliverables
}

export interface ContractDetailContractDataAttributesMilestonesDataAttributesEditsDataAttributesEditDeliverables {
  data: ContractDetailContractDataAttributesMilestonesDataAttributesEditsDataAttributesEditDeliverablesData[]
}

export interface ContractDetailContractDataAttributesMilestonesDataAttributesEditsDataAttributesEditDeliverablesData {
  id: string
  type: string
  attributes: ContractDetailContractDataAttributesMilestonesDataAttributesEditsDataAttributesEditDeliverablesDataAttributes
}

export interface ContractDetailContractDataAttributesMilestonesDataAttributesEditsDataAttributesEditDeliverablesDataAttributes {
  deliverable_id: number
  deliverable: string
}

interface IdMap {
  [key: string]: number;
};
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  cardId: string;
  dropDownId: number;
  // Customizable Area End
}

interface S {
  // Customizable Area Start  
  contentType: string;
  activeId: string;
  SelectedDropDownID:number;
  contractAllDetails: ContractDetail
  contractDtailsProposal: ContractDetailProposal
  contractDtailsContract: ContractDetailContract
  mileStoneExpandData: { 
    expandedTerms: IdMap 
  },
  moreMilestone: boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class DraftOpportunityController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  cardid = this.props.cardId;
  apiWorkOpportunityContract : string = ''
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      contentType: "awaiting",
      activeId: "",
      SelectedDropDownID:2,
      contractAllDetails: {},
      contractDtailsProposal: {},
      contractDtailsContract: {},
      mileStoneExpandData: {
        expandedTerms: {}
      },
      moreMilestone: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      // code here
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiWorkOpportunityContract) {
          this.handleWorkOpportunity(responseJson);
        } 
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    let userInfo = await getStorageData("userInfo");
    let storeData = JSON.parse(userInfo);
    if (userInfo) {
      tokenData = storeData.meta.token;
    }
    this.getContarctData()
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (this.props.cardId !== prevProps.cardId) {
      this.cardid = this.props.cardId;
      if (this.props.cardId) {
        this.getContarctData();
      }
    }
  }

  handleWorkOpportunity = (response: ContractDetail) => {
    if (response.proposal && response.contract) {
      this.setState({
        contractAllDetails: response,
        contractDtailsProposal: response.proposal,
        contractDtailsContract: response.contract,
      })
    }
  }

  navigateViewWork = () => {
    this.props.navigation.navigate("ViewWorkDetail",{id: this.props.cardId, dropdownId: this.props.dropDownId})
  }

  formatDateShort = (dateString: DateInput) => {
    if (dateString) {
      const date = dayjs.isDayjs(dateString) ? dateString.toDate() : new Date(dateString);
      const options: Intl.DateTimeFormatOptions = { year: '2-digit', month: '2-digit', day: '2-digit' };
      return date.toLocaleDateString(undefined, options);
    }
  }

  displayAllProposal = () => {
    this.setState({
      contentType: 'proposalList'
    })
  }

  displayAwaiting = () => {
    this.setState({
      contentType: 'awaiting'
    })
  }

  toggleTerm = (index: number) => {
    this.setState((prevState) => ({
      mileStoneExpandData: {
        ...prevState.mileStoneExpandData,
        expandedTerms: {
          ...prevState.mileStoneExpandData.expandedTerms,
          [index]: !prevState.mileStoneExpandData.expandedTerms[index],
        }
      }
    }));
  };

  checkMoreMilestone = () => {
    this.setState({ moreMilestone: true })
  }

  getContarctData = () => {
    const headerObj = {
      "token": tokenData,
    };
    const headerData = JSON.stringify(headerObj);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiWorkOpportunityContract = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.workOpportunityContactApiEndPoint + this.props.cardId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headerData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getcompanyApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
import React, { useCallback, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import "./multiRangeSlider.css";
import { Slider, Box, Typography } from "@mui/material";  

interface MultiRangeSliderProps {
  min: number;
  max: number;
  valueMin:number;
  valueMax:number;
  onChange: ({ min, max }: { min: number; max: number }) => void;
}

const MultiRangeSlider: React.FC<MultiRangeSliderProps> = ({ min, max, onChange ,valueMin,valueMax}) => {
  const [minVal, setMinVal] = useState<number>(valueMin);
  const [maxVal, setMaxVal] = useState<number>(valueMax);

  const handleChange = (event: Event, newValue: number | number[]) => {
    const [newMin, newMax] = newValue as number[];
    setMinVal(newMin);
    setMaxVal(newMax);
  };
  useEffect(() => {
    onChange({ min: minVal, max: maxVal });
  }, [minVal, maxVal, onChange]);  
  return (
    <Box sx={{ width: 800, 
      marginLeft:'50px',
      marginTop:'-12px',
    }}>
      {" "}
      <Slider
        value={[minVal, maxVal]}
        onChange={handleChange}
        min={min}
        max={max}
        valueLabelDisplay="auto"
        disableSwap
        sx={{
          "& .MuiSlider-thumb": {
            background: '#fff2e3',
            height: 15,
            width:15,
            "&:hover": { boxShadow: "0 0 0 8px rgba(58, 133, 137, 0.16)" },
            "& .airbnb-bar": {
              height: 7,
              width: 1,
              backgroundColor: "currentColor",
              marginLeft: 1,
              marginRight: 1,
            },
          },
          "& .MuiSlider-rail": { height: 6, 
            background: '#3d3b3b'
          },
          "& .MuiSlider-track": { height: 6, 
            background: 'linear-gradient(224.84deg, #ffc29f, #ffb9c7 16.15%, #cf8ccc 33.85%, #ab9ef5 50.52%, #6c98ee 67.71%, #1cbae0 83.85%, #a8e5ec)', },
        }}
      />{" "}
      <Box display="flex" 
      sx={{gap: 25, marginTop:"10px",marginBottom:"-80px", marginLeft:10
      }}>  
      <Box>
        <Typography gutterBottom sx={{color:"#797979", fontSize:12}}>Min (per hour) </Typography>
        <Typography>{minVal}</Typography></Box>
      
      <Typography gutterBottom sx={{color:"#797979", fontSize:12}}>to</Typography>
      <Box>
        <Typography gutterBottom sx={{color:"#797979", fontSize:12}}>Max (per hour) </Typography>
        <Typography>{maxVal}</Typography></Box>
            </Box>
    
    </Box>
  );
};

MultiRangeSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
};

export default MultiRangeSlider;



import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import StorageProvider from "../../../framework/src/StorageProvider";

// Customizable Area Start
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
interface AuctionList {
  id?: string,
  type?: string,
  attributes: {
    product_name?: string;
    description?: string;
    start_time?: Date;
    end_time?: Date;
    minimum_bid?: number;
    maximum_bid?: number;
    status?: string;
    product_images:[
      {
        id: number,
        url: string
      }
    ];
    maximum_bid_placed?: number;
    time_remaining?: string;
  }
}
// Customizable Area End

interface S {
  // Customizable Area Start
  selectedTab : number;
  live_auction: Array<AuctionList>,
  upcoming_auction: Array<AuctionList>
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AuctionBiddingController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    loginApiCallId : string = "";
    getLiveAuctionCallId : string = "";
    getUpcommingAuctionCallId : string = "";
    // Customizable Area End
  
    constructor(props: Props) {
      super(props);
      this.receive = this.receive.bind(this);
  
      // Customizable Area Start
      // Customizable Area End
      this.subScribedMessages = [
        getName(MessageEnum.AccoutLoginSuccess),
        // Customizable Area Start
        getName(MessageEnum.RestAPIResponceMessage),
        getName(MessageEnum.SessionResponseMessage)
        // Customizable Area End
      ];
  
      this.state = {
        // Customizable Area Start
        selectedTab: 0,
        live_auction: [],
        upcoming_auction: [],
        // Customizable Area End
      };
      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  
      // Customizable Area Start
      // Customizable Area End
    }
    
    async componentDidMount() {
     // Customizable Area Start
      this.getLiveAuctionData();
      this.getUpcommingAuctionData();
      // Customizable Area End  
    }

    async receive(from: string, message: Message) {
      // Customizable Area Start
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
  
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
  
        let errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        runEngine.debugLog("API Message Recived", message);
        
        if (responseJson && !responseJson.errors) {
          
          if (apiRequestCallId === this.loginApiCallId) {
            await StorageProvider.set("TOKEN", responseJson.meta.token);
            
            this.getLiveAuctionData();
            this.getUpcommingAuctionData();
          }
          
          if (apiRequestCallId === this.getLiveAuctionCallId) {
            this.setState({ live_auction: responseJson.data });
          }
          
          if (apiRequestCallId === this.getUpcommingAuctionCallId) {
            this.setState({ upcoming_auction: responseJson.data });
          }
          
        }
         else if (responseJson && responseJson.errors) {
          if (responseJson.errors) {
            if (apiRequestCallId === this.loginApiCallId) {
              
            }
            if (apiRequestCallId === this.getLiveAuctionCallId) {
              
                this.getLoginDetail()
             
            }
            if (apiRequestCallId === this.getUpcommingAuctionCallId) {
              
            }
          }
        }
      }
      // Customizable Area End
    }
    // Customizable Area Start
    apiCall_Abc = async(data: {contentType: string,method: string, endPoint: string,body?:object, type?: string }) => {
      
      const { contentType, method, endPoint, body, type } = data;
  
      const token_Web_Abc = (await StorageProvider.get("TOKEN"));
      
      
      const header_Web_ABC = {
        "Content-Type": contentType,
        token : type != "login" ? token_Web_Abc : ""
      };
      
      const requestMessageWeb_Abc = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      
      requestMessageWeb_Abc.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header_Web_ABC)
      );
      
      requestMessageWeb_Abc.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
      );
      
      requestMessageWeb_Abc.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        method
      );
      
      
      body && 
      requestMessageWeb_Abc.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        )
      
      runEngine.sendMessage(requestMessageWeb_Abc.id, requestMessageWeb_Abc);
      
      return requestMessageWeb_Abc.messageId;
    }

    
    getLoginDetail = async() => {
      
      let email = 'abc@yopmail.com'
      
      let information = "Abc@12345"

      const attrs = {
        email: email,
        password: information,
        // device_id: StorageProvider.get("devicePushToken")
      };
      const setData = {
        attributes: attrs,
        type: "email_account",
      };
      const httpBody = {
        data: setData,
      };

      this.loginApiCallId = await this.apiCall_Abc({
        contentType: configJSON.loginApiContentType,
        method: configJSON.loginApiMethod,
        endPoint: configJSON.loginApiEndPoint,
        body: httpBody,
      });

    }
    
    getLiveAuctionData = async() => {
      this.getLiveAuctionCallId = await this.apiCall_Abc({
        contentType: configJSON.getAuctionApiContentType,
        method: configJSON.getAuctionApiMethod,
        endPoint: configJSON.getAuctionApiEndPoint + `?auction_type=live`,
        
      });
    }
  
    getUpcommingAuctionData = async() => {
      this.getUpcommingAuctionCallId = await this.apiCall_Abc({
        contentType: configJSON.getAuctionApiContentType,
        method: configJSON.getAuctionApiMethod,
        endPoint: configJSON.getAuctionApiEndPoint + `?auction_type=upcoming`,
        
      });
    }

    onTabChange = (event: object, newValue: number) => {
      this.setState({ selectedTab: 1})
    };
    
    auctionStart = (start_time:Date) => {
     return moment(start_time).format('DD/MM/YYYY')
    }

    auctionEnd =(end_time:Date)=>{
      return moment(end_time).format('DD/MM/YYYY')
    }

    upcomingAuctionStart = (start_time:Date) => {
      return moment(start_time).format('DD/MM/YYYY')
     }
 
     upcomingAuctionEnd =(end_time:Date)=>{
       return moment(end_time).format('DD/MM/YYYY')
     }

     navigateAuctionBiddingNotificationPage=()=>{
      const msgData: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
      msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      msgData.addData(getName(MessageEnum.NavigationScreenNameMessage), "AuctionBiddingNotification");
      this.send(msgData);
     }

     navigateAuctionBiddingDetailsPage=(item: string | unknown)=>{
      const msgData: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
      msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      msgData.addData(getName(MessageEnum.NavigationScreenNameMessage), "AuctionBiddingDetail");
      msgData.addData(getName(MessageEnum.RestAPIResponceSuccessMessage), {screenData : item});
      this.send(msgData);
     }
    // Customizable Area End
  }
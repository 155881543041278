// Customizable Area Start
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { getStorageData,setStorageData} from "framework/src/Utilities";
export interface IListItem  {
  id: string,
  type: string,
  attributes:{
    filename: null,
    file:{
      id: number,
      url: string,
      type: string
    },
    file_format: string
  }
}
export interface Itabs {
  label:string
}

export interface ParticularCollection
		{
			"id": string,
			"type": string,
			"attributes": {
				"project_name": string,
				"start_date": null | Date,
				"end_date": null | Date,
				"url": null | string,
				"description": string,
				"username": string,
				"account_id": number,
				"profile_photo": string,
				"cover_image_url": string,
				"project_images": string[],
				"likes": number,
				"views": number,
				"saved_count": number,
				"collection_name": string,
				"is_liked": boolean,
				"is_saved": boolean
			}
}

export const configJSON = require("../config");

export interface Props {
  id: string;
  navigation: any;
  EditProfile:boolean;
  handleCloseEditProfile:any;
  
}

interface S {
  companyOrganization:string;
  companyError:string;
  positionError:string;
  position:string;
  selectedRegion:string;
  regionError:string;
city:string;
CityError:string;
startyear:number;
endYear:number;
endMonth:string;
startMonth:string;
endYearError:string;
details:string;
referencelinks: string;
links: any,
linkError:string;
currentlyWorking:boolean;
token:string;
expertiseTempData:any;
filteredOptionsExpertise:any;
expertiseSelected:any;
enteredExpQuery:any;
skillsSelected:any;
skillsTempData:any;
collectionList:any;
currentCollectionId:any;
currentPage:number;
totalCount:number;
particularCollectionData:ParticularCollection[],
tempSelectedProjectList: string[],
selectedProjectList: ParticularCollection[],
previewData:any;
viewMore:boolean;
editProfileData:any;
ischecked:boolean;
toastStatusexp:boolean;
toastMsgExp:string;
toastActionExp:string;
  AllStates:any;
  address:string;
  country:string;
  


}

interface SS {
  id: any;
}

export default class WorkExperienceFromController extends BlockComponent<
  Props,
  S,
  SS
> {
  
  baseUrlStirng:string = configJSON.baseURL.baseURL;
  getcategoryListApicallId:string ='' ;
  getskillscategoryApiCallId:string='';
  collectionDataID:string='';
  collectionNameGetApiCallId:string='';
  postAddProfessionalWorkExpApiCallId:string=''

  

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
    companyOrganization:'' ,
    companyError:'' ,
    positionError:'',
    position:'' , 
    regionError:'',  
    selectedRegion:'',
    city:'',
    CityError:'',
    startyear:0,
    endYear:0,
    endMonth:'',
    startMonth:'',
    endYearError:'',
    details:'',
    referencelinks: "",
    links: [],
    linkError:'',
    ischecked:false,
    currentlyWorking:false,
    token:'',
    viewMore:false,
    expertiseTempData:[],
    filteredOptionsExpertise:[],
    expertiseSelected:'',
    enteredExpQuery:'',
    skillsSelected:[],
    skillsTempData:[],
    collectionList:[],
    currentCollectionId:0,
    currentPage:0,
    particularCollectionData:[],
    totalCount:0,
    tempSelectedProjectList: [],
    selectedProjectList:[],
    previewData:[],
    address:'',
    country:'',
    toastStatusexp:false,
    toastMsgExp:'',
    toastActionExp:'',
    editProfileData:{expertise:[],skills:[]},

    AllStates:[{"code": "1","name": "AnyWhere/Remote"},
      {"code": "2","name": "All/Countries"}]
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    const apiRequestCallId1 = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson1 = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId1 === this.getcategoryListApicallId) {
      this.setState({
        expertiseTempData:responseJson1.data,
        filteredOptionsExpertise:responseJson1.data,
      })
      
    }
    if(apiRequestCallId1 === this.getskillscategoryApiCallId){
      this.setState({skillsTempData:responseJson1.data})
    }

    if (apiRequestCallId1 === this.collectionNameGetApiCallId) {
      if (responseJson1?.data?.collections) {
        this.setState({
          collectionList: responseJson1.data.collections,
          currentCollectionId: responseJson1.data.collections[0].id
        }, () => {
          this.collectionData(responseJson1.data.collections[0].id); 
        });
      }
      
    }
    if (apiRequestCallId1 === this.collectionDataID) {
      this.setState({
        particularCollectionData:responseJson1?.data,
        totalCount:Math.ceil(responseJson1?.meta?.project_count/6)
      });
    }

    this.handlepostformdetails(apiRequestCallId1,responseJson1)
    
    
  }

  handlepostformdetails=(apiRequestCallId1:string,responseJson1:any)=>{
    if (apiRequestCallId1 === this.postAddProfessionalWorkExpApiCallId ) {
     
      if (!responseJson1.errors && !responseJson1.error) {
        this.setState({toastStatusexp:true,toastActionExp:"success",toastMsgExp:"sucessfully Added"})
        this.props.handleCloseEditProfile()
      }
      if(responseJson1.errors) {
        this.setState({toastStatusexp:true,toastActionExp:"danger",toastMsgExp:"please fill the mandatory fields"})
      }
    }
  }
  handleToastClose = () => {
    this.setState({ toastStatusexp: false });
  }

  handleChangeLocationText = (data: string) => {
    this.setState({city:data,address:data})
  }

  handlePressEnter = (e: React.KeyboardEvent<HTMLDivElement>) =>{
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }

  handleChangeLocation = (data: string) => {
    let values = data.split(',')
    
    this.setState({address:data, city:values[0], country:values[values.length - 1]})
}

handleChangeLocationTextBlur = () => {
  if(!this.state.city){
    this.setState({address:""})
  }
}
  

  handleCollection=(id:number|null)=>{
    this.setState({currentCollectionId:id,currentPage:1})
    this.collectionData(id)
  }
  
  handleEndYear=()=>{
    this.setState({ischecked:!this.state.ischecked})
  }

  validateEndYear = () => {
    const { startyear, endYear } = this.state;
    if (!this.state.ischecked ) {
      if ((startyear > endYear) ) {
      
        this.setState({ endYearError: 'End year must not be smaller than start year' });
      } else {
       
        this.setState({ endYearError: '' });
      }
    }
   
  };

  getCollectionAPI = async () => {
    const tokens = await getStorageData("userInfo");
    let newToken = JSON.parse(tokens)
    const { meta: { token ,id} } = newToken;  
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/bx_block_projectportfolio/get_user_collections' )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     'GET'
    );
    this.collectionNameGetApiCallId = getValidationsMsg.messageId
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }  

  collectionData= async (collectionId:number|null)=>{
    const tokens = await getStorageData("userInfo");   
  let newToken = JSON.parse(tokens)
    const { meta: { token } } = newToken;   
    const { meta: { id } } = newToken;   
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_portfolio_management/portfolios/get_projects_based_collection?collection_id=${collectionId}&account_id=${id}&page=${this.state.currentPage}&per_page=6`)
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     'GET'
    );
    this.collectionDataID = getValidationsMsg.messageId
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  handleSelectAll = () => {
    const { particularCollectionData, tempSelectedProjectList, selectedProjectList } = this.state;
  
    if (this.areAllProjectsSelected()) {
      const updatedTempList = tempSelectedProjectList.filter(id => !particularCollectionData.some(item => item.id === id));
      const updatedSelectedList = selectedProjectList.filter(project => !particularCollectionData.some(item => item.id === project.id));
  
      const groupedProjects = updatedSelectedList.reduce((acc: any, project) => {
        const collectionName = project.attributes.collection_name;
        if (!acc[collectionName]) {
          acc[collectionName] = {
            collection_name: collectionName,
            data: [],
          };
        }
        acc[collectionName].data.push(project.attributes);
        return acc;
      }, {});
  
      this.setState({
        tempSelectedProjectList: updatedTempList,
        selectedProjectList: updatedSelectedList,
        previewData: Object.values(groupedProjects),
      });
    } else {
      const newIds = particularCollectionData.map(item => item.id);
      const updatedSelectedList = [...new Set([...selectedProjectList, ...particularCollectionData])];
      const updatedTempList = [...new Set([...tempSelectedProjectList, ...newIds])];
  
      const groupedProjectsData = updatedSelectedList.reduce((acc: any, project) => {
        const collectionNameData = project.attributes.collection_name;
        if (!acc[collectionNameData]) {
          acc[collectionNameData] = {
            data: [],
            collection_name: collectionNameData,
          };
        }
        acc[collectionNameData].data.push(project.attributes);
        return acc;
      }, {});
  
      this.setState({
        previewData: Object.values(groupedProjectsData), 
        selectedProjectList: updatedSelectedList,
        tempSelectedProjectList: updatedTempList,
      });
    }
  };

  areAllProjectsSelected = () => {
    const { particularCollectionData, tempSelectedProjectList } = this.state;
    return particularCollectionData&& particularCollectionData.length>0 ? 
    particularCollectionData.every(item => tempSelectedProjectList.includes(item.id)):
    false;
  };

  handleareAllProjectsSelected=()=>{
    return this.areAllProjectsSelected() ? "Deselect all" : "Select all"
  }

  toggleSelectProject = (item: ParticularCollection) => {
    const { tempSelectedProjectList, selectedProjectList } = this.state;
    const isAlreadySelected = tempSelectedProjectList.includes(item.id);
  
    let updatedTempList, updatedSelectedList;
  
    if (isAlreadySelected) {
      updatedTempList = tempSelectedProjectList.filter(projectId => projectId !== item.id);
      updatedSelectedList = selectedProjectList.filter(project => project.id !== item.id);
    } else {
      updatedTempList = [...tempSelectedProjectList, item.id];
      updatedSelectedList = [...selectedProjectList, item];
    }
  
    const groupedProjectsParticular = updatedSelectedList.reduce((acc: any, project) => {
      const collectionNameParticular = project.attributes.collection_name;
      if (!acc[collectionNameParticular]) {
        acc[collectionNameParticular] = {
          data: [],
          collection_name: collectionNameParticular,
        };
      }
      acc[collectionNameParticular].data.push(project.attributes);
      return acc;
    }, {});
  
    this.setState({
      selectedProjectList: updatedSelectedList,
      previewData: Object.values(groupedProjectsParticular), 
      tempSelectedProjectList: updatedTempList,
    });
  };
  
   handleViewMoreCssBeforeAdd=(viewMore:any)=>{
    return !viewMore?"user_profile_skills_hide":""
  }

  handleViewMoreCss=()=>this.handleViewMoreCssBeforeAdd(this.state.viewMore)


  handleAddNewExpertise=()=>{
    const skills=this.state.skillsTempData.map((item:any)=>{
      return ({value:item.name,select:this.state.skillsSelected.includes(item.name),id:item.id})
    })
    this.state.editProfileData.expertise=[...this.state.editProfileData.expertise,
      {value:this.state.expertiseSelected,skills:[...skills],view_more:false}]
    this.setState({editProfileData:this.state.editProfileData,expertiseSelected:"",skillsTempData:[]})
   }

getAllcategorylist =async() =>{
  const tokens = await getStorageData("userInfo");   
  let newToken = JSON.parse(tokens)
  const {meta: { token } } = newToken;
  this.setState({token:token})
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    "token":token
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getcategoryListApicallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getCategoriesList
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}

handleChanges=(event:any,page:any)=>{
  this.setState({currentPage:page})
}

handleViewCheck = (item: ParticularCollection) => {
  const matchedData = this.state.tempSelectedProjectList.includes(item.id);
  return matchedData ? true : false
}
handleInputChangeExpertiseSkills = (e:any) => {
  const value = e.target.value;
  this.setState({enteredExpQuery:value})
  if (value) {
      const filtered = (this.state.expertiseTempData || []).filter((item:any) => 
          (item.name || "").toLowerCase().includes(value.toLowerCase())
      );
      this.setState({filteredOptionsExpertise:filtered})
  } else {
    this.setState({filteredOptionsExpertise:this.state.expertiseTempData})
  }
};

handleViewMore=()=>{
  this.setState({viewMore: !this.state.viewMore})
}
handleCloseExpertise=()=>{
  this.setState({expertiseSelected:""})
}

expertiseHandler=(item:any)=>{  
  this.setState({enteredExpQuery:'',filteredOptionsExpertise:this.state.expertiseTempData})
  if(this.state.expertiseSelected==item)
  this.setState({expertiseSelected:"",skillsSelected:[]})
else
  this.setState({expertiseSelected:item,skillsSelected:[]})
  this.getSkillsList(item?.id)
}

handleSuggestionSkills=(item:any)=>{
  if(!this.state.skillsSelected.includes(item.name))
  {this.state.skillsSelected.push(item.name)
  this.setState({skillsSelected: this.state.skillsSelected})}
  else  {
    const skillFilterArr = this.state.skillsSelected.filter((i:any) => i !== item.name)
    this.setState({skillsSelected: skillFilterArr})}
  }

getSkillsList =async(id:any)=>{
  const tokens = await getStorageData("userInfo");   
  let newToken = JSON.parse(tokens)
  const {meta: { token } } = newToken;
  // this.setState({token:token})
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    "token":token
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getskillscategoryApiCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getAllSkillsbyid+id
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}

  async componentDidMount() {
this.getAllcategorylist()
this.getCollectionAPI()
    
  }


  getYearList=()=>{
    const years: any = [];
    const startYear = 1960;
    const endYear = new Date().getFullYear();
    
    // Add the current year first
    years.push(endYear);

    // Then add the years from endYear - 1 down to startYear
    for (let year = endYear - 1; year >= startYear; year--) {
        years.push(year);
    }

    return years;
  }

  getMonthList = () => {
    const months = [
        { value: 1, label: "January" },
        { value: 2, label: "February" },
        { value: 3, label: "March" },
        { value: 4, label: "April" },
        { value: 5, label: "May" },
        { value: 6, label: "June" },
        { value: 7, label: "July" },
        { value: 8, label: "August" },
        { value: 9, label: "September" },
        { value: 10, label: "October" },
        { value: 11, label: "November" },
        { value: 12, label: "December" }
    ];
    return months;
};

  handlecheckbox = (e:any) =>{
   this.setState({currentlyWorking:e.target.checked})
  }
  handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement| HTMLTextAreaElement>) => {
    const {  name, value  } = e.target;
   
    
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }),()=>{this.validateEndYear()}
  )
 
  };

  viewMoreForExpertise=(viewMore:any)=>{
    return viewMore?"View less":"View more..."
  }
  handleAddLink = () => {
    const { referencelinks, links } = this.state;

    if (referencelinks.trim() === "") {
        this.setState({ linkError: "Please enter a link." });
        return;
    }

    const urlPattern = /^(https?:\/\/)?([\w.-]+)([\/\w .-]*)*\/?$/;
    if (!urlPattern.test(referencelinks)) {
        this.setState({ linkError: "Please enter a valid URL." });
        return;
    }

    if (links.includes(referencelinks.trim())) {
        this.setState({ linkError: "This link has already been added." });
        return;
    }

    this.setState({
        links: [...links, referencelinks.trim()], 
        referencelinks: "", 
        linkError: "", 
    });
};
  handleBlur = (field:string) => {
    if (field === 'companyOrganization') {
      this.setState({
        companyError: this.state.companyOrganization ? '' : 'Company Organization is required.'
      });
    } else if (field === 'position') {
      this.setState({
        positionError: this.state.position ? '' : 'Position is required.'
      });
    }else if (field === 'region') {
      this.setState({
        regionError: this.state.selectedRegion ? '' : 'location is required.'
      });
      
    }
    else if (field === 'city') {
      this.setState({
        CityError: this.state.city ? '' : 'location is required.'
      });
    }
  };

  
  
  hasErrors = () => {
   let {companyOrganization,position,details,startyear}=this.state
    return (

     !!companyOrganization && !!position && !!details && !!startyear
      
    );
  };

  handleFormData =()=>{
    const formdata = new FormData();
    const { editProfileData,expertiseSelected,skillsSelected } = this.state;
let skillsIDArray:number[] =[]
let expertiseIDArray = []

  
  const  skillsTemp = editProfileData && editProfileData.expertise && 
  editProfileData.expertise.length > 0 ? editProfileData.expertise.map((exp:any) => exp.skills && exp.skills.filter((skill:any) => skill.select).map((skill:any) => skill.id)) : [];;
  skillsIDArray =[].concat(...skillsTemp)
  
  expertiseIDArray = editProfileData && editProfileData.expertise && 
  editProfileData.expertise.length > 0 ? editProfileData.expertise.map((exp:any) => exp.value.id) : [];
  // editProfileData.city = this.state.address

  if (expertiseSelected !=""){

    const filteredObjects =this.state.skillsTempData.filter((obj:any) => skillsSelected.includes(obj.name));
    expertiseIDArray.push(expertiseSelected.id)
    for(let ele of filteredObjects){
     skillsIDArray.push(ele.id)
    }

  }
  let projectID = this.state.selectedProjectList.map((data:any)=>data.id)
formdata.append("professional_experience[company_name]", this.state.companyOrganization);
formdata.append("professional_experience[position]", this.state.position);
formdata.append("professional_experience[location]", this.state.selectedRegion);
if(this.state.selectedRegion === 'All/Countries') {

  formdata.append("professional_experience[city]", this.state.city);
}else {
  formdata.append("professional_experience[city]", 'Remote');
}
formdata.append("professional_experience[starting_from_month]", this.state.startMonth);
formdata.append("professional_experience[starting_from_year]", `${this.state.startyear}`);
if (!this.state.ischecked) {

  formdata.append("professional_experience[ending_in_month]", this.state.endMonth);
  formdata.append("professional_experience[ending_in_year]", `${this.state.endYear}`);
}
formdata.append("professional_experience[details]", this.state.details);
formdata.append("professional_experience[reference_links]",this.state.links );
formdata.append("professional_experience[skill_ids]", `${skillsIDArray}`);
formdata.append("professional_experience[project_ids]", `${projectID}`);

return formdata

  }

  handleSaveChanges =async () =>{
   
    const tokens = await getStorageData("userInfo");   
    let newToken = JSON.parse(tokens)
    const {meta: { token } } = newToken;
    const header = {
      "token":token
    };
    
    

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.postAddProfessionalWorkExpApiCallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.AddProfessionalWorkExp
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      this.handleFormData()
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  HandleBackBtn =()=>{
    this.props.handleCloseEditProfile()
    this.setState({
      companyOrganization:'' ,
    companyError:'' ,
    positionError:'',
    position:'' , 
    regionError:'',  
    selectedRegion:'',
    city:'',
    CityError:'',
    startyear:0,
    endYear:0,
    endMonth:'',
    startMonth:'',
    details:'',
    referencelinks: "",
    links: [],
    linkError:'',
    currentlyWorking:false,
    token:'',
    viewMore:false,
    expertiseTempData:[],
    filteredOptionsExpertise:[],
    expertiseSelected:'',
    enteredExpQuery:'',
    skillsSelected:[],
    skillsTempData:[],
    collectionList:[],
    currentCollectionId:0,
    currentPage:0,
    particularCollectionData:[],
    totalCount:0,
    tempSelectedProjectList: [],
    selectedProjectList:[],
    previewData:[],
    editProfileData:{expertise:[],skills:[]},
    })
  }
}

// Customizable Area End

// Customizable Area Start
import React from "react";
import ProposalsController from "./ProposalsController";
import SearchIcon from '@material-ui/icons/Search';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Box, Button, Grid, IconButton, InputAdornment, withStyles, TextField, Typography, styled, Checkbox } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import StarIcon from '@material-ui/icons/Star';
import {avatar} from "../../catalogue/src/assets"
export const configJSON = require("./config");
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import ToastMSG from "../../../components/src/ToastMSG.web";
const customStyle = require('../../dashboard/src/CustomStyles.js');
import {leftArrowIcon} from '../../dashboard/src/assets';

const CustomButton = withStyles({
    root: {
        ...customStyle.customStyle.buttonField
    }
})(Button);

const CustomBox = withStyles((theme) => ({
    root: {
        '& .add-question': {
            padding: '10px 30px !important'
        },

        '& .medium - text': {
            fontFamily: 'Inter-Medium, sans-serif !important'
        }
    }
}))(Box);

export class Proposals extends ProposalsController {


    renderTab = () => {
        return (
            <CustomTabBox style={{ display: "flex", borderBottom: "1px solid grey", color: "white", gap: "30px" }}>
                <Box data-test-id="tab1" className={`tab ${this.state.selectedTab === "pending" ? "pending" : ""}`}
                    onClick={() => this.handleTabClick("pending")}
                    style={{
                        cursor: "pointer",
                        position: 'relative'
                    }}
                >
                    <TabsTypo>Pending</TabsTypo>
                </Box>
                <Box data-test-id="tab2" className={`tab ${this.state.selectedTab === "shortlisted" ? "shortlisted" : ""}`}
                    onClick={() => this.handleTabClick("shortlisted")}
                    style={{
                        cursor: "pointer",
                        position: 'relative'

                    }}>
                    <TabsTypo>Shortlisted</TabsTypo>
                </Box>
                <Box data-test-id="tab3" className={`tab ${this.state.selectedTab === "archived" ? "archived" : ""}`}
                    onClick={() => this.handleTabClick("archived")}
                    style={{
                        cursor: "pointer",
                        position: 'relative'
                    }}>
                    <TabsTypo>Archived</TabsTypo>
                </Box>
                {this.props.displayTitle === false &&
                     <Box data-test-id="tab4" className={`tab ${this.state.selectedTab === "offered" ? "offered" : ""}`}
                     onClick={() => this.handleTabClick("offered")}
                     style={{
                         cursor: "pointer",
                         position: 'relative'
                     }}
                 >
                     <TabsTypo>Offered</TabsTypo>
                 </Box>
                }
            </CustomTabBox>
        )
    }

    renderEmptyTitle = () => {
        let title;

        if (this.state.selectedTab === "pending") {
            title = "No pending proposals"
        } else if (this.state.selectedTab === "shortlisted") {
            title = "No shortlisted proposals"
        } else if (this.state.selectedTab === "archived") {
            title = "No archived proposals"
        }
        return title;
    }

    renderEmptySubContent = () => {
        let title;

        if (this.state.selectedTab === "pending") {
            title = "Hang tight! You haven’t received any proposals for this work opportunity yet."
        } else if (this.state.selectedTab === "shortlisted") {
            title = "You haven’t shortlisted any proposals yet. Check out the pending proposals and shortlist the ones you find suitable."
        } else if (this.state.selectedTab === "archived") {
            title = "You haven’t archived any proposals yet. Check out the pending and shortlisted proposals instead"
        }
        return title;
    }

    renderPhoto = (mainPhoto: string, backgroundColor: string) => {
        let content;

        if (mainPhoto) {
            content = (
                <img style={{ width: "100px", height: "100px", borderRadius: "50%" }} src={`${this.baseUrlStirng}${mainPhoto}`} />
            );
        } else if (backgroundColor) {
            content = (
                <Box style={{ width: "100px", height: "100px", borderRadius: "50%", backgroundColor: backgroundColor }}></Box>
            );
        } else {
            content = (
                <img src={avatar} style={{ width: "100px", height: "100px", borderRadius: "50%" }} alt="avatar" />
            );
        }
        return <>{content}</>;
    }

    renderSection = () => {
        return (
            <form data-test-id="search"  onSubmit={(event)=>{this.handleSubmitSearch(event)}}>
            <CustomTextField onChange={this.handleSearch}
                variant="outlined"
                size="small"
                id="find_work_search"
                data-test-id="searchfeild"
                fullWidth
                placeholder="Type here"
                value={this.state.searchInput}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIconStyled />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">

                            {this.state.showIcon &&
                                <IconButton data-test-id="searchclear"
                                    onClick={this.clearText} id="clear-text" style={{ color: "white", marginRight: 20, fontFamily: "Inter , sans-serif", }} size="small">
                                    x
                                </IconButton>}
                            <SearchButton type="submit" data-test-id="searchBtn" style={{ cursor: "pointer" }} onClick={(event)=>{this.handleSubmitSearch(event)}} disabled={this.handleDisableSearch()}>
                                Search
                            </SearchButton>
                        </InputAdornment>
                    ),

                }}
            />
            </form>
        )
    }

    viewWorkDetail = () => {
        return (
            this.props.displayTitle !== false &&
                <Box>
                    <Typography
                        onClick={this.navigateViewWork}
                        style={{
                            fontFamily: "Inter",
                            fontSize: "16px",
                            fontWeight: 600,
                            lineHeight: "19.26px",
                            textAlign: "left",
                            color: "#FFF2E3",
                            textDecoration: "underline",
                            cursor: "pointer",
                            width: 'fit-content'
                        }}>View work details</Typography>
                </Box>
        )
    }

    title = () => {
        return (
            this.props.displayTitle !== false &&
                <Box
                    style={{
                        marginTop: "45px"
                    }}>
                    <Typography
                        style={{
                            fontFamily: "Inter",
                            fontSize: "36px",
                            fontWeight: 700,
                            lineHeight: "43.57px",
                            textAlign: "left",
                            color: "#FFF2E3",
                        }}>
                        {this.props.type === "deleted" ? "Archived Proposals" :
                            "Proposals"}</Typography>
                </Box>
        )
    }

    tabDisplay = () => {
        return (
            this.props.type !== 'deleted' && 
                <Box
                    style={{
                        borderBottom: "1px solid grey",
                        marginTop: "58px",
                    }}>
                    {this.renderTab()}
                </Box>
        )
    }

    seacrchBar = () => {
        return (
            this.props.displayTitle !== false ?
                <SearchBox70>
                    {this.renderSection()}
                </SearchBox70>
                :
                <SearchBox50>
                    {this.renderSection()}
                </SearchBox50>
        )
    }

    paginationArrorw = () => {
        return (
            ((this.state.page * 6) < this.state.totalProposal) &&
                        <Grid xs={12} lg={12} md={12}>
                            <Box>
                                <div className="MoreVectorIcon">
                                    <ExpandMoreRoundedIcon data-testId="paginationTestid" onClick={this.handleScroll} />
                                </div>
                            </Box>
                        </Grid>
        )
    }

    render() {
        return (
            <>
                {this.viewWorkDetail()}

                {this.title()}

                {this.props.displayTitle === false &&
                    <MainBox>
                        <Box data-testid="allProposals"
                            style={webStyle.allPropsCon}>
                            <img style={{ cursor: 'pointer' }} onClick={this.props.onOfferAction} src={leftArrowIcon} alt="left-icon" />
                            <Typography style={{ ...webStyle.title, marginLeft: '15px' }}>All Proposals</Typography>
                        </Box>
                        <FiltersBox >
                            <Box style={{ position: "relative" }} data-test-id="openRating" onClick={() => { this.setState({ openLocationDropDown: false, openSortDropDown: false }) }} >
                                <RateButton data-test-id="rating"
                                    onClick={() => { this.setState({ openRatingDropDown: !this.state.openRatingDropDown }) }}
                                >
                                    <Typography style={{
                                        fontWeight: 400,
                                        fontFamily: "Inter",
                                        fontSize: "14px",
                                        lineHeight: "16.94px",
                                        color: "#FFF2E3",
                                        textTransform: "capitalize",
                                        cursor: "pionter"
                                    }}>Ratings</Typography>
                                    <ArrowDropDownIcon style={{ width: "20px", height: "20px", color: "#D9D9D9" }} />
                                </RateButton>

                                {
                                    this.state.openRatingDropDown && (
                                        <Box style={{
                                            position: "absolute",
                                            opacity: "0px",
                                            display: "flex",
                                            height: "fit-content",
                                            maxWidth: "257px",
                                            width: "257px",
                                            backgroundColor: "#111111",
                                            flexDirection: "column",
                                            border: "1px solid #595959",
                                            cursor: "pointer",
                                            borderRadius: "10px",
                                            zIndex: 9
                                        }}>

                                            <Box style={{
                                                padding: "1rem",
                                                borderBottom: "1px solid #595959",
                                            }}>
                                                <Typography
                                                    style={{
                                                        color: "#ffffff",
                                                        fontWeight: 600,
                                                        fontSize: "14px",
                                                        lineHeight: "16.94px",
                                                        whiteSpace: "nowrap" as "nowrap",
                                                        fontFamily: "Inter",
                                                        textAlign: "left",
                                                    }}>
                                                        Ratings:
                                                </Typography>
                                            </Box>
                                            <Box style={{
                                                alignItems: "center",
                                                display: "flex", 
                                                padding: "0rem 1rem"

                                            }}>
                                                <Checkbox data-test-id="checkbox1"
                                                    style={{ color: "#FFF2E3" }}
                                                    onChange={() => this.ratingStatiData("less than 1")}
                                                    checked={this.state.SelectedDropDownRatingName.includes("less than 1")}
                                                    name="checkedB"
                                                    color="primary"
                                                    value={"less than 1"}
                                                />
                                                <Typography style={{
                                                    color: "white",
                                                    fontSize: "14px",
                                                    fontWeight: 400,
                                                    lineHeight: "16.94px",
                                                    textAlign: "left",
                                                    fontFamily: "Inter",
                                                    whiteSpace: "nowrap" as "nowrap"

                                                }}>
                                                    Less than 1 </Typography>
                                            </Box>
                                            {this.state.ratingData.map((element: any, index: number) => {
                                                return (
                                                    <Box
                                                        data-test-id={`selectedDropDown-${index}`}
                                                        key={index}
                                                        style={{
                                                            cursor: "pointer",
                                                            display: "flex",
                                                            padding: "0rem 1rem",
                                                            alignItems: "center",
                                                        }}>
                                                        <Checkbox data-test-id={`checkbox2-${index}`}
                                                            checked={this.state.SelectedDropDownRatingName.includes(element.name)}
                                                            name="checkedB"
                                                            value={element.name}
                                                            style={{ color: "#FFF2E3" }}
                                                            color="primary"
                                                            onChange={() => this.ratingStatiData(element.name)}
                                                        />
                                                        <Box style={{ display: "flex", alignItems: "center" }}>
                                                            <Typography data-test-id={`Typography-${index}`}
                                                                style={{
                                                                    color: "white",
                                                                    fontFamily: "Inter",
                                                                    fontWeight: 400,
                                                                    lineHeight: "16.94px",
                                                                    textAlign: "left",
                                                                    fontSize: "14px",
                                                                    whiteSpace: "nowrap" as "nowrap"

                                                                }}>
                                                                    {element.name.split("-")[0]}
                                                            </Typography>
                                                            <StarIcon style={{ color: "#BFC06F" }} />
                                                            &nbsp;

                                                            <Typography data-test-id={`Typography-${index}`}
                                                                style={{
                                                                    color: "white",
                                                                    fontSize: "14px",
                                                                    fontWeight: 400,
                                                                    lineHeight: "16.94px",
                                                                    textAlign: "left",
                                                                    fontFamily: "Inter",
                                                                    whiteSpace: "nowrap" as "nowrap"

                                                                }}>
                                                                    - &nbsp;{element.name.split("-")[1]}</Typography>
                                                            <StarIcon style={{ color: "#BFC06F" }} />
                                                        </Box>
                                                    </Box>
                                                )
                                            })
                                            }
                                            <Box style={{
                                                padding: "0rem 1rem",
                                                display: "flex", alignItems: "center",
                                                marginBottom: "10px"

                                            }}>
                                                <Checkbox data-test-id="checkbox3" style={{ color: "#FFF2E3" }}
                                                    onChange={() => this.ratingStatiData("Above 4.5")}
                                                    name="checkedB"
                                                    color="primary"
                                                    checked={this.state.SelectedDropDownRatingName.includes("Above 4.5")}
                                                    value={"Above 4.5"}
                                                />
                                                <Typography style={{
                                                    fontFamily: "Inter",
                                                    fontSize: "14px",
                                                    fontWeight: 400,
                                                    color: "white",
                                                    lineHeight: "16.94px",
                                                    textAlign: "left",
                                                    whiteSpace: "nowrap" as "nowrap",
                                                }}>
                                                    Above 4.5 </Typography>
                                                <StarIcon style={{ color: "#BFC06F" }} />
                                            </Box>
                                        </Box>)
                                }
                            </Box>
                            <Box 
                                style={{ position: "relative" }} 
                                data-test-id="openlocation" 
                                onClick={() => { this.setState({ openRatingDropDown: false, openSortDropDown: false }) }}
                                >
                                <LocationButton data-test-id="location"
                                    onClick={() => { this.setState({ openLocationDropDown: !this.state.openLocationDropDown }) }}
                                >
                                    <Typography style={{
                                        lineHeight: "16.94px",
                                        fontFamily: "Inter",
                                        fontSize: "14px",
                                        color: "#FFF2E3",
                                        fontWeight: 400,
                                        textTransform: "capitalize"
                                    }} >
                                        Location </Typography>
                                    <ArrowDropDownIcon style={{ width: "20px", height: "20px", color: "#D9D9D9" }} />
                                </LocationButton>
                                {
                                    this.state.openLocationDropDown && (
                                        <Box style={{
                                            maxWidth: "257px",
                                            position: "absolute",
                                            display: "flex",
                                            height: "fit-content",
                                            backgroundColor: "#111111",
                                            opacity: "0px",
                                            flexDirection: "column",
                                            cursor: "pointer",
                                            width: "257px",
                                            borderRadius: "10px",
                                            border: "1px solid #595959",
                                            right: '0px',
                                            zIndex: 9
                                        }}>

                                            <Box style={{
                                                padding: "0.3rem",
                                                borderBottom: "1px solid #595959",
                                            }}>
                                                <CustomLocationTextField
                                                    variant="outlined"
                                                    size="small"
                                                    value={this.state.searchLocationInput}
                                                    id="find_work_search"
                                                    data-test-id="locationSearch"
                                                    onChange={this.handleLocationSearch}
                                                    fullWidth
                                                    placeholder="Search Location"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <SearchIconStyled />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Box>
                                            {this.state.locationSearchData
                                                .filter((element) =>
                                                    element.toLowerCase().includes(this.state.searchLocationInput.toLowerCase())
                                                )
                                                .map((element: any, index: number) => {
                                                    return (
                                                        <Box
                                                        data-test-id={`selectedDropDown-${index}`}
                                                        key={index}
                                                        style={{
                                                            display: "flex",
                                                            cursor: "pointer",
                                                            alignItems: "center",
                                                            padding: "0rem 1rem",
                                                        }}>
                                                            <Checkbox
                                                                checked={this.state.SelectedDropDownLocationName.includes(element)}
                                                                data-test-id={`checkboxlocation`}
                                                                style={{ color: "#FFF2E3" }}
                                                                name="checkedB"
                                                                onChange={() => this.filterLocationData(element)}
                                                                value={element}
                                                                color="primary"
                                                            />
                                                            <Box style={{ display: "flex", alignItems: "center" }}>
                                                                <Typography
                                                                    data-test-id={`Typography-${index}`}
                                                                    style={{
                                                                        fontFamily: "Inter",
                                                                        color: "white",
                                                                        textAlign: "left",
                                                                        fontSize: "14px",
                                                                        fontWeight: 400,
                                                                        lineHeight: "16.94px",
                                                                        textTransform: "capitalize",
                                                                        whiteSpace: "nowrap" as "nowrap"
                                                                    }}>{element}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    );
                                                }) }
                                        </Box>
                                    )
                                }
                            </Box>
                            <Box style={{ position: "relative" }} 
                                data-test-id="openSort" 
                                onClick={() => { 
                                    this.setState({ 
                                        openRatingDropDown: false, 
                                        openLocationDropDown: false 
                                    })
                                }}>
                                <SortButton data-test-id="sort"
                                    onClick={() => { this.setState({ openSortDropDown: !this.state.openSortDropDown }) }}>
                                    <Typography style={{
                                        fontFamily: "Inter",
                                        fontWeight: 400,
                                        lineHeight: "16.94px",
                                        color: "#FFF2E3",
                                        textTransform: "capitalize",
                                        fontSize: "14px",
                                        cursor: "pionter",
                                    }}  >
                                        Sort
                                    </Typography>
                                    <ArrowDropDownIcon style={{ width: "20px", height: "20px", color: "#D9D9D9" }} />
                                </SortButton>
                                {
                                    this.state.openSortDropDown && (
                                        <Box style={{
                                            position: "absolute",
                                            flexDirection: "column",
                                            maxWidth: "257px",
                                            height: "fit-content",
                                            display: "flex",
                                            opacity: "0px",
                                            border: "1px solid #595959",
                                            cursor: "pointer",
                                            left: "-9rem",
                                            backgroundColor: "#111111",
                                            borderRadius: "10px",
                                            zIndex: 9
                                        }}>

                                            <Box style={{
                                                padding: "1rem",
                                                borderBottom: "1px solid #595959",
                                            }}>
                                                <Typography
                                                    style={{
                                                        fontFamily: "Inter",
                                                        fontSize: "14px",
                                                        fontWeight: 600,
                                                        color: "#ffffff",
                                                        lineHeight: "16.94px",
                                                        textAlign: "left"
                                                    }}>Sort:</Typography>
                                            </Box>
                                            {this.state.sortData.map((element: any, index: number) => {
                                                return (
                                                    <Box data-test-id={`selectedDropDownSorting-${index}`}
                                                        onClick={() => this.dropDownViewData(element)}
                                                        style={{
                                                            display: "flex",
                                                            cursor: "pointer",
                                                            padding: "1rem",
                                                        }}>
                                                        <Typography data-test-id={`Typography-${index}`} onClick={() => {
                                                            this.setState({ openSortDropDown: !this.state.openSortDropDown })
                                                        }}
                                                            style={{
                                                                color: "white",
                                                                fontSize: "14px",
                                                                fontWeight: 400,
                                                                lineHeight: "16.94px",
                                                                fontFamily: "Inter",
                                                                textAlign: "left",
                                                                cursor: "pionter",

                                                            }}>{element.name}</Typography>
                                                    </Box>
                                                )
                                            })
                                            }
                                        </Box>
                                    )
                                }
                            </Box>
                        </FiltersBox>
                    </MainBox>
                }

                {this.tabDisplay()}

                {!this.state.ProposalNotFound && 
                <MainBox>
                    {this.seacrchBar()}
                    {this.props.displayTitle !== false &&
                        <FiltersBox >
                            <Box style={{ position: "relative" }} data-test-id="openRating" onClick={() => { this.setState({ openLocationDropDown: false, openSortDropDown: false }) }} >
                                <RateButton data-test-id="rating"
                                    onClick={() => { this.setState({ openRatingDropDown: !this.state.openRatingDropDown }) }}
                                >
                                    <Typography style={{
                                        fontFamily: "Inter",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        lineHeight: "16.94px",
                                        color: "#FFF2E3",
                                        textTransform: "capitalize",
                                        cursor: "pionter"
                                    }}>
                                        Ratings
                                    </Typography>
                                    <ArrowDropDownIcon style={{ width: "20px", height: "20px", color: "#D9D9D9" }} />
                                </RateButton>
                                {
                                    this.state.openRatingDropDown && (
                                        <Box style={{
                                            position: "absolute",
                                            display: "flex",
                                            flexDirection: "column",
                                            height: "fit-content",
                                            maxWidth: "257px",
                                            backgroundColor: "#111111",
                                            opacity: "0px",
                                            border: "1px solid #595959",
                                            cursor: "pointer",
                                            width: "257px",
                                            borderRadius: "10px",
                                            zIndex: 9
                                        }}>

                                            <Box style={{
                                                borderBottom: "1px solid #595959",
                                                padding: "1rem"
                                            }}>
                                                <Typography
                                                    style={{
                                                        color: "#ffffff",
                                                        fontFamily: "Inter",
                                                        fontSize: "14px",
                                                        fontWeight: 600,
                                                        lineHeight: "16.94px",
                                                        whiteSpace: "nowrap" as "nowrap",
                                                        textAlign: "left",
                                                    }}>Ratings:</Typography>
                                            </Box>
                                            <Box style={{
                                                display: "flex", alignItems: "center",
                                                padding: "0rem 1rem"

                                            }}>
                                                <Checkbox data-test-id="checkbox1"
                                                    style={{ color: "#FFF2E3" }}
                                                    checked={this.state.SelectedDropDownRatingName.includes("less than 1")}
                                                    name="checkedB"
                                                    color="primary"
                                                    onChange={() => this.ratingStatiData("less than 1")}
                                                    value={"less than 1"}
                                                />
                                                <Typography style={{
                                                    color: "white",
                                                    fontFamily: "Inter",
                                                    fontSize: "14px",
                                                    fontWeight: 400,
                                                    lineHeight: "16.94px",
                                                    textAlign: "left",
                                                    whiteSpace: "nowrap" as "nowrap"

                                                }}>Less than 1 </Typography>
                                            </Box>
                                            {this.state.ratingData.map((element: any, index: number) => {
                                                return (
                                                    <Box
                                                        key={index}
                                                        data-test-id={`selectedDropDown-${index}`}
                                                        style={{
                                                            display: "flex",
                                                            padding: "0rem 1rem",
                                                            cursor: "pointer",
                                                            alignItems: "center",
                                                        }}>
                                                        <Checkbox data-test-id={`checkbox2-${index}`}
                                                            style={{ color: "#FFF2E3" }}
                                                            checked={this.state.SelectedDropDownRatingName.includes(element.name)}
                                                            name="checkedB"
                                                            color="primary"
                                                            onChange={() => this.ratingStatiData(element.name)}
                                                            value={element.name}
                                                        />
                                                        <Box style={{ display: "flex", alignItems: "center" }}>
                                                            <Typography data-test-id={`Typography-${index}`}
                                                                style={{
                                                                    color: "white",
                                                                    fontFamily: "Inter",
                                                                    fontSize: "14px",
                                                                    fontWeight: 400,
                                                                    lineHeight: "16.94px",
                                                                    textAlign: "left",
                                                                    whiteSpace: "nowrap" as "nowrap"

                                                                }}>{element.name.split("-")[0]}
                                                            </Typography>
                                                            <StarIcon style={{ color: "#BFC06F" }} />
                                                            &nbsp;

                                                            <Typography data-test-id={`Typography-${index}`}
                                                                style={{
                                                                    color: "white",
                                                                    fontFamily: "Inter",
                                                                    fontSize: "14px",
                                                                    fontWeight: 400,
                                                                    lineHeight: "16.94px",
                                                                    textAlign: "left",
                                                                    whiteSpace: "nowrap" as "nowrap"

                                                                }}>- &nbsp;{element.name.split("-")[1]}</Typography>
                                                            <StarIcon style={{ color: "#BFC06F" }} />
                                                        </Box>

                                                    </Box>
                                                )
                                            })
                                            }
                                            <Box style={{
                                                display: "flex", alignItems: "center",
                                                padding: "0rem 1rem",
                                                marginBottom: "10px"

                                            }}>
                                                <Checkbox data-test-id="checkbox3" style={{ color: "#FFF2E3" }}
                                                    checked={this.state.SelectedDropDownRatingName.includes("Above 4.5")}
                                                    name="checkedB"
                                                    color="primary"
                                                    onChange={() => this.ratingStatiData("Above 4.5")}
                                                    value={"Above 4.5"}
                                                />
                                                <Typography style={{
                                                    color: "white",
                                                    fontFamily: "Inter",
                                                    whiteSpace: "nowrap" as "nowrap",
                                                    textAlign: "left",
                                                    fontSize: "14px",
                                                    fontWeight: 400,
                                                    lineHeight: "16.94px",

                                                }}>Above 4.5 </Typography>
                                                <StarIcon style={{ color: "#BFC06F" }} />
                                            </Box>
                                        </Box>
                                    )
                                }
                            </Box>
                            <Box 
                                style={{ position: "relative" }} data-test-id="openlocation" 
                                onClick={() => { this.setState({ openRatingDropDown: false, openSortDropDown: false }) }}>
                                <LocationButton data-test-id="location"
                                    onClick={() => { 
                                        this.setState({ 
                                            openLocationDropDown: !this.state.openLocationDropDown 
                                        })
                                    }}
                                >
                                    <Typography style={{
                                        fontFamily: "Inter",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        lineHeight: "16.94px",
                                        color: "#FFF2E3",
                                        textTransform: "capitalize"
                                    }} >
                                        Location
                                    </Typography>
                                    <ArrowDropDownIcon style={{ width: "20px", height: "20px", color: "#D9D9D9" }} />
                                </LocationButton>
                                {
                                    this.state.openLocationDropDown && (
                                        <Box style={{
                                            position: "absolute",
                                            display: "flex",
                                            flexDirection: "column",
                                            height: "fit-content",
                                            maxWidth: "257px",
                                            backgroundColor: "#111111",
                                            opacity: "0px",
                                            border: "1px solid #595959",
                                            cursor: "pointer",
                                            width: "257px",
                                            borderRadius: "10px",
                                            right: '0px',
                                            zIndex: 9
                                        }}>

                                            <Box style={{
                                                borderBottom: "1px solid #595959",
                                                padding: "0.3rem"
                                            }}>
                                                <CustomLocationTextField
                                                    onChange={this.handleLocationSearch}
                                                    variant="outlined"
                                                    size="small"
                                                    id="find_work_search"
                                                    data-test-id="locationSearch"
                                                    fullWidth
                                                    placeholder="Search Location"
                                                    value={this.state.searchLocationInput}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <SearchIconStyled />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Box>
                                            {this.state.locationSearchData
                                                .filter((element) =>
                                                    element.toLowerCase().includes(this.state.searchLocationInput.toLowerCase())
                                                )
                                                .map((element: any, index: number) => {
                                                    return (
                                                        <Box
                                                            key={index}
                                                            data-test-id={`selectedDropDown-${index}`}
                                                            style={{
                                                                display: "flex",
                                                                padding: "0rem 1rem",
                                                                cursor: "pointer",
                                                                alignItems: "center",
                                                            }}>
                                                            <Checkbox
                                                                data-test-id={`checkboxlocation`}
                                                                style={{ color: "#FFF2E3" }}
                                                                checked={this.state.SelectedDropDownLocationName.includes(element)}
                                                                name="checkedB"
                                                                color="primary"
                                                                onChange={() => this.filterLocationData(element)}
                                                                value={element}
                                                            />
                                                            <Box style={{ display: "flex", alignItems: "center" }}>
                                                                <Typography
                                                                    data-test-id={`Typography-${index}`}
                                                                    style={{
                                                                        color: "white",
                                                                        fontFamily: "Inter",
                                                                        fontSize: "14px",
                                                                        fontWeight: 400,
                                                                        lineHeight: "16.94px",
                                                                        textAlign: "left",
                                                                        textTransform: "capitalize",
                                                                        whiteSpace: "nowrap" as "nowrap"
                                                                    }}>
                                                                    {element}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    );})
                                            }
                                        </Box>
                                    )}
                            </Box>
                            <Box style={{ position: "relative" }} data-test-id="openSort" onClick={() => { this.setState({ openRatingDropDown: false, openLocationDropDown: false }) }}>
                                <SortButton data-test-id="sort"
                                    onClick={() => { this.setState({ openSortDropDown: !this.state.openSortDropDown }) }}>
                                    <Typography style={{
                                        fontFamily: "Inter",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        lineHeight: "16.94px",
                                        color: "#FFF2E3",
                                        textTransform: "capitalize",
                                        cursor: "pionter",
                                    }}  >
                                        Sort
                                    </Typography>
                                    <ArrowDropDownIcon style={{ width: "20px", height: "20px", color: "#D9D9D9" }} />
                                </SortButton>
                                {
                                    this.state.openSortDropDown && (
                                        <Box style={{
                                            position: "absolute",
                                            display: "flex",
                                            flexDirection: "column",
                                            maxWidth: "257px",
                                            height: "fit-content",
                                            opacity: "0px",
                                            backgroundColor: "#111111",
                                            border: "1px solid #595959",
                                            cursor: "pointer",
                                            left: "-9rem",
                                            borderRadius: "10px",
                                            zIndex: 9
                                        }}>

                                            <Box style={{
                                                borderBottom: "1px solid #595959",
                                                padding: "1rem"
                                            }}>
                                                <Typography
                                                    style={{
                                                        color: "#ffffff",
                                                        fontFamily: "Inter",
                                                        fontSize: "14px",
                                                        fontWeight: 600,
                                                        lineHeight: "16.94px",
                                                        textAlign: "left"
                                                    }}>Sort:</Typography>
                                            </Box>
                                            {this.state.sortData.map((element: any, index: number) => {
                                                return (
                                                    <Box data-test-id={`selectedDropDownSorting-${index}`}
                                                        onClick={() => this.dropDownViewData(element)}
                                                        style={{
                                                            display: "flex",
                                                            padding: "1rem",
                                                            cursor: "pointer",
                                                        }}>
                                                        <Typography data-test-id={`Typography-${index}`} onClick={() => {
                                                            this.setState({ openSortDropDown: !this.state.openSortDropDown })
                                                        }}
                                                            style={{
                                                                color: "white",
                                                                fontFamily: "Inter",
                                                                fontSize: "14px",
                                                                fontWeight: 400,
                                                                lineHeight: "16.94px",
                                                                textAlign: "left",
                                                                cursor: "pionter",

                                                            }}>{element.name}</Typography>
                                                    </Box>
                                                )
                                            })
                                            }
                                        </Box>
                                    )
                                }

                            </Box>

                        </FiltersBox>
                    }
                </MainBox >
                }

                <CardGrid data-test-id="gridDebounceScroll" container className="scrollhidden">
                        {   (this.state.loader && !this.state.ProposalNotFound) &&
                        <Box style={{ color: "white", height: "50%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <CircularProgress style={{color:"FFF2E3"}} />
                        </Box>
                        }

                    {
                        
                         !this.state.loader && this.state.pendingDataList?.map((element: any) => {
                            return (
                                <Grid item xs={12} lg={4} md={6} style={{ display: "flex", justifyContent: "center", alignItems: "center", flexWrap: "wrap", height: 'fit-content', cursor: "pointer" }}>

                                    <CardBox data-test-id="navigate" onClick={() => this.handleCardID(element.attributes.id, true, '')}>
                                        <Box style={{ display: "flex", alignItems: "center", gap: "30px", padding: "2rem 1rem" }}>
                                            <Box>
                                                {this.renderPhoto(element.attributes.profile_photo, element.attributes.background_color)}
                                            </Box>
                                            <Box><Typography style={{
                                                fontFamily: "Inter",
                                                fontSize: "20px",
                                                fontWeight: 700,
                                                lineHeight: "24.2px",
                                                color: "#FFF2E3",
                                                textTransform: "capitalize",
                                                width: "100%",
                                                wordBreak: "break-all"
                                            }}>{element.attributes.user_name}</Typography>
                                                <Typography style={{
                                                    fontFamily: "Inter",
                                                    fontSize: "16px",
                                                    fontWeight: 500,
                                                    lineHeight: "19.36px",
                                                    color: "#FFF2E3",
                                                    textTransform: "capitalize",
                                                    paddingTop: "0.5rem"
                                                }}>{element.attributes.location}</Typography></Box>
                                        </Box>
                                        <Box style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around", padding: "0px 0.5rem" }}>
                                            <Box>
                                                <Typography style={{
                                                    fontFamily: "Inter",
                                                    fontWeight: 400,
                                                    lineHeight: "16.94px",
                                                    color: "#7D7D7D",
                                                    fontSize: "14px",
                                                    textTransform: "capitalize",
                                                    textAlign: "center"
                                                }}>Views</Typography>
                                                <Typography style={{
                                                    fontFamily: "Inter",
                                                    fontSize: "16px",
                                                    fontWeight: 500,
                                                    lineHeight: "19.36px",
                                                    color: "#FFF2E3",
                                                    textTransform: "capitalize",
                                                    paddingTop: "0.5rem",
                                                    textAlign: "center"
                                                }}>{element.attributes.view_count}</Typography>
                                            </Box>
                                            <Box style={{ borderLeft: "1px solid grey", paddingLeft: "1rem" }}>
                                                <Typography style={{
                                                    fontFamily: "Inter",
                                                    fontSize: "14px",
                                                    fontWeight: 400,
                                                    lineHeight: "16.94px",
                                                    color: "#7D7D7D",
                                                    textTransform: "capitalize",
                                                    textAlign: "center"
                                                }}>Likes</Typography>
                                                <Typography style={{
                                                    fontFamily: "Inter",
                                                    fontSize: "16px",
                                                    fontWeight: 500,
                                                    lineHeight: "19.36px",
                                                    color: "#FFF2E3",
                                                    textTransform: "capitalize",
                                                    paddingTop: "0.5rem",
                                                    textAlign: "center"
                                                }}>{element.attributes.likes_count}</Typography>
                                            </Box>
                                            <Box style={{ borderRight: "1px solid grey", borderLeft: "1px solid grey", padding: "0px 10px" }}>
                                                <Typography style={{
                                                    fontFamily: "Inter",
                                                    fontSize: "14px",
                                                    fontWeight: 400,
                                                    lineHeight: "16.94px",
                                                    color: "#7D7D7D",
                                                    textTransform: "capitalize",
                                                    textAlign: "center"
                                                }}>Submitted rate</Typography>
                                                <Typography style={{
                                                    fontFamily: "Inter",
                                                    fontSize: "16px",
                                                    fontWeight: 500,
                                                    lineHeight: "19.36px",
                                                    color: "#FFF2E3",
                                                    paddingTop: "0.5rem",
                                                    textAlign: "center"
                                                }}>{element.attributes.service_rate.toLocaleString('en-IN')} pr/hr</Typography>
                                            </Box>
                                            <Box>
                                                <Typography style={{
                                                    fontFamily: "Inter",
                                                    fontSize: "14px",
                                                    fontWeight: 400,
                                                    lineHeight: "16.94px",
                                                    color: "#7D7D7D",
                                                    textTransform: "capitalize",
                                                    textAlign: "center"
                                                }}>Ratings</Typography>
                                                <Box style={{
                                                    display: "flex", alignItems: "center", justifyContent: "center", marginTop: "5px",
                                                    height: "26px", width: "60px", background: "green", borderRadius: "3px", gap: "2px"
                                                }}>
                                                    <Typography style={{
                                                        fontFamily: "Inter",
                                                        fontSize: "16px",
                                                        fontWeight: 500,
                                                        lineHeight: "19.36px",
                                                        color: "#FFF2E3",
                                                        textTransform: "capitalize",
                                                        textAlign: "center",
                                                    }}>{element.attributes.rating}
                                                    </Typography>
                                                    <Box><StarIcon style={{ color: "white", width: "14px", height: "13px" }} /></Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box style={{ borderTop: "0.97px solid #545454", marginTop: "20px" }}>
                                            <Box style={{ padding: "1rem" }}>
                                                <Typography style={{
                                                    fontFamily: "Inter",
                                                    fontSize: "11px",
                                                    fontWeight: 400,
                                                    lineHeight: "13.31px",
                                                    color: "#66625C",
                                                    textTransform: "capitalize",
                                                    textAlign: "left",
                                                }}>{element.attributes.received_time_ago}</Typography>
                                            </Box>
                                            <Box style={{
                                                padding: "1rem"
                                            }}>
                                                <Typography style={{
                                                    fontFamily: "Inter",
                                                    fontSize: "14px",
                                                    fontWeight: 700,
                                                    lineHeight: "16.94px",
                                                    color: "#FFF2E3",
                                                    textTransform: "capitalize",
                                                    textAlign: "left",
                                                }}>Pitch</Typography>
                                            </Box>
                                            <Box style={{ padding: "1rem", height: "6rem" }}>
                                                <Typography style={{
                                                    fontFamily: "Inter",
                                                    fontSize: "14px",
                                                    fontWeight: 400,
                                                    lineHeight: "16.94px",
                                                    color: "#FFF2E3",
                                                    textTransform: "capitalize",
                                                    textAlign: "left",
                                                    display: "-webkit-box",
                                                    WebkitLineClamp: 5,
                                                    WebkitBoxOrient: "vertical",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    wordWrap: "break-word"

                                                }}>{element.attributes.give_your_pitch}</Typography>
                                            </Box>
                                            {(this.state.selectedTab == "pending" && this.props.type !== 'deleted') &&
                                                <Button data-test-id="ShortlistBtn" style={{
                                                    padding: "1rem", display: "flex", alignItems: "center", gap: "5px", cursor: "pointer",
                                                    justifyContent: "space-between", width: "130px", marginBottom: "10px"
                                                }}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        this.handleCardID(element.attributes.id, false, "shortlisted");
                                                    }}
                                                >
                                                    <CheckCircleOutlineIcon style={{ color: "#8C8C8C", height: "27px", width: "27px" }} />
                                                    <Typography style={{
                                                        fontFamily: "Inter",
                                                        fontSize: "16px",
                                                        fontWeight: 500,
                                                        lineHeight: "24px",
                                                        color: "#FFF2E3",
                                                        textTransform: "capitalize",
                                                        letterSpacing: "0.379999995231628px",
                                                        textDecoration: "underline"
                                                    }}>Shortlist</Typography>
                                                </Button>
                                            }

                                            {this.state.selectedTab == "shortlisted" &&
                                                <Box style={webStyle.buttonBox}>
                                                    <CustomBox>
                                                        <CustomButton
                                                            onClick={(event) => {
                                                                event.stopPropagation();
                                                                this.handleCardID(element.attributes.id, false, "archived");
                                                            }}
                                                            data-test-id='add-question' className='button-elm default-btn add-question medium-text'>
                                                            Archive
                                                        </CustomButton>
                                                    </CustomBox>
                                                    <CustomBox>
                                                        <CustomButton onClick={() => this.handleCardID(element.attributes.id, true, '')} data-test-id='add-question' className='button-elm secondary-btn add-question medium-text'>Offer contract</CustomButton>
                                                    </CustomBox>
                                                </Box>
                                            }
                                        </Box>

                                    </CardBox>
                                </Grid>
                            )
                        })
                    }
                    {this.paginationArrorw()}
                    {
                        this.state.ProposalNotFound &&
                        <Box style={{ color: "white", maxHeight: "645px", backgroundColor: '#1F1F1F', width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: 'column' }}>

                            <Typography style={{
                                fontFamily: "Inter",
                                fontSize: "20px",
                                fontWeight: 600,
                                lineHeight: "24.2px"

                            }}>
                                <strong>{this.renderEmptyTitle()}</strong></Typography>

                            <Typography style={{
                                fontFamily: "Inter",
                                fontSize: "16px",
                                fontWeight: 400,
                                lineHeight: "22.08px",
                                width: '100%',
                                maxWidth: "460px",
                                textAlign: 'center',
                                marginTop: '25px'
                            }}>
                                {this.renderEmptySubContent()}
                            </Typography>
                        </Box>
                    }

                </CardGrid>
                
                <ToastMSG test-ID="closeSnack" close={() => this.handleClose()} open={this.state.openToast} message={this.state.toastMessage} action={this.state.toastHeading} />
                
            </>
        )
    }
}

const CustomTextField = styled(TextField)(({ theme }) => ({
    backgroundColor: '#1c1c1c',
    fontSize: 14,
    fontFamily: "Inter , sans-serif",
    width: "100%",
    borderRadius: 20,
    height: "48px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'transparent',
        },
        '&:hover fieldset': {
            borderColor: 'transparent',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'transparent',
        },
    },
    '& .MuiInputBase-input': {
        color: '#fff',
        fontSize: 14,
        '&:placeholder': {
            textDecoration: 'italic',
        },
    },
    '& .MuiInputAdornment-root': {
        color: '#66625C',
    },
    [theme.breakpoints.down("md")]: {
    }
})


)

const CustomLocationTextField = styled(TextField)(({ theme }) => ({
    fontSize: 14,
    fontFamily: "Inter , sans-serif",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "italic",
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'transparent',
        },
        '&:hover fieldset': {
            borderColor: 'transparent',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'transparent',
        },
    },
    '& .MuiInputBase-input': {
        color: '#fff',
        fontSize: 14,
        '&:placeholder': {
            textDecoration: 'italic',
        },
    },
    '& .MuiInputAdornment-root': {
        color: '#66625C',
    },
    [theme.breakpoints.down("md")]: {
    }
})

)


const SearchButton = styled(IconButton)({
    color: '#fff',
    textTransform: 'none',
    fontSize: 14,
    textDecoration: 'underline',
    fontFamily: "Inter , sans-serif",
    borderLeft: '1px solid #434343',
    borderRadius: '0px',
    '&:disabled': {
        color: '#888',
        textDecoration: 'none',
        opacity: 0.5,
    },
});

const SearchIconStyled = styled(SearchIcon)({
    color: '#66625C',
    fontSize: 18
});

const SearchBox70 = styled(Box)(({ theme }) => ({
    color: '#66625C',
    fontSize: 18,
    width: "70%",
    height: "48px",
    "@media (max-width:960px)": {
        width: "90%",
    },
    "@media (min-width:720px)": {

    },
})
);

const SearchBox50 = styled(Box)(({ theme }) => ({
    color: '#66625C',
    fontSize: 18,
    width: "50%",
    height: "48px",
    "@media (max-width:960px)": {
        width: "70%",
    }
}));


const MainBox = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "50px",
    flexWrap: "wrap",
    width: "100%",
    gap: "10px",
    "@media (max-width:960px)": {
        height: "100px",
        width: "100%",
    },
    "@media (min-width:720px)": {

    },

});

const FiltersBox = styled(Box)({
    display: "flex",
    gap: "20px",
    flexWrap: "wrap",
    "@media (max-width:960px)": {

    },
    "@media (min-width:720px)": {

    },

});

const RateButton = styled(Button)({
    width: "99px",
    height: "37px",
    gap: "9px",
    borderRadius: "6px",
    opacity: "0px",
    border: "1px solid #66625C",
    cursor: "pointer"
});
const LocationButton = styled(Button)({
    height: "37px",
    gap: "9px",
    borderRadius: "6px",
    border: "1px solid #66625C",
    opacity: "0px",
    cursor: "pointer"

});
const SortButton = styled(Button)({
    height: "37px",
    gap: "9px",
    borderRadius: "6px",
    cursor: "pointer",
    opacity: "0px",

});

const CardBox = styled(Box)({
    width: "398px",
    margin: "2% 5%",
    border: "1.15px solid #6D6D6D",
    borderRadius: "11.54px",
    overflow: 'hidden',
    "@media (max-width:960px)": {
        height: "533px",
    },
    "@media (min-width:720px)": {

    },
});

const TabsTypo = styled(Typography)({
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "24.2px",
    textAlign: "left",
    marginBottom: "1rem",
    cursor: "pointer",
    "@media (max-width:960px)": {
    },
    "@media (min-width:720px)": {

    },
});

const CustomTabBox = styled(Box)({
    "& .pending::before": {
        content: "close-quote",
        position: 'absolute',
        top: "38px",
        width: "100%",
        height: "6px",
        background: "#fff",
        borderRadius: '9.5px'
    },
    "& .shortlisted::before": {
        content: "close-quote",
        position: 'absolute',
        top: "38px",
        width: "100%",
        height: "6px",
        background: "#fff",
        borderRadius: '9.5px'
    },
    "& .archived::before": {
        content: "close-quote",
        position: 'absolute',
        top: "38px",
        width: "100%",
        height: "6px",
        background: "#fff",
        borderRadius: '9.5px'
    },
    "& .offered::before": {
        content: "close-quote",
        position: 'absolute',
        top: "38px",
        width: "100%",
        height: "6px",
        background: "#fff",
        borderRadius: '9.5px'
    }

});

const CardGrid = styled(Grid)({
    display: "flex",
    justifyContent: "start",
    marginTop: "2rem",
    height: "1075px",
    overflowY: "scroll",
    scrollbarWidth: 'none',
    '& .MoreVectorIcon': {
      textAlign: 'center',
      paddingBottom: '20px',
      width: '100%'
    },
    '& .MoreVectorIcon svg': {
      width: "50px",
      height: "50px",
      background: "#171717",
      borderRadius: "50px",
      color: '#fff'
    },
})

const webStyle: Record<string, CSSProperties> = {
    buttonBox: {
        padding: '1rem',
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    },
    allPropsCon: {
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
        alignSelf: "flex-start",
    },
    title: {
        fontFamily: "Inter, sans-serif",
        fontSize: '24px',
        fontWeight: 700,
        lineHeight: '29.05px',
        color: '#545454'
    },
}
// Customizable Area End


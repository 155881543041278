import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData, setStorageData } from "framework/src/Utilities";
export const configJSON = require("./config");

let tokenData = "";
let cardId = "";
let dropdownId = 0

export interface Skill {
  id: number
  name: string
  created_at: string
  updated_at: string
  category_id: number
}

export interface ReceiveProject {
  id: number
  project_title: string
  project_cover_image: string
  user_name: string
  view_count: number
  like_count: number
  saved_count: number
}

export interface PostHistory {
  id: string
  type: string
  attributes: PostHistoryAttributes
}

export interface PostHistoryAttributes {
  id: number
  work_opportunity_id: number
  event: string
  event_date: string
  referenceable_type: string
  referenceable_id: number
}


// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: Record<string, string>;
  // Customizable Area End
}

interface S {
  // Customizable Area Start  
  title: string;
  description: string;
  skills: Skill[];
  deliverables: string;
  workType: string;
  location:string;
  multiLocationData: Array<string>;
  workExperience:string;
  workHour: number;
  rateData: number;
  overallRate: number;
  projectTimeline: number;
  selectedElm: string;
  dateData: string;
  linkList: string[];
  receivedProjectList: ReceiveProject[]
  uploadedFile: string[]
  question: string[]
  postHistory: PostHistory[]
  mainTitle: string
  titleColor: string
  postedTime: string
  isLoading: boolean;
  deleteModal: boolean;
  open: boolean;
  message: string;
  action: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ViewWorkDetailController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiLoadViewData: string = '';
  apiDeleteDraftData: string = ''
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      title: '',
      description: '',
      skills: [],
      deliverables: '',
      workType: '',
      location:'',
      multiLocationData: [],
      workExperience: '',
      workHour: 0,
      overallRate: 0,
      rateData: 0,
      projectTimeline: 0,
      selectedElm: '',
      dateData: '',
      linkList: [],
      receivedProjectList: [],
      uploadedFile: [],
      question: [],
      postHistory: [],
      mainTitle: '',
      titleColor: '',
      postedTime: '',
      isLoading: true,
      deleteModal: false,
      open: false,
      action: '',
      message: ''
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      // code here
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiLoadViewData) {
          this.handleSetData(message);
        }
        if (apiRequestCallId === this.apiDeleteDraftData) {
          this.handleDelete(message);
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    let userInfo = await getStorageData("userInfo");
    let storeData = JSON.parse(userInfo);
    if (userInfo) {
      tokenData = storeData.meta.token;
    }
    cardId = this.props.navigation.getParam("id")
    dropdownId = this.props.navigation.getParam("dropdownId")
    this.loadData();
    this.setMainTitle();
  }

  loadData = () => {
    const headerObj = {
      "token": tokenData
    };
    const headerData = JSON.stringify(headerObj);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiLoadViewData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.viewDeatilApiEndPoint + cardId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headerData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getcompanyApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSetData = (message: Message) => {
    const responseJson = message.getData( getName(MessageEnum.RestAPIResponceSuccessMessage));
    this.setState({ isLoading: false })
    if (responseJson.data) {
      const uploadedfile = responseJson.data.attributes?.attached_files.map((file: { file_name: string }) => file.file_name);
      this.setState({
        title: responseJson.data.attributes?.title,
        description: responseJson.data.attributes?.description,
        skills: responseJson.data.attributes?.skills,
        deliverables: responseJson.data.attributes?.deliverables,
        workType: responseJson.data.attributes?.work_type,
        location: responseJson.data.attributes?.location,
        workExperience: responseJson.data.attributes?.experience_level,
        workHour: responseJson.data.attributes?.required_hours_per_week,
        projectTimeline: responseJson.data.attributes?.project_timeline,
        selectedElm: responseJson.data.attributes?.project_timeline_type,
        dateData: responseJson.data.attributes?.start_date,
        receivedProjectList: responseJson.data.attributes?.added_projects,
        uploadedFile: uploadedfile,
        question: responseJson.data.attributes?.question,
        postHistory: responseJson.data.attributes?.Post_history.data,
        postedTime: responseJson.data.attributes?.Posted_time_ago
      }, () => {
        this.checkMultiLocation();
      })

      if (responseJson.data.attributes?.rate_type == "hourly") {
        this.setState({rateData: responseJson.data.attributes?.rate_amount})
      } else {
        this.setState({overallRate: responseJson.data.attributes?.rate_amount})
      }

      let str = [];
      let strNew = [];
      if (responseJson.data.attributes?.files_or_links != '') {
        str = responseJson.data.attributes?.files_or_links
        strNew = str.split(',');
      }
      if (str != null && str.length > 0) {
        this.setState({
          linkList: strNew,
        })
      }
    }
  }

  handleDelete = (message: Message) => {
    const responseJson = message.getData( getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (!responseJson.errors) {
      this.setState(() => ({
        ...this.state,
        open: true,
        action: "success",
        message: "Work opportunity deleted sucessfully",
      }), () => {
        setTimeout(() => {
          this.redirectTo("LandingPage");
        }, 2000);
      });
    }
  }

  checkMultiLocation = () => {
    if (this.state.location.includes(',')) {
      let multiLocation = this.state.location.split(',');

      this.setState({multiLocationData: multiLocation})
    }
  }

  navigateMyWork = () => {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "MyWork");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  navigateMyWorkWithDelete = async () => {
    await setStorageData("DeleteDraft", true);
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "MyWork");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  setMainTitle = () => {
    const result = configJSON.dropDownDataStatic.find((item: {id: number}) => item.id == dropdownId);
    this.setState({
      mainTitle: result.name,
      titleColor: result.color
    })
  }

  handleDeleteModel = () => {
    this.setState({deleteModal: !this.state.deleteModal})
  }

  handleShowDiscard = () => {
    const headerObj = {
      "token": tokenData
    };
    const headerData = JSON.stringify(headerObj);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDeleteDraftData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSpecificData + cardId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headerData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.delete
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  redirectTo = (url: string) => {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), url);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleClose = () => {
    this.setState({ open: false });
  }

  // Customizable Area End
}